import { AxiosResponse } from "axios";
import RnsHistory from "../interface/RnsHistory";
import RnsSearchResult from "../interface/RnsSearchResult";

const RnsSearchResultDTO = {
  convertResponseToRnsSearchResultDTO: (
    response: AxiosResponse
  ): RnsSearchResult => {
    if (response.data) {
      return {
        total: response.data.total,
        rows: response.data.Row,
      };
    }
    return {} as RnsSearchResult;
  },
  convertResponseToRnsSearchHistoryDTO: (
    response: AxiosResponse
  ): Array<RnsHistory> => {
    if (response.data) {
      return response.data;
    }
    return {} as Array<RnsHistory>;
  },
};
export default RnsSearchResultDTO;
