import { Grid } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import TextValue from "global/components/UI/TextValue/TextValue";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  EpcomReducerFunctionOptional,
  EpcomReducerOptional
} from "global/interface/EpcomReducer";
import DateUtils from "global/util/DateUtils";
import { DataControl } from "global/util/enum/DataControl";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { returnNullIfEmptyString } from "global/util/utils";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useState
} from "react";
import AutomatenblattInformation, {
  changeBonNumberInRnsBonNummerDTO,
  changeRnsObjectWithRnsGln
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import RnsHistory from "service/data-service/rns-controller/interface/RnsHistory";
import RnsService from "service/data-service/rns-controller/Rns.service";
import { generateDefaultAutomatenblattInformation } from "../../../generator/defaultAutomatenblattInformation";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import "../panels.scss";
import CPChange from "./components/CPChange";
import GlnChange from "./components/GlnChange";
import RNSAktiveAutomaten from "./components/RNSAktiveAutomaten";
import { RNSPanelColumnDefs } from "./grid-column-defs";
import "./rnsPanel.scss";

interface RNSPanelProps extends PanelBaseProps {
  automatKey: string;
}

const RNSPanel = (props: RNSPanelProps) => {
  const [automatData] = useAutomatData(props.automatKey);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const stateChangeAndReset: EpcomReducerFunctionOptional<
    AutomatenblattInformation
  > = (state, action) => {
    if (action.type === DataControl.CHANGE && action.valueToChange) {
      return { ...state, ...action.valueToChange };
    } else {
      // RNS will be ignored at discard, except serial number changes
      let rns = state.rns;

      if (
        state.seriennummer !==
        automatData.automatenblattInformation.seriennummer
      ) {
        rns = automatData.automatenblattInformation.rns;
      }

      return {
        ...automatData.automatenblattInformation,
        rns: rns,
      };
    }
  };

  const [automatenblattInformation, dispatchAutomatenblattInformation] =
    useReducer<EpcomReducerOptional<AutomatenblattInformation>>(
      stateChangeAndReset,
      automatData.automatenblattInformation ??
      generateDefaultAutomatenblattInformation()
    );

  const changeAutomatenblattInformation = (
    value: Optional<AutomatenblattInformation>
  ) => {
    dispatchAutomatenblattInformation({
      type: DataControl.CHANGE,
      valueToChange: value,
    });
  };

  const { updateValues } = props;
  const [history, setHistory] = useState<Array<RnsHistory>>([]);

  function handleSapBonNrOnChange(event: ChangeEvent<HTMLInputElement>) {
    let newValue = event.target.value.trim();
    updateValues.current.rnsBonNummer = newValue;
    insertRnsBonPairValueInUpdateIfYetUndefined();
    changeAutomatenblattInformation({
      sapBonNummerDTO: changeBonNumberInRnsBonNummerDTO(
        automatenblattInformation.sapBonNummerDTO,
        returnNullIfEmptyString(newValue)
      ),
    });
  }

  function insertRnsBonPairValueInUpdateIfYetUndefined() {
    if (!updateValues.current.rnsBonNummer) {
      updateValues.current.rnsBonNummer =
        automatenblattInformation.sapBonNummerDTO.bonNummer ?? undefined;
    }
    if (!updateValues.current.rnsBonNummerGueltigVon) {
      updateValues.current.rnsBonNummerGueltigVon =
        DateUtils.optionalTimeArrayToISOString(
          automatenblattInformation.sapBonNummerDTO.gueltigVon
        );
    }
  }

  const retrieveRNSHistory = useCallback(() => {
    setIsFetchingData(true);
    RnsService.searchRNSHistory(
      automatenblattInformation.automatKey,
      (data: RnsHistory[]) => {
        setIsFetchingData(false);
        setHistory(data);
      },
      () => {
        setIsFetchingData(false);
      }
    );
  }, [automatenblattInformation.automatKey]);

  // Initialising at render and reset
  useEffect(() => {
    dispatchAutomatenblattInformation({ type: DataControl.RESET });
  }, [automatData, props.resetValues]);

  // Get Machine History on Render
  useEffect(() => {
    if (automatenblattInformation.automatKey) retrieveRNSHistory();
  }, [automatenblattInformation.automatKey, retrieveRNSHistory]);

  return (
    <Grid container>
      <Grid className="grid_item_30rem" item xs={12} sm={6}>
        <TextValue
          label="GLN:"
          id="gln"
          value={automatenblattInformation.rns.gln}
          readOnly
          disableInputUnderline={false}
        />
        <GlnChange
          automatenblattInformation={automatenblattInformation}
          updateValues={updateValues}
          onGlnChange={() => {
            retrieveRNSHistory();
          }}
        />
        <TextValue
          label="Name:"
          value={automatenblattInformation.rns.name}
          id="name"
          readOnly
        />
        <TextValue
          label="Straße:"
          value={automatenblattInformation.rns.strasse}
          id="strasse"
          readOnly
        />
        <TextValue
          label="PLZ:"
          value={automatenblattInformation.rns.plz}
          id="plz"
          readOnly
        />
        <TextValue
          label="Ort:"
          value={automatenblattInformation.rns.ort}
          id="ort"
          readOnly
        />
        <TextValue
          label="Datum von:"
          value={DateUtils.optionalTimeArrayToGermanString(
            automatenblattInformation.rns.sapGueltigVon
          )}
          id="sap-gueltig-von"
          readOnly
        />
        <TextValue
          label="Datum bis:"
          value={DateUtils.optionalTimeArrayToGermanString(
            automatenblattInformation.rns.sapGueltigBis
          )}
          id="sap-gueltig-bis"
          readOnly
        />
        <RNSAktiveAutomaten
          automatenblattInformation={automatenblattInformation}
        />
        <TextValue
          label="SAP Bon Nr.:"
          id="sap-bon-nummer"
          value={automatenblattInformation.sapBonNummerDTO.bonNummer ?? ""}
          onChange={handleSapBonNrOnChange}
        />
        <DatePickerElement
          label="SAP Bon Nr. gültig von:"
          defaultValue={DateUtils.arrayToDate(
            automatenblattInformation.sapBonNummerDTO.gueltigVon
          )}
          getSelectedValue={(value) => {
            updateValues.current.rnsBonNummerGueltigVon =
              DateUtils.formatDateToAPIDateString(value);
            insertRnsBonPairValueInUpdateIfYetUndefined();
          }}
          id="sap-bon-nummer-gueltig-von"
          resetToDefault={props.resetValues}
        />
        <TextValue
          label="Infofeld 1:"
          id="sap-infofeld-1"
          value={automatenblattInformation.infofeld1 ?? ""}
          onChange={(event) => {
            let newValue = event.target.value;
            changeAutomatenblattInformation({
              infofeld1: newValue,
            });
            updateValues.current.infofeld1 = newValue;
          }}
        />
        <TextValue
          label="Infofeld 2:"
          id="sap-infofeld-2"
          value={automatenblattInformation.infofeld2 ?? ""}
          onChange={(event) => {
            let newValue = event.target.value;
            changeAutomatenblattInformation({
              infofeld2: newValue,
            });
            updateValues.current.infofeld2 = newValue;
          }}
        />
        <TextValue
          label="Infofeld 3:"
          id="sap-infofeld-3"
          value={automatenblattInformation.infofeld3 ?? ""}
          onChange={(event) => {
            let newValue = event.target.value;
            changeAutomatenblattInformation({
              infofeld3: newValue,
            });
            updateValues.current.infofeld3 = newValue;
          }}
        />
      </Grid>
      <Grid className="grid_item_40rem" item xs={12} sm={6}>
        <TextValue
          label="Ansprechpartner:"
          value={automatenblattInformation.rns.kontaktName ?? ""}
          id="partner-ansprechpartner"
          readOnly
        />
        <TextValue
          label="Telefon1:"
          value={automatenblattInformation.rns.kontaktTelefon1 ?? ""}
          id="partner-telefon1"
          readOnly
        />
        <TextValue
          label="Telefon 2:"
          value={automatenblattInformation.rns.kontaktTelefon2 ?? ""}
          id="partner-telefon2"
          readOnly
        />
        <TextValue
          label="EMail:"
          value={automatenblattInformation.rns.kontaktEmail ?? ""}
          id="partner-email"
          readOnly
        />
        <TextValue
          label="Bemerkung1:"
          value={automatenblattInformation.rns.bemerkung1 ?? ""}
          id="partner-bemerkung1"
          readOnly
        />
        <TextValue
          label="Bemerkung2:"
          value={automatenblattInformation.rns.bemerkung2 ?? ""}
          id="partner-bemerkung2"
          readOnly
        />
        <TextValue
          label="Bemerkung3:"
          value={automatenblattInformation.rns.bemerkung3 ?? ""}
          id="partner-bemerkung3"
          readOnly
        />
        <TextValue
          label="Organisationseinheit:"
          value={automatenblattInformation.rns.edekaOrganisation.name ?? ""}
          id="partner-organisation"
          multiline
          readOnly
        />
        <CPChange
          automatKey={props.automatKey}
          rnsInfo={automatenblattInformation.rns}
          getChangedRnsGln={(changedValues) => {
            changeAutomatenblattInformation({
              rns: changeRnsObjectWithRnsGln(
                automatenblattInformation.rns,
                changedValues
              ),
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          height={500}
          columnDefs={RNSPanelColumnDefs}
          rowsDataDef={{
            data: history,
            isFetchingData: isFetchingData,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RNSPanel;
