import { generateDefaultAutomatenblattInformation } from "page/Automatensuche/components/Automatenblatt/generator/defaultAutomatenblattInformation";
import { useCallback, useEffect, useState } from "react";
import configureArticleSearchStore from "./automat/automat-artikelsuche-store";
import configureAutomatCheckStore from "./automat/automat-check-store";
import configureClearingFehlerStore from "./automat/automat-clearingfehler-store";
import configureAutomatDpgStammdatenStore from "./automat/automat-dpg-stammdaten-store";
import configureDsmdStatusStore, { FETCH_AUTOMAT_DSMD_STATUS } from "./automat/automat-dsmd-status-store";
import configureAutomatHistoryStore from "./automat/automat-history-store";
import configureAutomatLogfileStore from "./automat/automat-logfile-store";
import configureLogfilefehlerStore from "./automat/automat-logfilefehler-store";
import configureAutomatStatusStore, {
  FETCH_AUTOMAT_STATUS
} from "./automat/automat-status-store";
import configureAutomatenblatInformationStore, {
  FETCH_AUTOMATTENBLATT_INFORMATION
} from "./automat/automatenblatt-information-store";
import configureSapRdVertragStore from "./automat/sap-rd-vertragsdaten-store";
import configureViewInfoStore from "./automat/view-info-store";
import AutomatAction, { AutomatActionPayload } from "./interface/AutomatAction";
import AutomatDataListener from "./interface/AutomatDataListener";
import AutomatDataRecord from "./interface/AutomatDataRecord";

export type AutomatDataStore = Array<AutomatDataRecord>;
export type DataFetchMonitor = React.Dispatch<React.SetStateAction<boolean>>
export type DispatchAutomatDataFunction = (
  actionIdentifier: string,
  payload?: AutomatActionPayload,
  callbackOnFail?: Function,
  dataFetchMonitor?: DataFetchMonitor,
) => void;

let globalAutomatDatastore: AutomatDataStore = [];
let listeners: Array<AutomatDataListener> = [];
let actions: Array<AutomatAction> = [];

const updateAutomatData = (
  automatKey: string,
  data: AutomatDataRecord,
  hasListeners: boolean
) => {
  const index = globalAutomatDatastore.findIndex(
    (e) => e.automatKey === automatKey
  );
  const updatedDatastore = [...globalAutomatDatastore];
  updatedDatastore[index] = {
    ...globalAutomatDatastore[index],
    ...data,
  };
  globalAutomatDatastore = updatedDatastore;
  if (hasListeners === true) {
    const dataListeners = listeners.filter(
      (li) => li.automatKey === automatKey
    );
    for (const dataListener of dataListeners) {
      dataListener.listener(globalAutomatDatastore);
    }
  }
};

export const useAutomatData = (
  automatKey: string,
  shouldListen = true
): [AutomatDataRecord, DispatchAutomatDataFunction] => {
  const setState = useState(globalAutomatDatastore)[1];

  const dispatch: DispatchAutomatDataFunction = useCallback(
    (
      actionIdentifier: string,
      payload?: AutomatActionPayload,
      callbackOnFail?: Function,
      dataFetchMonitor?: DataFetchMonitor,
    ) => {
      const actionIndex = actions.findIndex(
        (e) => e.identifier === actionIdentifier
      );
      if (actionIndex >= 0) {
        const index = globalAutomatDatastore.findIndex(
          (e) => e.automatKey === automatKey
        );
        if (dataFetchMonitor) dataFetchMonitor(true)
        actions[actionIndex].action(
          globalAutomatDatastore[index],
          {
            ...payload,
            automatKey: automatKey,
          } as AutomatActionPayload,
          (data) => {
            if (dataFetchMonitor) dataFetchMonitor(false)
            updateAutomatData(automatKey, data, shouldListen)
          },
          (error: Error) => {
            if (dataFetchMonitor) dataFetchMonitor(false)
            if (callbackOnFail) { callbackOnFail(error) }
          }
        );
      }
    },
    [automatKey]
  );

  var recordIndex = globalAutomatDatastore.findIndex(
    (e) => e.automatKey === automatKey
  );
  if (recordIndex === -1) {
    recordIndex = globalAutomatDatastore.length;
    globalAutomatDatastore.push({
      automatKey: automatKey,
      viewInfo: {
        infoPanel: "rns",
        infoPanelIndex: 0,
        statusRecordCount: 10,
      },
      automatenblattInformation: generateDefaultAutomatenblattInformation(),
    });
    // fetch initial data
    dispatch(FETCH_AUTOMATTENBLATT_INFORMATION);
    dispatch(FETCH_AUTOMAT_STATUS);
    dispatch(FETCH_AUTOMAT_DSMD_STATUS);
  }

  useEffect(() => {
    if (shouldListen) {
      listeners.push({
        automatKey: automatKey,
        listener: setState,
      });
    }

    return () => {
      if (shouldListen) {
        listeners = listeners.filter((li) => li.listener !== setState);
      }
    };
  }, [automatKey, setState, shouldListen]);

  return [globalAutomatDatastore[recordIndex], dispatch];
};

export const AutomatStoreController = {
  removeAutomat: (serienNummer: string) => {
    globalAutomatDatastore = globalAutomatDatastore.filter(
      (li) => serienNummer !== li.automatenblattInformation.seriennummer + ""
    );
  },
  automatRoutePath: (serienNummer: string): string => {
    var recordIndex = globalAutomatDatastore.findIndex(
      (e) => serienNummer === e.automatenblattInformation.seriennummer + ""
    );
    if (recordIndex >= 0) {
      return "/" + globalAutomatDatastore[recordIndex].viewInfo.infoPanel;
    }
    return "/rns";
  },
};

export const registerAutomatDataStoreActions = (
  userActions: Array<AutomatAction>
) => {
  actions = [...actions, ...userActions];
};

export const configureAutomatDataStore = () => {
  configureAutomatenblatInformationStore();
  configureAutomatStatusStore();
  configureDsmdStatusStore();
  configureViewInfoStore();
  configureAutomatCheckStore();
  configureArticleSearchStore();
  configureClearingFehlerStore();
  configureAutomatDpgStammdatenStore();
  configureAutomatHistoryStore();
  configureLogfilefehlerStore();
  configureAutomatLogfileStore();
  configureSapRdVertragStore();
};
