import { createTheme } from "@mui/material";
import ButtonThemeComponents from "./ButtonTheme";
import ColorPaletteOptions from "./ColorTheme";
import MenuThemeComponents from "./MenuTheme";
import TypographyThemeOptions from "./TypographyTheme";

const AppTheme = createTheme({
  palette: ColorPaletteOptions,
  components: { ...ButtonThemeComponents, ...MenuThemeComponents },
  ...TypographyThemeOptions,
});

export default AppTheme;
