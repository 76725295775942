import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import DateUtils from "global/util/DateUtils";
import { TabControlContext } from "page/Automatensuche/components/TabControl/TabControlProvider";
import { useContext, useState } from "react";
import AutomatInvalidFields from "service/data-service/automate-controller/interface/AutomatInvalidFields";
import ZurBearbeitungAnstehend from "service/data-service/automate-controller/ZurBearbeitungAnstehend.service";
import ExportService from "service/report-service/export/Export.service";
import ZurBearbeitungColumnDefs from "./grid-column-defs";
import "./zurBearbeitung.scss";

const ZurBearbeitung: React.FC = () => {
  const olderThanDays = 2;
  const tabControlCtx = useContext(TabControlContext);
  const [letzteDate, setLetzteDate] = useState<string>("unbekannt");
  const [shwoErrorMsg, setShowErrorMsg] = useState<boolean>(false);
  const windowViewport = useWindowViewport(0, 273)

  const onGridDataChanged = (
    zurBearbeitungRecords: Array<AutomatInvalidFields> | undefined
  ) => {
    if (zurBearbeitungRecords && zurBearbeitungRecords.length > 0) {
      setLetzteDate(zurBearbeitungRecords[0].pruefungsdatum);
      setShowErrorMsg(
        DateUtils.isDateOlderThanDays(
          DateUtils.parseIsoDateString(
            zurBearbeitungRecords[0]?.pruefungsdatum
          ),
          olderThanDays
        )
      );
    }
  };

  return (
    <Stack className="zurBearbeitungRecords">
      <Grid container spacing={1} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>Zur Bearbeitung anstehend</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack rowGap={1.5}>
            <Typography
              className={(shwoErrorMsg ? "prufungColor " : " ") + "prufung"}
            >
              Zeitpunkt letzte Prufung der Clearingfehler: {letzteDate}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={ZurBearbeitungColumnDefs}
            onRowDataChanged={onGridDataChanged}
            onRowClicked={(row) => {
              tabControlCtx.push(row.serienNr, row.automatKey);
            }}
            exportDef={{
              action: ExportService.downloadZurBearbeitungAnstehend,
            }}
            rowsDataDef={{
              uncontrolledDataFetchCall: ZurBearbeitungAnstehend.load,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default ZurBearbeitung;
