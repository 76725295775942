export const RNS_PATH = "rns";
export const DETAILS_PATH = "details";
export const STATUS_PATH = "status";
export const TK_PARTNER_PATH = "tk-partner";
export const ABRUF_PATH = "abruf";
export const DPG_STAMMDATEN_PATH = "dpg-stammdaten";
export const CLEARINGFEHLER_PATH = "clearingfehler";
export const NOTIZEN_PATH = "notizen";
export const VERTRAGE_PATH = "vertrage";
export const ARTIKELSUCHE_PATH = "artikelsuche";
export const LOGFILE_PATH = "logfile";
export const LOGFILEFEHLER_PATH = "logfilefehler";
export const HISTORIE_PATH = "historie";
