import { Button, Grid, Stack, Typography } from "@mui/material";
import {
  AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID,
  useEnumerator
} from "App/hook/use-enumerator";
import {
  Enumerator,
  EnumeratorItem
} from "global/components/EnumeratedValues/Enumerator";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import TextValue from "global/components/UI/TextValue/TextValue";
import { useState } from "react";
import ExportService from "service/report-service/export/Export.service";

import "./auslastungVerfuegbarkeit.scss";

const AuslastungVerfuegbarkeit = () => {
  const automatStatusEnum: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID
  );

  const [seriennummer, setSeriennummer] = useState<string>("");
  const [glnDerRns, setGlnDerRns] = useState<string>("");
  const [status, setStatus] = useState<Array<EnumeratorItem>>([]);
  const [reset, setReset] = useState(false);

  const resetFilter = () => {
    setSeriennummer("");
    setGlnDerRns("");
    setStatus([]);
    setReset((state) => !state);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={3}>
        <Typography variant={"h1"}>Verfügbarkeitsanalyse</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"auslastungverfuegbarkeit-seriennummer"}
          label="Seriennummer:"
          value={seriennummer ?? ""}
          onChange={(event) => setSeriennummer(event.target.value ?? "")}
          TypographyProps={{
            className: "auslastungverfuegbarkeit__LabelStyle",
          }}
          TextFieldProps={{ className: "auslastungverfuegbarkeit__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"auslastungverfuegbarkeit-glnderrns"}
          label="GLN der RNS:"
          value={glnDerRns ?? ""}
          onChange={(event) => setGlnDerRns(event.target.value ?? "")}
          TypographyProps={{
            className: "auslastungverfuegbarkeit__LabelStyle",
          }}
          TextFieldProps={{ className: "auslastungverfuegbarkeit__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <MultipleSelectCheckbox
          label={"Status:"}
          limitTags={4}
          options={automatStatusEnum.items}
          id="auslastungverfuegbarkeit-status"
          onChange={(values) => setStatus(values)}
          resetSelectedValues={reset}
          TypographyProps={{
            className: "auslastungverfuegbarkeit__LabelStyle",
          }}
          AutocompleteProps={{
            className: "auslastungverfuegbarkeit__InputStyle",
          }}
          StackProps={{ columnGap: 2 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Button size="small" variant="secondary" onClick={resetFilter}>
            Felder zürucksetzen
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              ExportService.downloadAuslastungVerfuegbarkeit(
                glnDerRns,
                seriennummer,
                status?.map((e) => e.id).join(",") ?? ""
              );
            }}
          >
            Excel Export
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default AuslastungVerfuegbarkeit;
