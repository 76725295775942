import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import AutomatInvalidFieldsDTO from "./dto/AutomatInvalidFieldsDTO";

const ZurBearbeitungAnstehend = {
  load: (handler: ServiceResponseHandler) => {
    axiosCalls
      .sendGet(
        `${Config.dataServiceUrl}automate/search/zurbearbeitunganstehend`
      )
      .then((response) => {
        handler.callbackOnSuccess(
          AutomatInvalidFieldsDTO.convertResponseToAutomatInvalidFieldsResult(
            response
          )
        );
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error)
      });
  },
};

export default ZurBearbeitungAnstehend;
