import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import IpBereichDTO from "./dto/IpBereichDTO";

const IpBereichService = {
  load: (handler: ServiceResponseHandler) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}automate/erreichbarkeitperiprange`)
      .then((response) => {
        handler.callbackOnSuccess(IpBereichDTO.convertIpBereichResponse(response))
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error)
      });
  },
};

export default IpBereichService;
