import { PaletteColorOptions, PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    secondary?: PaletteColorOptions;
    monochrome?: PaletteColorOptions;
  }
}

export const ColorPaletteOptions: PaletteOptions = {
  primary: {
    main: "#1c65b1",
  },
  secondary: {
    main: "#fbe530",
  },
  success: {
    main: "#488355",
  },
  warning: {
    main: "#fbc40a",
  },
  error: {
    main: "#e81b35",
  },
  monochrome: {
    main: "#504952",
  },
  text: {
    primary: "#08284a",
    secondary: "#000000",
  },
};

export default ColorPaletteOptions;
