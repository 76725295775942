import { CheckCircle } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  Link,
  Stack
} from "@mui/material";
import { ColDef } from "ag-grid-community";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";
import { TabControlContext } from "page/Automatensuche/components/TabControl/TabControlProvider";
import React, { useContext, useEffect, useState } from "react";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import "./rnsAktiveAutomaten.scss";

interface RNSAktiveAutomatenProps {
  automatenblattInformation: AutomatenblattInformation;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RNSAktiveAutomatenColumnDefs: Array<ColDef> = [
  {
    field: "serialNumber",
    headerName: "Seriennummer",
    suppressSizeToFit: false,
    suppressAutoSize: false,
    cellClass: "serialNumberCell",
    resizable: false,
  },
  {
    field: "isCurrentMachine",
    headerName: "Dieser Automat",
    suppressSizeToFit: false,
    suppressAutoSize: false,
    resizable: false,
    cellRenderer: CellValueRenderer,
  },
];

interface TabelElements {
  serialNumber: string;
  isCurrentMachine: React.ReactNode | undefined;
  automatKey: string;
}

const RNSAktiveAutomaten: React.FC<RNSAktiveAutomatenProps> = (
  props: RNSAktiveAutomatenProps
) => {
  const { anzahlAktiveAutomatenInRns, aktiveSerienNrInRns, seriennummer } =
    props.automatenblattInformation;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tabControlContext = useContext(TabControlContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const otherMachines: Array<TabelElements> = [];

  useEffect(() => {
    var aktiveAutomaten = aktiveSerienNrInRns.split(";");
    aktiveAutomaten.forEach((value) => {
      otherMachines.push({
        serialNumber: value,
        isCurrentMachine:
          value === seriennummer.toString() ? (
            <CheckCircle
              className="uiElement"
              fontSize="small"
              sx={{ color: "success.main", alignSelf: "center" }}
            />
          ) : undefined,
        automatKey: props.automatenblattInformation.automatKey,
      });
    });
    // eslint-disable-next-line
  }, [
    otherMachines,
    aktiveSerienNrInRns,
    seriennummer,
    props.automatenblattInformation.automatKey,
  ]);

  return (
    <Stack className="rnsStack">
      <Link
        className="aktiveAutomatenButton"
        component="button"
        onClick={handleOpen}
      >
        Aktive Automaten:
      </Link>

      <Input
        className="rnsInputElement"
        id={`${props.id}-input`}
        readOnly
        disableUnderline
        value={anzahlAktiveAutomatenInRns}
        onChange={props.onChange}
      />

      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Aktive Automaten der RNS</DialogTitle>
          <DialogContent>
            <div className="gridDiv">
              <DataGrid
                columnDefs={RNSAktiveAutomatenColumnDefs}
                rowsDataDef={{ data: otherMachines }}
                onRowClicked={(machine) => {
                  tabControlContext.push(machine.serialNumber);
                  handleClose();
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </Stack>
  );
};
export default RNSAktiveAutomaten;
