import AutomatenblattInformation from "../interface/AutomatenblattInformation";

const AutomatenblattInformationDTO = {
  createDTO: (input: AutomatenblattInformation) => {
    let newDTO: AutomatenblattInformation = {
      aktiveSerienNrInRns: input.aktiveSerienNrInRns,
      andereRegion: input.andereRegion,
      anlageBenutzer: input.anlageBenutzer,
      anlageDatum: input.anlageDatum,
      anzahlAktiveAutomatenInRns: input.anzahlAktiveAutomatenInRns,
      art: input.art,
      artKey: input.artKey,
      athGln: input.athGln,
      auslastungVerfuegbarkeitDTO: input.auslastungVerfuegbarkeitDTO,
      automatCredentialList: input.automatCredentialList,
      automatKey: input.automatKey,
      automatStatusAktuell: input.automatStatusAktuell,
      automatStatusGueltigBisAktuell: input.automatStatusGueltigBisAktuell,
      automatStatusGueltigVonAktuell: input.automatStatusGueltigVonAktuell,
      automatStatusGueltigVonInZukunft: input.automatStatusGueltigVonInZukunft,
      automatStatusInZukunft: input.automatStatusInZukunft,
      eigentuemer: input.eigentuemer,
      epcomGroupNameForGui: input.epcomGroupNameForGui,
      garantieEnde: input.garantieEnde,
      herstellerKuerzel: input.herstellerKuerzel,
      herstellerName: input.herstellerName,
      herstellungsdatum: input.herstellungsdatum,
      inbetriebnahme: input.inbetriebnahme,
      infofeld1: input.infofeld1,
      infofeld2: input.infofeld2,
      infofeld3: input.infofeld3,
      kommTypen: input.kommTypen,
      letzterErfolgreicherAufruf: input.letzterErfolgreicherAufruf,
      letzterZugriffAlle: input.letzterZugriffAlle,
      modell: input.modell,
      modellKey: input.modellKey,
      modellMerkmale: input.modellMerkmale,
      notVisible: input.notVisible,
      notizen: input.notizen,
      nutzungsart: input.nutzungsart,
      readonly: input.readonly,
      rns: input.rns,
      sapBonNummerDTO: input.sapBonNummerDTO,
      ruhtBis: input.ruhtBis ? input.ruhtBis : undefined,
      ruhtVon: input.ruhtVon ? input.ruhtVon : undefined,
      sammlungAufTisch: input.sammlungAufTisch,
      seriennummer: input.seriennummer,
      sortierKonf: input.sortierKonf,
      sortierKonfText: input.sortierKonfText,
      ssidAktiv: input.ssidAktiv,
      ssidGueltigVon: input.ssidGueltigVon,
      standort: input.standort,
      standortHinweis: input.standortHinweis,
      tkClearer: input.tkClearer,
      tkDaten: input.tkDaten,
      zaehlstelle: input.zaehlstelle,
    };
    return newDTO;
  },
};

export default AutomatenblattInformationDTO;
