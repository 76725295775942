import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import ArtikleSearchDTO from "./dto/ArtikleSearchDTO";

const ArtikleService = {
  search: (
    machineId: string,
    barcode: number,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(
        `${Config.artikleServiceUrl}artikelsuche-service/info-combined`,
        {
          params: { machineId: machineId, barcode: barcode },
        }
      )
      .then((response) => {
        callbackOnSuccess(ArtikleSearchDTO.convertArtikleResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default ArtikleService;
