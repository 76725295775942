import { SxProps, Typography } from "@mui/material";
import { redGreenSelector } from "global/util/styleMethods";
import React from "react";

interface props {
  text: string;
  id?: string;
  sx?: SxProps;
}

export const ListItemTypography: React.FC<props> = (props: props) => {
  return (
    <Typography
      id={props.id}
      sx={{
        justifySelf: "flex-end",
        fontWeight: "bold",
        fontSize: 20,
        ...props.sx,
      }}
    >{`${props.text}`}</Typography>
  );
};

interface propsColor extends props {
  colorChangeBool: boolean;
}

export const ColoredListItemTypography: React.FC<propsColor> = (
  props: propsColor
) => {
  return (
    <ListItemTypography
      id={props.id}
      sx={{
        ...props.sx,
        color: redGreenSelector(props.colorChangeBool),
      }}
      text={props.text}
    />
  );
};

export const ColoredSmallListItemTypography: React.FC<propsColor> = (
  props: propsColor
) => {
  return (
    <ListItemTypography
      id={props.id}
      sx={{
        ...props.sx,
        fontSize: 13.5,
        color: redGreenSelector(props.colorChangeBool),
      }}
      text={props.text}
    />
  );
};
