import { Chip, Stack } from "@mui/material";
import { CellClickedEvent, GridApi, GridOptions } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import "./dataGrid.scss";
import DataGridProps from "./interface/DataGridProps";

const DEFAULT_GRID_HEIGHT = 300;

const DataGrid = <T,>(props: PropsWithChildren<DataGridProps<T>>) => {
  const [rowData, setRowData] = useState<Array<T>>(); // Set rowData to Array of Objects, one Object per Row
  const [gridApi, setGridApi] = useState<GridApi>();

  const onRowClicked = props.onRowClicked;
  const cellClickedListener = useCallback(
    (event: CellClickedEvent) => {
      if (onRowClicked) onRowClicked(event.data, event.column.getColId());
    },
    [onRowClicked]
  );
  const hasActions = props.exportDef || props.gridActions;
  const hasUncontrolledData = props.rowsDataDef.uncontrolledDataFetchCall !== undefined

  const gridOptions: GridOptions = {
    ...props.gridOptions,
    defaultColDef: {
      // Default Column Properties
      width: 120,
      sortable: true,
      resizable: true,
      flex: 1,
    },
    overlayNoRowsTemplate: "keine Daten vorhanden",
    columnDefs: props.columnDefs, // Column Defs for Columns
    rowHeight: 26,
    headerHeight: 36,
    animateRows: true, // Optional - set to 'true' to have rows animate when sorted
    overlayLoadingTemplate: "Wird geladen...",
  };

  const fetchData = () => {
    if (hasUncontrolledData) {
      props.rowsDataDef.uncontrolledDataFetchCall!({
        callbackOnSuccess: (data: T[]) => {
          setRowData(data)
        },
        callbackOnFail: (error: Error) => {
          // ignore the error param since the errors are handled higher up in the rest call interceptor
          setRowData([])// this will hide the loading overlay and show the no rows overlay
        }
      })
    }
  }

  useEffect(() => {
    if (hasUncontrolledData) { return }
    if (props.rowsDataDef.isFetchingData === true) {
      gridApi?.showLoadingOverlay()
    } else if ((props.rowsDataDef.data ?? []).length === 0) {
      gridApi?.showNoRowsOverlay()
    } else {
      gridApi?.hideOverlay()
    }
  }, [props.rowsDataDef])

  const dataGridContainerClassName = `ag-theme-alpine relativeGrid ${hasActions ? "withGridActionsRow" : ""
    }`;

  const gridData = props.rowsDataDef.uncontrolledDataFetchCall ? rowData : props.rowsDataDef.data

  return (
    <div
      className={dataGridContainerClassName}
      style={{ height: props.height ?? DEFAULT_GRID_HEIGHT }}
    >
      {hasActions && (
        <Stack
          className="gridActionsRow buttonStack"
          justifyContent="space-between"
        >
          <Stack className="gridActionsRow filterButtons">
            {props.gridActions}
          </Stack>

          {props.exportDef && (
            <Stack className="gridActionsRow">
              <Chip
                className="exportChip"
                size="small"
                variant="outlined"
                label={props.exportDef?.name ?? "EXCEL Export"}
                clickable
                onClick={() => props.exportDef?.action!()}
                disabled={props.exportDef.disabled}
              />
            </Stack>
          )}
        </Stack>
      )}
      <AgGridReact
        ref={props.dataGridRef}
        className={props.className}
        rowHeight={props.rowHeight}
        gridOptions={gridOptions}
        rowData={gridData} // Row Data for Rows
        rowSelection="single"
        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
        onRowDataChanged={(event) => {
          if (props.onRowSelected) props.onRowSelected(undefined)
          if (props.onRowDataChanged) props.onRowDataChanged(gridData)
        }}
        onRowSelected={(event) => {
          if (props.onRowSelected && event.node.isSelected())
            props.onRowSelected(event.data)
        }}
        onGridReady={(event) => {
          setGridApi(event.api)
          if (props.onGridReady) props.onGridReady(event)
          fetchData()
        }}
      />
    </div>
  );
};

export default DataGrid;
