import { AxiosResponse } from "axios";
import SapOutputParam from "../interface/SapOutputParam";

const SapDTO = {
  convertSapResponseDTO: (response: AxiosResponse): SapOutputParam => {
    if (response.data) {
      return {
        anlagen: response.data.anlagen,
        cntDoublettenDb: response.data.cntDoublettenDb,
        cntImported: response.data.cntImported,
        cntMovedToPostion: response.data.cntMovedToPostion,
        cntNotForRegion: response.data.cntNotForRegion,
        cntPositions: response.data.cntPositions,
        cntPositionsWithoutWarengruppe:
          response.data.cntPositionsWithoutWarengruppe,
        doublettenCheckDb: response.data.doublettenCheckDb,
        errorTyp: response.data.errorTyp,
        excelDTO: response.data.excelDTO,
        exportPositions: response.data.exportPositions,
        importPositions: response.data.importPositions,
        message: response.data.message,
        page: response.data.page,
        pageSize: response.data.pageSize,
        positionEdits: response.data.positionEdits,
        rnGln: response.data.rnGln,
        rnsGln: response.data.rnsGln,
      };
    }
    return {} as SapOutputParam;
  },
};
export default SapDTO;
