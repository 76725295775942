import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

export const RNSPanelColumnDefs: Array<ColDef> = [
  {
    field: "gueltigVon",
    headerName: "Datum von",
    maxWidth: 140,
    cellClass: "centerAlignedText",
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    field: "gueltigBis",
    headerName: "Datum bis",
    maxWidth: 140,
    cellClass: "centerAlignedText",
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    field: "rnsGln",
    headerName: "GLN",
    maxWidth: 200,
    cellClass: "centerAlignedText",
  },
  {
    field: "name",
    headerName: "Name",
  },
];
