import { Grid, Stack } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import IpBereichService from "service/data-service/automate-controller/IpBereich.service";
import ExportService from "service/report-service/export/Export.service";
import IpRangeColumnDefs from "./grid-column-defs";

const IpRange: React.FC = () => {

  return (
    <Stack className="ipRange">
      <Grid container spacing={5} width={"auto"}>
        <Grid item xs={12}>
          <DataGrid
            height={430}
            columnDefs={IpRangeColumnDefs}
            exportDef={{ action: ExportService.ipRange }}
            rowsDataDef={{
              uncontrolledDataFetchCall: IpBereichService.load
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default IpRange;
