import { ColDef } from "ag-grid-community";

const HistoriePanelColumnDefs: Array<ColDef> = [
  {
    headerName: "Speicherung",
    field: "createdString",
    width: 160,
    flex: 0,
  },
  {
    headerName: "Feldname",
    field: "beschreibung",
    width: 280,
    flex: 0,
  },
  {
    headerName: "Inhalt alt",
    field: "wertAlt",
    flex: 0,
  },
  {
    headerName: "Inhalt neu",
    field: "wertNeu",
    initialWidth: 200,
    flex: 0,
  },
  {
    headerName: "Gültig ab",
    field: "gueltigVonString",
    width: 100,
    flex: 0,
  },
  {
    headerName: "Rolle",
    field: "rolle",
    width: 80,
    flex: 0,
  },
  {
    headerName: "User",
    field: "benutzer",
    minWidth: 260,
    flex: 1,
  },
];

export default HistoriePanelColumnDefs;
