import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SupportIcon from "@mui/icons-material/Support";
import TokenIcon from "@mui/icons-material/Token";
import {
  HREF_ADMIN_ANLAGENEUEUSER,
  HREF_ADMIN_ANLAGERECHNUNGSNEMER,
  HREF_ADMIN_ANWENDERNACHRICHTEN,
  HREF_ADMIN_IPBEREICH,
  HREF_ADMIN_IPWHITELIST,
  HREF_ADMIN_RECHNUNGSDATENATH, HREF_ADMIN_STAMMDATENABHOLUNG,
  HREF_AUTOMATENKAUFANFRAGEN,
  HREF_AUTOMATENSUCHE,
  HREF_BERICHTE_ANSICHTFEHLER,
  HREF_BERICHTE_ANSICHTWECHSEL, HREF_BERICHTE_DSMD_FEHLERHAFT,
  HREF_BERICHTE_ERREICHBARKEITEN,
  HREF_BERICHTE_FLATFILE, HREF_BERICHTE_VERFUGBARKEISANALYSE,
  HREF_BERICHTE_ZURBEARBEITUNG,
  HREF_DATENPFLEGE_RNS,
  HREF_HILFE_BENUTZERHANDBUCH,
  HREF_HOME as HREF_Cockpit,
  HREF_NOTIZEN_SUCHE,
  HREF_NOTIZEN_WIEDERVORLAGE,
  HREF_RECHNUNGSDATEN_GLNS_OHNE_ZUORDNUNG,
  HREF_RECHNUNGSDATEN_SUCHE
} from "global/util/routes";
import { SideMenuItem } from "./components/Sidemenu/SideMenuItem";

export const MENU_ID_COCKPIT = 'MENU_ID_COCKPIT';

export const MENU_ID_AUTOMATEN = 'MENU_ID_AUTOMATEN';
export const MENU_ID_AUTOMATEN_ENTRY_AUTOMATENSUCHE = 'MENU_ID_AUTOMATEN_ENTRY_AUTOMATENSUCHE';
export const MENU_ID_AUTOMATEN_ENTRY_KAUFANFRAGEN = 'MENU_ID_AUTOMATEN_ENTRY_KAUFANFRAGEN';

export const MENU_ID_BERICHTE = 'MENU_ID_BERICHTE';
export const MENU_ID_BERICHTE_EntryAnsichtFehlerhafteMm = 'MENU_ID_BERICHTE_EntryAnsichtFehlerhafteMm';
export const MENU_ID_BERICHTE_EntryAnsichtWechselNokAufOk = 'MENU_ID_BERICHTE_EntryAnsichtWechselNokAufOk';
export const MENU_ID_BERICHTE_EntryErreichbarkeitenJeKommart = 'MENU_ID_BERICHTE_EntryErreichbarkeitenJeKommart';
export const MENU_ID_BERICHTE_EntryZurBearbeitungAnstehend = 'MENU_ID_BERICHTE_EntryZurBearbeitungAnstehend';
export const MENU_ID_BERICHTE_EntryFlatfile = 'MENU_ID_BERICHTE_EntryFlatfile';
export const MENU_ID_BERICHTE_EntryDsmdFehlerhaft = 'MENU_ID_BERICHTE_EntryDsmdFehlerhaft';
export const MENU_ID_BERICHTE_EntryVerfuegbarkeit = 'MENU_ID_BERICHTE_EntryVerfuegbarkeit';

export const MENU_ID_DATENPFLEGE = 'MENU_ID_DATENPFLEGE';
export const MENU_ID_DATENPFLEGE_EntryRns = 'MENU_ID_DATENPFLEGE_EntryRns';

export const MENU_ID_NOTIZEN = 'MENU_ID_NOTIZEN';
export const MENU_ID_NOTIZEN_EntryNotizenSuche = 'MENU_ID_NOTIZEN_EntryNotizenSuche';
export const MENU_ID_NOTIZEN_EntryWiedervorlage = 'MENU_ID_NOTIZEN_EntryWiedervorlage';

export const MENU_ID_RECHNUNGSDATEN_ATH = 'MENU_ID_RECHNUNGSDATEN_ATH';
export const MENU_ID_RECHNUNGSDATEN_ATH_EntrySuchePositionen = 'MENU_ID_RECHNUNGSDATEN_ATH_EntrySuchePositionen';
export const MENU_ID_RECHNUNGSDATEN_ATH_EntryGlnOhneZuordnung = 'MENU_ID_RECHNUNGSDATEN_ATH_EntryGlnOhneZuordnung';

export const MENU_ID_HILFE = 'MENU_ID_HILFE';
export const MENU_ID_HILFE_EntryBenutzerhandbuch = 'MENU_ID_HILFE_EntryBenutzerhandbuch';

export const MENU_ID_ADMIN = 'MENU_ID_ADMIN';
export const MENU_ID_ADMIN_EntryAnwenderNachrichten = 'MENU_ID_ADMIN_EntryAnwenderNachrichten';
export const MENU_ID_ADMIN_EntryAnlageNeuerUser = 'MENU_ID_ADMIN_EntryAnlageNeuerUser';
export const MENU_ID_ADMIN_EntryAnlageRechnungsempfaenger = 'MENU_ID_ADMIN_EntryAnlageRechnungsempfaenger';
export const MENU_ID_ADMIN_EntryStammdatenabholungClearer = 'MENU_ID_ADMIN_EntryStammdatenabholungClearer';
export const MENU_ID_ADMIN_EntryRechnungsdatenAth = 'MENU_ID_ADMIN_EntryRechnungsdatenAth';
export const MENU_ID_ADMIN_EntryIpBereiche = 'MENU_ID_ADMIN_EntryIpBereiche';
export const MENU_ID_ADMIN_EntryIpWhiteList = 'MENU_ID_ADMIN_EntryIpWhiteList';

const epcommTreeViewItems: SideMenuItem[] = [
  {
    id: MENU_ID_COCKPIT,
    text: "Cockpit",
    link: HREF_Cockpit,
    icon: DashboardIcon,
  },
  {
    id: MENU_ID_AUTOMATEN,
    text: "Automaten",
    icon: DoorSlidingIcon,
    children: [
      {
        id: MENU_ID_AUTOMATEN_ENTRY_AUTOMATENSUCHE,
        text: "Automatensuche",
        link: HREF_AUTOMATENSUCHE,
      },
      {
        id: MENU_ID_AUTOMATEN_ENTRY_KAUFANFRAGEN,
        text: "Kaufanfragen",
        link: HREF_AUTOMATENKAUFANFRAGEN,
      },
    ],
  },
  {
    id: MENU_ID_BERICHTE,
    text: "Berichte",
    icon: AssessmentIcon,
    children: [
      {
        id: MENU_ID_BERICHTE_EntryAnsichtFehlerhafteMm,
        text: "Ansicht fehlerhafte MM",
        link: HREF_BERICHTE_ANSICHTFEHLER,
      },
      {
        id: MENU_ID_BERICHTE_EntryAnsichtWechselNokAufOk,
        text: "Ansicht Wechsel NOK auf OK",
        link: HREF_BERICHTE_ANSICHTWECHSEL,
      },
      {
        id: MENU_ID_BERICHTE_EntryErreichbarkeitenJeKommart,
        text: "Erreichbarkeiten je KommArt.",
        link: HREF_BERICHTE_ERREICHBARKEITEN,
      },
      {
        id: MENU_ID_BERICHTE_EntryZurBearbeitungAnstehend,
        text: "Zur Bearbeitung anstehend",
        link: HREF_BERICHTE_ZURBEARBEITUNG,
      },
      {
        id: MENU_ID_BERICHTE_EntryFlatfile,
        text: "Flatfile",
        link: HREF_BERICHTE_FLATFILE,
      },
      {
        id: MENU_ID_BERICHTE_EntryDsmdFehlerhaft,
        text: "DSMD fehlerhaft",
        link: HREF_BERICHTE_DSMD_FEHLERHAFT,
      },
      {
        id: MENU_ID_BERICHTE_EntryVerfuegbarkeit,
        text: "Verfügbarkeitsanalyse",
        link: HREF_BERICHTE_VERFUGBARKEISANALYSE,
      },
    ],
  },
  {
    id: MENU_ID_DATENPFLEGE,
    text: "Datenpflege",
    icon: TokenIcon,
    children: [
      {
        id: MENU_ID_DATENPFLEGE_EntryRns,
        text: "RNS",
        link: HREF_DATENPFLEGE_RNS,
      },
    ],
  },
  {
    id: MENU_ID_NOTIZEN,
    text: "Notizen",
    icon: SpeakerNotesIcon,
    children: [
      {
        id: MENU_ID_NOTIZEN_EntryNotizenSuche,
        text: "Suche",
        link: HREF_NOTIZEN_SUCHE,
      },
      {
        id: MENU_ID_NOTIZEN_EntryWiedervorlage,
        text: "Wiedervorlage",
        link: HREF_NOTIZEN_WIEDERVORLAGE,
      },
    ],
  },
  {
    id: MENU_ID_HILFE,
    text: "Hilfe",
    icon: SupportIcon,
    children: [
      {
        id: MENU_ID_HILFE_EntryBenutzerhandbuch,
        text: "Benutzerhandbuch",
        link: HREF_HILFE_BENUTZERHANDBUCH,
      },
    ],
  },
  {
    id: MENU_ID_RECHNUNGSDATEN_ATH,
    text: "Rechnungsdaten ATH",
    icon: SpeakerNotesIcon,
    children: [
      {
        id: MENU_ID_RECHNUNGSDATEN_ATH_EntrySuchePositionen,
        text: "Suche Positionen",
        link: HREF_RECHNUNGSDATEN_SUCHE,
      },
      {
        id: MENU_ID_RECHNUNGSDATEN_ATH_EntryGlnOhneZuordnung,
        text: "GLN 's ohne Zuordnung",
        link: HREF_RECHNUNGSDATEN_GLNS_OHNE_ZUORDNUNG,
      },
    ],
  },
  {
    id: MENU_ID_ADMIN,
    text: "Administration",
    icon: ManageAccountsIcon,
    children: [
      {
        id: MENU_ID_ADMIN_EntryAnwenderNachrichten,
        text: "Anwendernachrichten",
        link: HREF_ADMIN_ANWENDERNACHRICHTEN,
      },
      {
        id: MENU_ID_ADMIN_EntryAnlageNeuerUser,
        text: "Anlage neuer User",
        link: HREF_ADMIN_ANLAGENEUEUSER,
      },
      {
        id: MENU_ID_ADMIN_EntryAnlageRechnungsempfaenger,
        text: "Anlage Rechnungsnehmer",
        link: HREF_ADMIN_ANLAGERECHNUNGSNEMER,
      },
      {
        id: MENU_ID_ADMIN_EntryStammdatenabholungClearer,
        text: "Stammdatenabholung Clearer",
        link: HREF_ADMIN_STAMMDATENABHOLUNG,
      },
      {
        id: MENU_ID_ADMIN_EntryRechnungsdatenAth,
        text: "Rechnungsdaten ATH",
        link: HREF_ADMIN_RECHNUNGSDATENATH,
      },
      {
        id: MENU_ID_ADMIN_EntryIpBereiche,
        text: "IP Bereich",
        link: HREF_ADMIN_IPBEREICH,
      },
      {
        id: MENU_ID_ADMIN_EntryIpWhiteList,
        text: "IP Whitelist",
        link: HREF_ADMIN_IPWHITELIST,
      },
    ],
  },
];

export default epcommTreeViewItems;
