import Config from "Config";
import Keycloak from "keycloak-js";

let disableKeycloak = false; // DEBUG manuell setzen, falls Keycloak mal nicht funktioniert

const keycloakConfig = {
  url: Config.keycloakUrl,
  realm: Config.keycloakRealm,
  clientId: Config.keycloakClientId,
};

// the keycloak instance object
const keycloak: Keycloak.KeycloakInstance = Keycloak(keycloakConfig);

export const KeyCloakService = {
  /**
   * Initializes keycloak.
   * It will check if the current keycloak object is authenticated and otherwise it will
   * redirect you to the given keycloak login page to execute a login.
   * After successful login a tokenRefresh execution is scheduled, when the initial accessToken expires
   * @param callback a function which is called after a successful login
   */
  initKeycloak(callback: () => void) {
    if (!keycloak.authenticated && !disableKeycloak) {
      keycloak
        .init({ onLoad: "login-required" })
        .catch(function () {
          alert("failed to authenticate by keycloak");
        })
        .finally(() => {
          callback();
        });
    } else {
      callback();
    }
  },

  /**
   * Calculates the remaining lifetime of the Token in milliseconds
   * @returns time in milliseconds when the token turns invalid
   */
  calculateTimeToTokenExpiration(): number {
    return keycloak.tokenParsed?.exp! * 1000 - new Date().getTime();
  },

  updateToken() {
    return keycloak.updateToken(30);
  },

  doLogin() {
    keycloak.login();
  },

  doLogout() {
    keycloak.logout();
  },

  getToken(): string | undefined {
    return keycloak.token;
  },

  isLoggedIn(): boolean {
    return !!keycloak.token;
  },

  getUsername(): string {
    return keycloak.tokenParsed?.preferred_username;
  },

  getFullname(): string {
    return (
      keycloak.tokenParsed?.given_name + " " + keycloak.tokenParsed?.family_name
    );
  },
};
