import { OptionsObject, useSnackbar, VariantType } from "notistack";

export type SnackbarFeatures = OptionsObject & {
    /**
     * Error that is the cause for the snackbar. if provided it will be logged on console.
     */
    error?: Error;
}

export type SnackbarGeneratorFunction = (message: string, features?: SnackbarFeatures) => void;

export interface SnackbarGenerator {
    showError: SnackbarGeneratorFunction,
    showWarn: SnackbarGeneratorFunction,
    showInfo: SnackbarGeneratorFunction,
}

/**
 * Custom hook to access snackbar functionality
 * Usage:
 * const { showError, showInfo } = useSnackbarGenerator();
 * ...
 * showError('Error message');
 */

const useSnackbarGenerator = (): SnackbarGenerator => {

    const { enqueueSnackbar } = useSnackbar();

    /**
     * Builds and OptionsObject based on given variant and features params.
     * IF not provided in features param, persist and preventDuplicate attributes are set to TRUE.
     * @param variant - variant of the Snackbar: info, error, warn
     * @param features - options for the Snackbar
     * @returns 
     */
    function buildOptionsWithDefaults(variant: VariantType, features?: SnackbarFeatures): OptionsObject {
        const extras: OptionsObject = {
            ...features,
            ...{
                variant: variant,
                persist: features?.persist ?? true,
                preventDuplicate: features?.preventDuplicate ?? true,
            }
        }
        return extras;
    }

    const errorHandler = (message: string, features?: SnackbarFeatures) => {
        enqueueSnackbar(message, buildOptionsWithDefaults('error', features));
        if (features?.error) {
            console.error(message, features?.error);
        }
    };
    const warnHandler = (message: string, features?: SnackbarFeatures) => {
        enqueueSnackbar(message, buildOptionsWithDefaults('warning', features));
    };
    const infoHandler = (message: string, features?: SnackbarFeatures) => {
        enqueueSnackbar(message, buildOptionsWithDefaults('info', features));
    };

    return { showError: errorHandler, showWarn: warnHandler, showInfo: infoHandler, }
};

export default useSnackbarGenerator;
