import {
  Alert,
  CircularProgress,
  Grid,
  Stack,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CockpitInformationService from "service/data-service/cockpit-controller/CockpitInformation.service";
import CockpitInformationDTO from "service/data-service/cockpit-controller/interface/CockpitInformation";
import CockpitVisibilitiesDTO from "service/data-service/cockpit-controller/interface/CockpitVisibilities";
import EpgCardInformationDTO from "service/data-service/cockpit-controller/interface/EpgCardInformation";
import "./Cockpit.css";
import CockpitTheme from "./CockpitTheme";
import Anwendernachrichten from "./components/Anwendernachrichten/Anwendernachrichten";
import Automatenstatus from "./components/Automatenstatus/Automatenstatus";
import EpgMonitoring from "./components/EpgMonitoring/EpgMonitoring";
import Fehleranzeige from "./components/Fehleranzeige/Fehleranzeige";
import Kaufanfragen from "./components/Kaufanfragen/Kaufanfragen";
import Notizen from "./components/Notizen/Notizen";
import Rechnungen from "./components/Rechnungen/Rechnungen";
import {
  generateDefaultCockpitInformation,
  generateDefaultCockpitVisibilities,
  generateDefaultEpgCardInformation,
} from "./generator/defaultObjectGenerators";

const Cockpit: React.FC = () => {
  const [cockpitInformationDTO, setCockpitInformationDTO] =
    useState<CockpitInformationDTO>(generateDefaultCockpitInformation());

  const [epgCardInformationDTO, setEpgCardInformationDTO] =
    useState<EpgCardInformationDTO>(generateDefaultEpgCardInformation());

  const [CockpitVisibilities, setCockpitVisibilities] =
    useState<CockpitVisibilitiesDTO>(generateDefaultCockpitVisibilities());

  const [loadingHidden, setLoadingHidden] = useState<boolean>(false);
  const [failedToLoadAlertHidden, setFailedToLoadAlertHidden] =
    useState<boolean>(true);
  const [cardGridHidden, setCardGridHidden] = useState<boolean>(true);

  useEffect(() => {
    function callbackForData(
      cockpitInformationDTO: CockpitInformationDTO,
      epgCardInformationDTO: EpgCardInformationDTO
    ) {
      setCockpitInformationDTO(cockpitInformationDTO);
      setEpgCardInformationDTO(epgCardInformationDTO);
    }

    function callbackForVisibilities(
      cockpitVisibilitiesDTO: CockpitVisibilitiesDTO
    ) {
      setCockpitVisibilities(cockpitVisibilitiesDTO);
      setLoadingHidden(true);
      setCardGridHidden(false);
    }

    function callbackOnFail(error: Error) {
      setFailedToLoadAlertHidden(false);
      setLoadingHidden(true);
      setCardGridHidden(true);
    }

    CockpitInformationService.retrieveCockpitInformation(
      callbackForData,
      callbackForVisibilities,
      callbackOnFail
    );
  }, []);

  return (
    <ThemeProvider theme={CockpitTheme}>
      <Stack alignItems="center">
        <CircularProgress
          sx={{
            display: loadingHidden ? "none" : "inline",
            marginTop: 2,
            marginBottom: 2,
          }}
          size={80}
        />
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            display: failedToLoadAlertHidden ? "none" : "flex",
            borderWidth: 2,
            alignItems: "center",
            fontSize: 16,
          }}
        >
          <strong>Beim Abrufen der Daten ist ein Fehler aufgetreten!</strong>
        </Alert>
      </Stack>
      <Grid
        container
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }}
        padding={6}
        sx={{ display: cardGridHidden ? "none" : "flex" }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Automatenstatus
            countOfDsmdFailure={cockpitInformationDTO.rnbtbDsmdFailed}
            countOfVendingMachineFailure={cockpitInformationDTO.atMmFailed}
            countOfWarningsReachability={
              cockpitInformationDTO.erreichbarkeitWarnings
            }
            dsmdFailureVisible={CockpitVisibilities.rnbtbDsmdFailedShow}
            vendingMachineFailureVisible={CockpitVisibilities.atMmFailedShow}
            warningsReachabilityVisible={
              CockpitVisibilities.erreichbarkeitWarningsShow
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Kaufanfragen
            countOfPurchaseRequest={cockpitInformationDTO.kaufanfragen}
            purchaseRequestVisible={CockpitVisibilities.kaufanfragenShow}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Fehleranzeige
            countOfDataError={
              cockpitInformationDTO.zurBearbeitungAnstehendStammdaten
            }
            countOfClearingError={
              cockpitInformationDTO.zurBearbeitungAnstehendClearing
            }
            countOfLogfileError={
              cockpitInformationDTO.zurBearbeitungAnstehendLogfile
            }
            clearingErrorVisible={
              CockpitVisibilities.zurBearbeitungAnstehendClearingShow
            }
            baseDataErrorVisible={
              CockpitVisibilities.zurBearbeitungAnstehendStammdatenShow
            }
            logfileErrorVisible={
              CockpitVisibilities.zurBearbeitungAnstehendLogfileShow
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Notizen
            countOfNotes={cockpitInformationDTO.notizenWiedervorlage}
            notesVisible={CockpitVisibilities.notizenWiedervorlageShow}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Rechnungen
            billsWithoutAssignment={
              cockpitInformationDTO.sapRdNichtZugeordnetGh
            }
            billsWithoutAssignmentVisible={
              CockpitVisibilities.sapRdNichtZugeordnetGhShow
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <EpgMonitoring
            alsLoginDritterAnzahl={epgCardInformationDTO.alsLoginDritterAnzahl}
            alsLoginDritterExists={epgCardInformationDTO.alsLoginDritterExists}
            erreichbarkeitIpBereicheFailed={
              epgCardInformationDTO.erreichbarkeitIpBereicheFailed
            }
            erreichbarkeitIpBereicheFailedAnzahl={
              epgCardInformationDTO.erreichbarkeitIpBereicheFailedAnzahl
            }
            sapRdPossesWithoutEpcomRegions={
              epgCardInformationDTO.sapRdPossesWithoutEpcomRegions
            }
            sapRdPossesWithoutEpcomRegionsAnzahl={
              epgCardInformationDTO.sapRdPossesWithoutEpcomRegionsAnzahl
            }
            archivTooManyFiles={epgCardInformationDTO.archivTooManyFiles}
            archivTooManyFilesThreshold={
              epgCardInformationDTO.archivTooManyFilesThreshold
            }
            pingInvalidAutomatenAnzahl={
              epgCardInformationDTO.pingInvalidAutomatenAnzahl
            }
            archivAuslastungExceeded={
              epgCardInformationDTO.archivAuslastungExceeded
            }
            jobsOk={epgCardInformationDTO.jobsOk}
            epgCardVisible={CockpitVisibilities.epgKachelShow}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Anwendernachrichten
            nachricht={cockpitInformationDTO.anwendernachricht}
            nachrichtVisible={CockpitVisibilities.anwendernachrichtShow}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Cockpit;
