import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

let date = new Date();

const NotZuOkColumnDefs: Array<ColDef> = [
  {
    headerName: "SerienNr.",
    field: "serienNr",
    minWidth: 100,
  },
  {
    headerName: "Name",
    field: "rnsName",
    minWidth: 80,
  },
  {
    headerName: "Ort",
    field: "rnsOrt",
    minWidth: 70,
  },
  {
    headerName: "Markt GLN",
    field: "rnsGln",
    minWidth: 110,
  },
  {
    headerName: "Region",
    field: "region",
    minWidth: 95,
  },
  {
    headerName: "RN",
    field: "rnName",
    minWidth: 60,
  },
  {
    headerName: "RN-GLN",
    field: "rnGln",
    minWidth: 100,
  },
  {
    headerName: "Hersteller",
    field: "herstellerKuerzel",
    minWidth: 110,
  },
  {
    headerName: "Heute",
    field: "day1FehlerNrProtokollAtMm",
    tooltipField: "day1FehlerTextProtokollAtMm",
    minWidth: 90,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day2FehlerNrProtokollAtMm",
    tooltipField: "day2FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day3FehlerNrProtokollAtMm",
    tooltipField: "day3FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day4FehlerNrProtokollAtMm",
    tooltipField: "day4FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day5FehlerNrProtokollAtMm",
    tooltipField: "day5FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day6FehlerNrProtokollAtMm",
    tooltipField: "day6FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day7FehlerNrProtokollAtMm",
    tooltipField: "day7FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day8FehlerNrProtokollAtMm",
    tooltipField: "day8FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day9FehlerNrProtokollAtMm",
    tooltipField: "day9FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
  {
    headerName: getHeaderDate(),
    field: "day10FehlerNrProtokollAtMm",
    tooltipField: "day10FehlerTextProtokollAtMm",
    minWidth: 90,
    maxWidth: 110,
    cellRenderer: CellValueRenderer,
  },
];

function getHeaderDate(): string {
  date.setDate(date.getDate() - 1);
  return DateUtils.formatDateToAPIDateString(date, "dd.MM.yyyy") ?? "";
}

export default NotZuOkColumnDefs;
