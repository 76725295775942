import { TableRowProps } from "../../TableContainer/TableRow";

export const DsmdTableHeader: Array<TableRowProps> = [
  {
    row: [
      { value: "Status DSMD", props: { align: "left" } },
      { value: "zuletzt OK am", props: { align: "left" } },
    ],
  },
];
