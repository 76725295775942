import { Stack } from "@mui/material";
import {
  AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID,
  AUTOMATENBLATT_STANDORT_ENUM_ID,
  useEnumerator,
} from "App/hook/use-enumerator";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UICheckbox from "global/components/UI/UICheckbox/UICheckbox";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  EpcomReducerFunctionOptional,
  EpcomReducerOptional,
} from "global/interface/EpcomReducer";
import DateUtils from "global/util/DateUtils";
import { DataControl } from "global/util/enum/DataControl";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { useEffect, useReducer } from "react";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { generateDefaultAutomatenblattInformation } from "../../../generator/defaultAutomatenblattInformation";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import "../panels.scss";

interface DetailsPanelProps extends PanelBaseProps {
  automatKey: string;
}

const DetailsLeftItem = (props: DetailsPanelProps) => {
  const [automatData] = useAutomatData(props.automatKey);

  const stateChangeAndReset: EpcomReducerFunctionOptional<
    AutomatenblattInformation
  > = (state, action) => {
    if (action.type === DataControl.CHANGE && action.valueToChange) {
      return { ...state, ...action.valueToChange };
    } else {
      return automatData.automatenblattInformation;
    }
  };

  const [automatenblattInformation, dispatchAutomatenblattInformation] =
    useReducer<EpcomReducerOptional<AutomatenblattInformation>>(
      stateChangeAndReset,
      automatData.automatenblattInformation ??
        generateDefaultAutomatenblattInformation()
    );

  const changeAutomatenblattInformation = (
    value: Optional<AutomatenblattInformation>
  ) => {
    dispatchAutomatenblattInformation({
      type: DataControl.CHANGE,
      valueToChange: value,
    });
  };

  const updateValues = props.updateValues;

  const standortEnumerator = useEnumerator(AUTOMATENBLATT_STANDORT_ENUM_ID);
  const sortierKonfigEnumerator = useEnumerator(
    AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID
  );

  // Initialising at render and reset
  useEffect(() => {
    dispatchAutomatenblattInformation({ type: DataControl.RESET });
  }, [automatData, props.resetValues]);

  return (
    <Stack spacing={0.5}>
      <SelectBox
        label="Standort:"
        enumerator={standortEnumerator}
        selection={standortEnumerator.itemForValue(
          automatenblattInformation.standort
        )}
        id="standort"
        getSelectedValue={(value) => {
          updateValues.current.standort = value?.id.toString();
        }}
        reset={props.resetValues}
      />
      <TextValue
        label="Hinweis zum Standort:"
        value={automatenblattInformation.standortHinweis ?? ""}
        id="standort-hinweis"
        onChange={(event) => {
          changeAutomatenblattInformation({
            standortHinweis: event.target.value,
          });
          updateValues.current.standortHinweis = event.target.value;
        }}
      />
      <TextValue
        label="Art der Zählstelle:"
        value={automatenblattInformation.zaehlstelle}
        id="zaehlstelle"
        readOnly
      />
      <TextValue
        label="Nutzungsart:"
        value={automatenblattInformation.nutzungsart}
        id="nutzungsart"
        readOnly
      />
      <UICheckbox
        label="Sammlung auf Tisch:"
        value={automatenblattInformation.sammlungAufTisch}
        id="sammlung-auf-tisch"
        onChange={(event) => {
          changeAutomatenblattInformation({
            sammlungAufTisch: event.target.checked,
          });
          updateValues.current.sammlungAufTisch = event.target.checked;
        }}
      />
      <SelectBox
        label="Sortier Konfiguration:"
        enumerator={sortierKonfigEnumerator}
        selection={sortierKonfigEnumerator.itemForId(
          automatenblattInformation.sortierKonf
        )}
        id="sortier-konfiguration"
        getSelectedValue={(value) => {
          updateValues.current.sortierKonf = value?.id.toString();
        }}
        reset={props.resetValues}
      />
      <TextValue
        label="Bulk:"
        value={automatenblattInformation.modellMerkmale.bulkTypeBeschreibung}
        id="modell-merkmal-bulk-beschreibung"
        readOnly
      />
      <TextValue
        label="Erkennung:"
        value={
          automatenblattInformation.modellMerkmale
            .erkennungTechnikTypeBeschreibung
        }
        id="modell-merkmal-erkennung-technik-beschreibung"
        readOnly
      />
      <TextValue
        label="Komp.im Automatenkopf:"
        value={
          automatenblattInformation.modellMerkmale
            .kompaktorImAutomatkopfTypeBeschreibung
        }
        id="modell-merkmal-kompaktor-im-automatkopf-beschreibung"
        readOnly
      />
      <TextValue
        label="Kistenannahme:"
        value={
          automatenblattInformation.modellMerkmale.kistenannahmeTypeBeschreibung
        }
        id="modell-merkmal-kompaktor-im-automatkopf-beschreibung"
        readOnly
      />
      <TextValue
        label="Sammlung auf Tisch:"
        value={
          automatenblattInformation.modellMerkmale
            .sammlungAufTischTypeBeschreibung
        }
        id="modell-merkmal-sammlung-auf-tisch-beschreibung"
        readOnly
      />
      <TextValue
        label="Mehrweg:"
        value={automatenblattInformation.modellMerkmale.mehrwegTypeBeschreibung}
        id="modell-merkmal-mehrweg-beschreibung"
        readOnly
      />
      <TextValue
        label="Einwurf mehrere Gebinde gleichzeitig:"
        value={
          automatenblattInformation.modellMerkmale
            .throwInSeveralTypeBeschreibung
        }
        id="modell-merkmal-throw-in-several-beschreibung"
        readOnly
      />
      <DatePickerElement
        label="Ende Garantie:"
        defaultValue={automatenblattInformation.garantieEnde}
        getSelectedValue={(value) => {
          changeAutomatenblattInformation({ garantieEnde: value ?? undefined });
          props.updateValues.current.garantieEnde =
            DateUtils.formatDateToAPIDateString(value);
        }}
        enablePast
        id="ende-garantie"
        resetToDefault={props.resetValues}
      />
    </Stack>
  );
};

export default DetailsLeftItem;
