import { TableRowProps } from "../../TableContainer/TableRow";

export const VerfügbarkeitsanalyseHeader: Array<TableRowProps> = [
  {
    row: [
      { value: "Ø", props: { align: "center" } },
      { value: "Verf.", props: { align: "center" } },
      { value: "Tend.", props: { align: "center" } },
      { value: "Ausl.", props: { align: "center" } },
      { value: "Rank.", props: { align: "center" } },
    ],
  },
];
