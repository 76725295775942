import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID,
  useEnumerator
} from "App/hook/use-enumerator";
import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import useModalController from "global/hook/modal/use-modals";
import { useState } from "react";
import RnsSearchResult, {
  Gln
} from "service/data-service/rns-controller/interface/RnsSearchResult";
import RnsService from "service/data-service/rns-controller/Rns.service";
import { KaufanfragenATHCreateDialogContentProps } from "../interface/Kaufanfragen";

import "../kaufanfragen.scss";

const KaufanfragenATHCreateDialogContent: React.FC<
  KaufanfragenATHCreateDialogContentProps
> = (props: KaufanfragenATHCreateDialogContentProps) => {
  const modelEnumerator = useEnumerator(AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID);

  const modals = useModalController();
  const emptyRnsRNRecord = { edekaOrganisation: {} } as Gln;

  const [glnValue, setGlnValue] = useState<string>("");
  const [modell, setModell] = useState<EnumeratorItem | null>(null);
  const [canSelectModel, setCanSelectModell] = useState(false);
  const [rns, setRns] = useState<Gln>(emptyRnsRNRecord);

  const onTextChangeHandler = (value: string) => {
    setGlnValue(value);
  };

  const deleteRnsFields = (error?: string) => {
    modellChangeHandler(null);
    setCanSelectModell(false);
    setRns(emptyRnsRNRecord);
  };

  const modellChangeHandler = (value: EnumeratorItem | undefined | null) => {
    setModell(value ?? null);
    props.dataChangeListener({
      rns: rns,
      modell: value ?? undefined,
    });
  };

  const searchHandler = () => {
    deleteRnsFields();
    if (glnValue.length !== 13) {
      modals.showMessageDialog(
        "Die angegebene GLN muss 13 Ziffern lang sein."
      );
      return
    }
    modals.showLoadingDialog("Rücknehmer wird gesucht...");
    RnsService.searchRNS(
      { gln: glnValue, callSource: "KAUFANFRAGEN" },
      (result: RnsSearchResult) => {
        modals.closeModal();
        if (result.rows.length === 1) {
          setRns(result.rows[0]);
          setCanSelectModell(!result.rows[0].sapGueltigBisInvalid);
          if (result.rows[0].sapGueltigBisInvalid) {
            modals.showMessageDialog("Die GLN der RNS ist weniger als 14 Tage gültig, daher ist es nicht möglich eine Kaufanfrage zu dieser GLN zu stellen. Wählen Sie bitte eine neue GLN oder wenden Sie sich an die zuständige GH")
          }
        }
      },
      (err: Error) => {
        modals.closeModal();
      }
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box className="rnsTextBox">
          <TextValue
            label="GLN der RNS:"
            value={glnValue}
            id="gln-der-rns-text"
            onChange={(event) => onTextChangeHandler(event.target.value)}
            TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
            TextFieldProps={{ className: "kaufanfragenDialogInput" }}
          />
          <Button variant="contained" onClick={searchHandler}>
            Suchen
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h3"} className="dialogHeaderLabelStyle">
          Rücknehmerstelle (RNS)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextValue
          label="Name:"
          value={rns.name ?? ""}
          id="name"
          readOnly
          TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          InputProps={{ className: "dialogTextStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          label="Straße:"
          value={rns.strasse ?? ""}
          id="strasse"
          readOnly
          TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          InputProps={{ className: " dialogTextStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          label="PLZ:"
          value={rns.plz ?? ""}
          id="plz"
          readOnly
          TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          InputProps={{ className: " dialogTextStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          label="Ort:"
          value={rns.ort ?? ""}
          id="ort"
          readOnly
          TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          InputProps={{ className: " dialogTextStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h3"} className="dialogHeaderLabelStyle">
          Rücknehmer (RN)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextValue
          label="GLN:"
          value={rns.edekaOrganisation.gln ?? ""}
          id="name"
          readOnly
          TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          InputProps={{ className: " dialogTextStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          label="Name:"
          value={rns.edekaOrganisation.name ?? ""}
          id="name"
          readOnly
          multiline
          TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          InputProps={{ className: " dialogTextStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h3"} className="dialogHeaderLabelStyle">
          Modellauswahl
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectBox
          id="automatModell"
          label="Modell:"
          enableClearable
          enumerator={modelEnumerator}
          selection={modell ?? undefined}
          getSelectedValue={(value) => modellChangeHandler(value)}
          TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
          AutocompleteProps={{ disabled: !canSelectModel }}
        />
      </Grid>
    </Grid>
  );
};

export default KaufanfragenATHCreateDialogContent;
