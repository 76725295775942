import { Card, CardHeader, List } from "@mui/material";
import { HREF_AUTOMATENKAUFANFRAGEN } from "global/util/routes";
import { ColoredListItemTypography } from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";

interface props {
  countOfPurchaseRequest: number;
  purchaseRequestVisible: boolean;
}

const Kaufanfragen: React.FC<props> = (props: props) => {
  // Variablen und Hooks
  const [countOfPurchaseRequest, setCountOfPurchaseRequest] = useState(Number);
  const [purchaseRequestVisible, setPurchaseRequestVisible] = useState(Boolean);

  let navigate = useNavigate();

  useEffect(() => {
    setCountOfPurchaseRequest(props.countOfPurchaseRequest);
    setPurchaseRequestVisible(
      props.purchaseRequestVisible !== undefined
        ? props.purchaseRequestVisible
        : false
    );
  }, [props.countOfPurchaseRequest, props.purchaseRequestVisible]);

  // React-Tree
  return (
    <Card hidden={!purchaseRequestVisible}>
      <CardHeader title="Kaufanfragen" />
      <List disablePadding>
        <CockpitListItemButton
          divider
          aria-label="Zu Bearbeiten"
          onClick={() => navigate(HREF_AUTOMATENKAUFANFRAGEN)}
        >
          <CockpitListItemText primary="Zu bearbeiten: " />
          <ColoredListItemTypography
            colorChangeBool={countOfPurchaseRequest <= 0}
            text={`${countOfPurchaseRequest}`}
            id="countOfPurchaseRequest"
          />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Kaufanfragen;
