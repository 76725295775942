import { Box, Typography } from "@mui/material";
import { hasChildren } from "global/util/utils";
import "./epcomtreeitem.scss";
import { SideMenuItem } from "./SideMenuItem";
import StyledEpcomTreeItemRoot from "./StyledEpcomTreeItemRoot";

interface props {
  item: SideMenuItem;
  onItemClick: EpcomTreeItemClickFunction;
  itemFilter?: (item: SideMenuItem) => boolean;
}

export type EpcomTreeItemClickFunction = (item: SideMenuItem) => void;

const EpcomTreeItem = (props: props) => {
  const { item, onItemClick: itemClickHandler, itemFilter, ...other } = props;
  const { id: key, text: labelText, icon: LabelIcon } = item;

  const defItemFilter = (item: SideMenuItem) => true
  const childItemFilter = itemFilter ?? defItemFilter

  return (
    <StyledEpcomTreeItemRoot
      nodeId={key}
      onClick={() => {
        itemClickHandler(item);
      }}
      label={
        <Box className="labelContainer">
          <Box
            component={LabelIcon}
            className="labelIconContainer"
            color="inherit"
          />
          <Typography className="labelText" variant="body1">
            {labelText}
          </Typography>
        </Box>
      }
      {...other}
    >
      {hasChildren(props.item)
        ? props.item.children!
          .filter(childItemFilter)
          .map((item) => (
            <EpcomTreeItem
              key={item.id}
              item={item}
              onItemClick={itemClickHandler}
            />
          ))
        : null}
    </StyledEpcomTreeItemRoot>
  );
};

export default EpcomTreeItem;
