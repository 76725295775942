import useSnackbarGenerator from "App/hook/use-snackbars";
import { createContext, ReactNode, useEffect, useState } from "react";
import AccessService from "service/data-service/access-controller/Access.service";
import Access, {
  generateDefaultAccess,
} from "service/data-service/access-controller/interface/Access";

interface IAccessContext {
  getAccessContainer: () => Access;
}

export const AccessContext = createContext<IAccessContext>({
  getAccessContainer: () => generateDefaultAccess(),
});

const AccessProvider = (props: { children: ReactNode | ReactNode[] }) => {
  const [accessContainer, setAccessContainer] = useState<Access>(
    generateDefaultAccess()
  );
  const { showError } = useSnackbarGenerator();

  useEffect(() => {
    AccessService.getAccess(setAccessContainer, (error: any) =>
      showError("Error in requesting Access:" + error)
    );
  }, []);

  return (
    <AccessContext.Provider
      value={{
        getAccessContainer: () => {
          return accessContainer;
        },
      }}
    >
      {props.children}
    </AccessContext.Provider>
  );
};

export default AccessProvider;
