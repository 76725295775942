import { createTheme } from "@mui/material";

const AccordionTheme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 35,
          maxHeight: 35,
        },
      },
    },

    MuiChip: { styleOverrides: { root: { height: 18 } } },

    MuiInputLabel: {
      styleOverrides: { root: { fontSize: 12.5 } },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: 35,
          fontSize: 12.5,
        },
      },
    },
  },
});

export default AccordionTheme;
