import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";

export function rnsBonNumberEnteredIsValid(
  updateRequest: AutomatenUpdateRequest
) {
  if (updateRequest.rnsBonNummer && !updateRequest.rnsBonNummerGueltigVon) {
    return false;
  } else if (
    !updateRequest.rnsBonNummer &&
    updateRequest.rnsBonNummerGueltigVon
  ) {
    return false;
  }
  return true;
}
