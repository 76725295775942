import { SnackbarGenerator } from "App/hook/use-snackbars";
import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import ClearingfehlerDTO from "./dto/ClearingfehlerDTO";
import { Clearingfehler } from "./interface/Clearingfehler";

const ClearingfehlerService = {
  getClearingfehlerForAutomat: (
    automatKey: String,
    callbackOnSuccess: Function,
    callbackOnFail?: Function,
  ) => {
    axiosCalls
      .sendGet(Config.dataServiceUrl + "clearingfehler/" + automatKey)
      .then((response) => {
        callbackOnSuccess(
          ClearingfehlerDTO.convertResponseToClearingfehlerResultDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) { callbackOnFail(error) }
        else {
          console.log(error);
          // @TODO fire error message
        }
      });
  },
  updateClearingfehlerForAutomat: (
    clearingfehler: Clearingfehler,
    callbackOnSuccess: Function,
    callbackOnFail?: Function,
    snackbarGenerator?: SnackbarGenerator
  ) => {
    axiosCalls
      .sendJsonDataPost(
        Config.dataServiceUrl + "clearingfehler/updateErledigt",
        clearingfehler
      )
      .then((response) => {
        callbackOnSuccess(
          ClearingfehlerDTO.convertResponseToClearingfehlerDTO(response)
        );
        if (snackbarGenerator) {
          snackbarGenerator.showInfo(
            'Der erledigt-Status wurde erfolgreich aktualisiert!',
            { persist: false })
        }
      })
      .catch((error) => {
        if (callbackOnFail) { callbackOnFail(error) }
        if (snackbarGenerator) {
          snackbarGenerator.showError(
            'Bei der Aktualisierung des erledigt-Status ist ein Fehler aufgetreten',
            {
              error: error
            })
        } else {
          console.log(error);
        }
      });
  },
};

export default ClearingfehlerService;
