import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import ErrorTypeDTO from "./interface/ErrorType";
import ErrorTypeMapper from "./mapping/ErrorTypeMapper";

const ErrorTypeService = {
  getErrorTypes: (
    callbackOnSuccess: (errorTypes: Array<ErrorTypeDTO>) => void
  ) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}errortyp/geterrortypes`)
      .then((response) => {
        callbackOnSuccess(ErrorTypeMapper.mapResponseToErrorTypeDTO(response));
      });
  },
};

export default ErrorTypeService;
