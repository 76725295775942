import { Components, Theme } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }
}

const ButtonThemeComponents: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "20px",
        boxShadow:
          "0px 2px 3px 0px rgba(30, 25, 31, 0.2), 0px 4px 6px 0px rgba(30, 25, 31, 0.2)",
        textTransform: "uppercase",
      },
    },
    variants: [
      //small
      {
        props: {
          variant: "secondary",
          size: "small",
        },
        style: {
          padding: "3px 11px",
          backgroundColor: "#ffffff",
          color: "#1c65b1",

          "&:hover": {
            backgroundColor: "#ffffff",
            color: "#115296",
          },
          "&:active": {
            backgroundColor: "#ffffff",
            color: "#08284a",
          },
          "&:disabled": {
            backgroundColor: "#a6d1ff",
            color: "#ffffff",
          },
        },
      },

      //medium
      {
        props: {
          variant: "secondary",
          size: "medium",
        },
        style: {
          backgroundColor: "#ffffff",
          color: "#1c65b1",

          "&:hover": {
            backgroundColor: "#ffffff",
            color: "#115296",
          },
          "&:active": {
            backgroundColor: "#ffffff",
            color: "#08284a",
          },
          "&:disabled": {
            backgroundColor: "#a6d1ff",
            color: "#ffffff",
          },
        },
      },
    ],
  },

  MuiIconButton: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
        color: "#1c65b1",

        "&:hover": {
          backgroundColor: "#ffffff",
          color: "#115296",
        },
        "&:active": {
          backgroundColor: "#ffffff",
          color: "#08284a",
        },
        "&:disabled": {
          backgroundColor: "#a6d1ff",
          color: "#ffffff",
        },
      },
    },
  },
};

export default ButtonThemeComponents;
