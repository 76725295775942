import { Grid, Typography } from "@mui/material";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import StatusIconElement, { StatusIconType } from "global/components/UI/StatusIcon/StatusIcon";
import TextValue from "global/components/UI/TextValue/TextValue";
import { returnUndefinedIfOnlyWhitespace } from "global/util/utils";
import { useEffect, useState } from "react";
import { KaufanfragenATHAutomatInsertDialogData, KaufanfragenATHInsertAutomatDialogContentProps } from "../interface/Kaufanfragen";

import "../kaufanfragen.scss";


const KaufanfragenATHAutomatInsertDialogContent: React.FC<KaufanfragenATHInsertAutomatDialogContentProps> = (props: KaufanfragenATHInsertAutomatDialogContentProps) => {

    const [hasMissingSerienNummer, setHasMissingSerienNummer] = useState(true)
    const [anlegenDialogData, setAnlegenDialogData] = useState<KaufanfragenATHAutomatInsertDialogData>({ aktivAb: new Date() })

    const onDataChangeHandler = (value: KaufanfragenATHAutomatInsertDialogData) => {
        setAnlegenDialogData((oldState: KaufanfragenATHAutomatInsertDialogData) => {
            return {
                ...oldState,
                ...value,
            }
        })
    }

    useEffect(() => {
        props.dataChangeListener(anlegenDialogData)
        setHasMissingSerienNummer(!returnUndefinedIfOnlyWhitespace(anlegenDialogData.seriennummer))
    }, [anlegenDialogData])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextValue
                    label="Seriennummer:"
                    value={anlegenDialogData.seriennummer ?? ''}
                    id="seriennummer"
                    onChange={(event) => onDataChangeHandler({ seriennummer: event.target.value } as KaufanfragenATHAutomatInsertDialogData)}
                    TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
                    TextFieldProps={{ className: "dialogTextStyle kaufanfragenDialogInput" }}
                />
            </Grid>
            {hasMissingSerienNummer &&
                <Grid item xs={1}>
                    <StatusIconElement type={StatusIconType.RN} />
                </Grid>}
            {hasMissingSerienNummer &&
                <Grid item xs={11}>
                    <Typography className="dialogTextErrorStyle">Seriennummer muss gefüllt sein!</Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <TextValue
                    label="Schlüssel-ID:"
                    value={anlegenDialogData.schlusselId ?? ''}
                    id="schlussel-id"
                    onChange={(event) => onDataChangeHandler({ schlusselId: event.target.value } as KaufanfragenATHAutomatInsertDialogData)}
                    TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
                    TextFieldProps={{ className: "dialogTextStyle kaufanfragenDialogInput" }}
                />
            </Grid>
            <Grid item xs={12}>
                <DatePickerElement
                    label="Aktiv ab:"
                    defaultValue={anlegenDialogData.aktivAb}
                    getSelectedValue={(value) => {
                        onDataChangeHandler({ aktivAb: value } as KaufanfragenATHAutomatInsertDialogData)
                    }}
                    id="aktiv-ab-date"
                    TypographyProps={{ className: "dialogLabelStyle dialogTextStyle" }}
                    TextFieldProps={{ className: "dialogTextStyle kaufanfragenDialogInput" }}
                />
            </Grid>
        </Grid >
    );
}

export default KaufanfragenATHAutomatInsertDialogContent;
