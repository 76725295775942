import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import ErreichbarkeitDTO from "./dto/ErreichbarkeitDTO";

const ErreichbarkeitService = {
  getReport: (handler: ServiceResponseHandler) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}reporting/erreichbarkeitsreport`)
      .then((response) => {
        handler.callbackOnSuccess(
          ErreichbarkeitDTO.convertErreichbarkeitResponse(response)
        );
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error)
      });
  },
};
export default ErreichbarkeitService;
