import { Button, Stack, Typography } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import {
  AUTOMATENBLATT_AUTOMATART_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID,
  useEnumerator,
} from "App/hook/use-enumerator";
import { Enumerator } from "global/components/EnumeratedValues/Enumerator";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import DateUtils from "global/util/DateUtils";
import { useContext, useEffect, useState } from "react";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import "../../automatenblatt.scss";
import AutomatStatusElement from "../AutomatStatus/AutomatStatus";
import DsmdStatusElement from "../DsmdStatus/DsmdStatus";
import ReadOnlyValue from "../ReadOnlyValue/ReadOnlyValue";
import Verfugbarkeitsanalyse from "../Verfugbarkeitsanalyse/Verfugbarkeitsanalyse";
import "./automatSheet.scss";

interface AutomatenblattProps {
  automatKey: string;
  updateValues: React.MutableRefObject<AutomatenUpdateRequest>;
  resetValues: boolean;
  onReset: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: Function;
}

const AutomatSheet: React.FC<AutomatenblattProps> = (
  props: AutomatenblattProps
) => {
  const [automatData] = useAutomatData(props.automatKey);
  const accessContext = useContext(AccessContext);

  const [disabledAction, setDisabledAction] = useState(false);

  const automatStatusZukunft: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID
  );
  const automatArt: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATART_ENUM_ID
  );

  useEffect(() => {
    setDisabledAction(
      !accessContext.getAccessContainer().showAutomatenTabBtnAutomatSpeichern
    );
  }, [accessContext]);

  return (
    <Stack width={340}>
      <Typography variant="h3">Automatenblatt</Typography>
      <ReadOnlyValue
        label="Automat Zustand:"
        value={automatData.automatenblattInformation.automatStatusAktuell}
        id="statusAktuell"
      />
      <ReadOnlyValue
        label="gültig ab:"
        value={
          automatData.automatenblattInformation.automatStatusGueltigVonAktuell
        }
        id="gueltigVonAktuell"
      />
      <ReadOnlyValue
        label="Zustand in Zukunft:"
        value={
          automatStatusZukunft.itemForId(
            automatData.automatenblattInformation.automatStatusInZukunft
          )?.value ?? ""
        }
        id="automatStatusInZukunftReadOnly"
      />
      <ReadOnlyValue
        label="gültig ab:"
        value={
          DateUtils.formatDate(
            automatData.automatenblattInformation
              .automatStatusGueltigVonInZukunft
          ) ?? ""
        }
        id="automatStatusGueltigVonInZukunftReadOnly"
      />
      <ReadOnlyValue
        label="Automaten Art:"
        value={
          automatArt.itemForId(automatData.automatenblattInformation.artKey)
            ?.value ?? ""
        }
        id="artKeyReadOnly"
      />
      <ReadOnlyValue
        label="Hersteller:"
        value={automatData.automatenblattInformation.herstellerName}
        id="herstellerName"
      />
      <ReadOnlyValue
        label="Modell:"
        value={automatData.automatenblattInformation.modell}
        id="modell"
      />
      <ReadOnlyValue
        label="ATH-GLN:"
        value={automatData.automatenblattInformation.athGln}
        id="athGln"
      />
      <ReadOnlyValue
        label="Seriennr.:"
        value={automatData.automatenblattInformation.seriennummer}
        id="seriennummer"
      />
      <ReadOnlyValue
        label="RNS-GLN:"
        value={automatData.automatenblattInformation.rns.gln}
        id="rnsGln"
      />
      <ReadOnlyValue
        label="SSID-Ref.Datum:"
        value={automatData.automatenblattInformation.ssidGueltigVon}
        id="ssidGueltigVon"
      />
      <ReadOnlyValue
        label="Aktive SSID:"
        value={automatData.automatenblattInformation.ssidAktiv}
        id="ssidAktivReadOnly"
      />
      <ReadOnlyValue
        label="in Betrieb seit:"
        value={automatData.automatenblattInformation.inbetriebnahme}
        id="inbetriebnahme"
      />
      <ReadOnlyValue
        label="ruht von:"
        value={
          DateUtils.formatDate(automatData.automatenblattInformation.ruhtVon) ??
          ""
        }
        id="ruhtVonReadOnly"
      />
      <ReadOnlyValue
        label="ruht bis:"
        value={
          DateUtils.formatDate(automatData.automatenblattInformation.ruhtBis) ??
          ""
        }
        id="ruhtBisReadOnly"
      />
      <ReadOnlyValue
        label="letzter erf. Zugriff:"
        value={automatData.automatenblattInformation.letzterErfolgreicherAufruf}
        id="letzterErfolgreicherAufruf"
      />

      <AutomatStatusElement
        automatStatusList={automatData.automatStatus ?? { row: [] }}
      />

      <DsmdStatusElement dsmdStatus={automatData.dsmdStatus} />

      <Verfugbarkeitsanalyse automatData={automatData} />

      <Stack className="saveStack">
        <Button
          id="resetButton"
          size="small"
          variant="secondary"
          disabled={disabledAction}
          onClick={() => {
            props.onReset(props.resetValues ? false : true);
          }}
        >
          Verwerfen
        </Button>
        <Button
          variant="contained"
          size="small"
          id="saveButton"
          disabled={disabledAction}
          onClick={() => props.onSave()}
        >
          Automat Speichern
        </Button>
      </Stack>
    </Stack>
  );
};

export default AutomatSheet;
