import { SnackbarGenerator } from "App/hook/use-snackbars";
import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import LogfilefehlerDTO from "./dto/LogfilefehlerDTO";
import Logfilefehler from "./interface/Logfilefehler";

const basePath = "logfilefehler/";

const LogfilefehlerService = {
  loadByAutomatSerienNr: (
    automatSerienNr: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(
        `${Config.logfileServiceUrl}${basePath}automat/${automatSerienNr}`
      )
      .then((response) => {
        callbackOnSuccess(LogfilefehlerDTO.convertLogErrorResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  update: (
    logfilefehler: Logfilefehler,
    callbackOnSuccess: Function,
    callbackOnFail?: Function,
    snackbarGenerator?: SnackbarGenerator
  ) => {
    axiosCalls
      .sendJsonDataPost(
        `${Config.logfileServiceUrl}${basePath}updateErledigt`,
        JSON.stringify(logfilefehler)
      )
      .then((response) => {
        callbackOnSuccess(LogfilefehlerDTO.convertUpdateResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) { callbackOnFail(error) }
        if (snackbarGenerator) {
          snackbarGenerator.showError(
            'Bei der Aktualisierung des erledigt-Status ist ein Fehler aufgetreten',
            {
              error: error
            })
        } else {
          console.log(error);
        }
      });
  },
};
export default LogfilefehlerService;
