import { CheckCircle, CrisisAlert, WarningAmber } from "@mui/icons-material";
import { Typography } from "@mui/material";

import "../../ui.scss";

export enum StatusIconType {
  OK = "success.main",
  RN = "warning.main",
  NOK = "error.main",
  NRN = "monochrome.main",
}

interface StatusIconProps {
  type: StatusIconType;
  text?: string;
}

const StatusIconElement: React.FC<StatusIconProps> = (
  props: StatusIconProps
) => {
  return (
    <>
      {props.type === StatusIconType.OK && (
        <CheckCircle
          className="uiElement"
          fontSize="small"
          sx={{ color: props.type }}
        />
      )}
      {props.type === StatusIconType.NOK && (
        <CrisisAlert
          className="uiElement"
          fontSize="small"
          sx={{ color: props.type }}
        />
      )}
      {props.type === StatusIconType.RN && (
        <WarningAmber
          className="uiElement"
          fontSize="small"
          sx={{ color: props.type }}
        />
      )}
      {props.text && (
        <Typography
          className="uiElement statusIcon__Text"
          sx={{ color: props.type }}
        >
          {props.text}
        </Typography>
      )}
    </>
  );
};

export function convertStatusValue(
  automatStatusValue: string | null,
  includeStatusText = true
): React.ReactNode {
  return convertStatusElement({ value: automatStatusValue } as StatusElement, includeStatusText);
}

export interface StatusElement {
  value: string | null;
  error: string | null;
}

export function convertStatusElement(
  automatStatusElement: StatusElement | null,
  includeStatusText = true,
): React.ReactNode {
  if (!automatStatusElement || !automatStatusElement.value) {
    return null;
  }
  const statusValue: string = automatStatusElement.value
  let icon: StatusIconType =
    StatusIconType[statusValue as keyof typeof StatusIconType] ?? StatusIconType.NOK;
  if (icon === StatusIconType.NRN) {
    return null;
  }
  const statusError = automatStatusElement.error ?? statusValue
  const statusText = icon === StatusIconType.NOK ? statusError : statusValue
  return (
    <StatusIconElement
      type={icon}
      text={includeStatusText ? statusText : undefined}
    />
  );
}

export default StatusIconElement;
