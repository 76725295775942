import NoteRequestDTO from "service/data-service/notizen-controller/interface/NoteRequest";

export function generateDefaultNoteRequest(): NoteRequestDTO {
  return {
    automatKey: "",
    notizAktion: "",
    status: "",
    type: "",
    filename: "",
    fileContent: "",
    notizKopfId: undefined,
    notizKopfKey: "",
    notizKopfText: "",
    notizReadRoles: null,
    notizZeileKey: "",
    notizZeileText: "",
    wiedervorlage: "",
  };
}
