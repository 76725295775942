import {
  createTheme,
  responsiveFontSizes,
  TableRow,
  Theme,
} from "@mui/material";
import { TableRowProps as TableRowPropsInterface } from "@mui/material/TableRow";
import Cell, { TableCellProps } from "./TableCell";

export interface TableRowProps {
  row: Array<TableCellProps>;
  props?: TableRowPropsInterface;
  isHead?: boolean;
}

const Row: React.FC<TableRowProps> = (element: TableRowProps) => {
  const theme: Theme = responsiveFontSizes(createTheme({}));
  const defaultRowStyle = {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  };
  return (
    <TableRow sx={defaultRowStyle} {...element.props}>
      {element.row.map((row: TableCellProps, index: number) => (
        <Cell
          key={index}
          value={row.value}
          props={row.props}
          isHead={element.isHead}
        />
      ))}
    </TableRow>
  );
};

export default Row;
