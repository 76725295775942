import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import { MutableRefObject } from "react";
import NewAutomatDTO from "./dto/NewAutomatDTO";
import AutomatenblattInformation from "./interface/AutomatenblattInformation";
import AutomatenUpdateRequest from "./interface/AutomatenUpdateRequest";
import NewAutomat from "./interface/NewAutomat";
import AxiosResponseToAutomatenblattInformationDTOMapper from "./mapper/AxiosResponseToAutomatenblattInformationDTOMapper";

const AutomatenblattInformationService = {
  retrieveAutomatenInformation: (
    automatkey: string,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    axiosCalls
      .sendGet(Config.dataServiceUrl + "automate/" + automatkey)
      .then((response) => {
        const ResponseDTO: AutomatenblattInformation =
          AxiosResponseToAutomatenblattInformationDTOMapper.map(response);
        callbackOnSuccess(ResponseDTO);
      })
      .catch((error) => {
        callbackOnFail(error);
      });
  },
  update: (
    automatUpdate: MutableRefObject<AutomatenUpdateRequest>,
    callbackOnSuccess?: () => void
  ) => {
    axiosCalls
      .sendJsonDataPost(
        Config.dataServiceUrl + "automate/update",
        automatUpdate.current
      )
      .then((response) => {
        // @TODO fire succes message
        if (callbackOnSuccess) callbackOnSuccess();
      })
      .catch((error) => {
        console.log(error);
        // @TODO fire error message
      });
  },
  create: (
    automat: NewAutomat,
    callbackOnSuccess?: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendJsonDataPost(
        Config.dataServiceUrl + "automate/createautomat",
        automat
      )
      .then((response) => {
        if (callbackOnSuccess) callbackOnSuccess(NewAutomatDTO.convertResponseToNewAutomatDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
        // @TODO fire error message
      });
  },
};

export default AutomatenblattInformationService;
