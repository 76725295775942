import { AxiosResponse } from "axios";
import { Erreichbarkeit } from "../interface/Erreichbarkeit";

const ErreichbarkeitDTO = {
  convertErreichbarkeitResponse: (
    response: AxiosResponse
  ): Array<Erreichbarkeit> => {
    if (response.data) {
      return response.data.Row;
    }
    return [];
  },
};
export default ErreichbarkeitDTO;
