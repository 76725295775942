import { Stack, Tabs, useTheme } from "@mui/material";
import { UPDATE_VIEW_PANEL_INFO } from "global/hook/datastore/automat/view-info-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";

import LinkTab from "page/Automatensuche/components/LinkTab/LinkTab";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import AbrufPanel from "../panel/Abruf/AbrufPanel";
import ArtikelsuchePanel from "../panel/ArtikelSuche/ArtikelsuchePanel";
import ClearingfehlerPanel from "../panel/Clearingfehler/ClearingfehlerPanel";
import DetailsPanel from "../panel/Details/DetailsPanelContainer";
import DPGStammdatenPanel from "../panel/DPGStammdaten/DPGStammdatenPanel";
import HistoriePanel from "../panel/Historie/HistoriePanel";
import LogfilePanel from "../panel/Logfile/LogfilePanel";
import LogfilefehlerPanel from "../panel/Logfilefehler/LogfilefehlerPanel";
import NotizenPanel from "../panel/Notizen/NotizenPanel";
import RNSPanel from "../panel/RNS/RNSPanel";
import StatusPanel from "../panel/Status/StatusPanel";
import TkPartnerController from "../panel/TKPartner/script/TKPartnerController";
import TKPartnerPanel from "../panel/TKPartner/TKPartnerPanel";
import VertragePanel from "../panel/Vertrag/VertragePanel";

import {
  ABRUF_PATH,
  ARTIKELSUCHE_PATH,
  CLEARINGFEHLER_PATH,
  DETAILS_PATH,
  DPG_STAMMDATEN_PATH,
  HISTORIE_PATH,
  LOGFILEFEHLER_PATH,
  LOGFILE_PATH,
  NOTIZEN_PATH,
  RNS_PATH,
  STATUS_PATH,
  TK_PARTNER_PATH,
  VERTRAGE_PATH
} from "./routes";
import "./tabs.scss";

export interface PanelBaseProps {
  updateValues: React.MutableRefObject<AutomatenUpdateRequest>;
  resetValues: boolean;
}

export const AutomatPanelRoutes: Array<string> = [
  RNS_PATH,
  DETAILS_PATH,
  STATUS_PATH,
  TK_PARTNER_PATH,
  ABRUF_PATH,
  DPG_STAMMDATEN_PATH,
  CLEARINGFEHLER_PATH,
  NOTIZEN_PATH,
  VERTRAGE_PATH,
  ARTIKELSUCHE_PATH,
  LOGFILE_PATH,
  LOGFILEFEHLER_PATH,
  HISTORIE_PATH,
];

interface AutomatDetailPanelContainerProps extends PanelBaseProps {
  automatKey: string;
}

const AutomatDetailPanelContainer = (
  props: AutomatDetailPanelContainerProps
) => {
  const { updateValues, resetValues } = props;
  const [automatData, dispatch] = useAutomatData(props.automatKey);
  const [selectedTab, setSelectedTab] = useState<number>(
    automatData.viewInfo.infoPanelIndex
  );
  const [markTkPartnerWithColor, setMarkTkPartnerWithColor] = useState(false);
  const theme = useTheme();

  const tabChangeHandler = (event: SyntheticEvent, value: number) => {
    setSelectedTab(value);
    dispatch(UPDATE_VIEW_PANEL_INFO, {
      viewPanelIndex: value,
    } as AutomatActionPayload);
  };

  useEffect(() => {
    setSelectedTab(automatData.viewInfo.infoPanelIndex);
  }, [automatData.viewInfo.infoPanelIndex]);

  useEffect(() => {
    if (automatData.automatenblattInformation.seriennummer !== 0) {
      TkPartnerController.isTkPartnerIncomplete(
        automatData.automatenblattInformation,
        setMarkTkPartnerWithColor
      );
    }
  }, [automatData]);

  const rnsPanel = (
    <RNSPanel
      automatKey={props.automatKey}
      updateValues={updateValues}
      resetValues={resetValues}
    />
  );
  const detailsPanel = (
    <DetailsPanel
      automatKey={props.automatKey}
      updateValues={updateValues}
      resetValues={resetValues}
    />
  );
  const tkPartnerPanel = (
    <TKPartnerPanel
      automatKey={props.automatKey}
      updateValues={updateValues}
      resetValues={resetValues}
      onOneElementMarked={(marked) => {
        setMarkTkPartnerWithColor(marked);
      }}
    />
  );
  const statusPanel = <StatusPanel automatKey={props.automatKey} />;

  const abrufPanel = <AbrufPanel automatKey={props.automatKey} />;
  const dpgStammdatenPanel = (
    <DPGStammdatenPanel automatKey={props.automatKey} />
  );
  const clearingfehlerPanel = (
    <ClearingfehlerPanel automatKey={props.automatKey} />
  );
  const notizenPanel = <NotizenPanel automatKey={props.automatKey} />;
  const vertragePanel = <VertragePanel automatKey={props.automatKey} />;

  const artikelSuchePanel = <ArtikelsuchePanel automatKey={props.automatKey} />;
  const logfilePanel = <LogfilePanel automatKey={props.automatKey} />;
  const logfehlerPanel = <LogfilefehlerPanel automatKey={props.automatKey} />;
  const historiePanel = <HistoriePanel automatKey={props.automatKey} />;

  const windowViewport = useWindowViewport(436)

  return (
    <Stack>
      <Tabs
        variant="scrollable"
        value={selectedTab}
        onChange={tabChangeHandler}
        scrollButtons="auto"
        className="automatenblatt-tabs"
        sx={{
          backgroundColor: "secondary.main",
          width: windowViewport.width,
        }}
      >
        <LinkTab to={RNS_PATH} label={"RNS"} />
        <LinkTab to={DETAILS_PATH} label={"Details"} />
        <LinkTab to={STATUS_PATH} label={"Status"} />
        <LinkTab
          to={TK_PARTNER_PATH}
          label={
            <p
              style={{
                color: markTkPartnerWithColor
                  ? theme.palette.error.main
                  : undefined,
              }}
            >
              {markTkPartnerWithColor ? "*TK Partner*" : "TK Partner"}
            </p>
          }
        />
        <LinkTab to={ABRUF_PATH} label={"Abruf"} />
        <LinkTab to={DPG_STAMMDATEN_PATH} label={"DPG-Stammdaten"} />
        <LinkTab to={CLEARINGFEHLER_PATH} label={"Clearingfehler"} />
        <LinkTab to={NOTIZEN_PATH} label={"Notizen"} />
        <LinkTab to={VERTRAGE_PATH} label={"Verträge"} />
        <LinkTab to={ARTIKELSUCHE_PATH} label={"Artikelsuche"} />
        <LinkTab to={LOGFILE_PATH} label={"Logfile"} />
        <LinkTab to={LOGFILEFEHLER_PATH} label={"Logfilefehler"} />
        <LinkTab to={HISTORIE_PATH} label={"Historie"} />
      </Tabs>
      <Routes>
        <Route path={RNS_PATH} element={rnsPanel} />
        <Route path={DETAILS_PATH} element={detailsPanel} />
        <Route path={STATUS_PATH} element={statusPanel} />
        <Route path={TK_PARTNER_PATH} element={tkPartnerPanel} />
        <Route path={ABRUF_PATH} element={abrufPanel} />
        <Route path={DPG_STAMMDATEN_PATH} element={dpgStammdatenPanel} />
        <Route path={CLEARINGFEHLER_PATH} element={clearingfehlerPanel} />
        <Route path={NOTIZEN_PATH} element={notizenPanel} />
        <Route path={VERTRAGE_PATH} element={vertragePanel} />
        <Route path={ARTIKELSUCHE_PATH} element={artikelSuchePanel} />
        <Route path={LOGFILE_PATH} element={logfilePanel} />
        <Route path={LOGFILEFEHLER_PATH} element={logfehlerPanel} />
        <Route path={HISTORIE_PATH} element={historiePanel} />
      </Routes>
    </Stack>
  );
};

export default AutomatDetailPanelContainer;
