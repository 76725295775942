import { AxiosResponse } from "axios";
import Logfilefehler from "../interface/Logfilefehler";
const LogfilefehlerDTO = {

  convertLogErrorResponse: (response: AxiosResponse): Array<Logfilefehler> => {
    const records: Array<Logfilefehler> = [];

    if (response.data) {
      response.data.forEach((rec: Logfilefehler) => {
        records.push({ ...rec })
      });
    }
    return records;
  },
  convertUpdateResponse: (response: AxiosResponse): Logfilefehler => {
    if (response.data) {
      return {
        fehlerKey: response.data.fehlerKey,
        automatSerienNr: response.data.automatSerienNr,
        fehlerkategorie: response.data.fehlerkategorie,
        fehlerbeschreibung: response.data.fehlerbeschreibung,
        additionalInfo: response.data.additionalInfo,
        additionalInfoDatum: response.data.additionalInfoDatum,
        created: response.data.created,
        updated: response.data.updated,
        userUpdate: response.data.userUpdate,
        erledigt: response.data.erledigt,
      };
    }
    return {} as Logfilefehler;
  },
};
export default LogfilefehlerDTO;
