import { convertStatusElement } from "global/components/UI/StatusIcon/StatusIcon";
import { AutomatStatusTyps } from "service/data-service/automate-controller/AutomatStatus.service";
import AutomatStatusList, { AutomatStatus } from "service/data-service/automate-controller/interface/AutomatStatus";



const StatusPanelController = {

    transmuteAutomatStatusToGridData(automatStatus: AutomatStatusList): Array<AutomatStatus> {
        let tableElements: Array<AutomatStatus> = [];
        automatStatus.row.forEach((element) => {
            const record = {
                ...element,
                ...{
                    mengenMeldungCellNode: convertStatusElement({ value: element.mengenMeldung, error: element.fehlerNrProtokollAtMm }),
                    artikelstammCellNode: convertStatusElement({ value: element.artikelstamm, error: element.fehlerNrProtokollCdStd }),
                    quittierungCellNode: convertStatusElement({ value: element.quittierung, error: element.fehlerNrProtokollCdMmquit }),
                    logfileCellNode: convertStatusElement({ value: element.logfile, error: element.fehlerNrProtokollAtLog }),
                    aktstatusDPGCellValue: convertStatusElement({ value: element.aktstatusDPG, error: element.fehlerNrProtokollAtDpgbtb }),
                    aktstatusRNCellValue: convertStatusElement({ value: element.aktstatusBTB, error: element.fehlerNrProtokollAtEpgbtb }),
                    emptyEntry: ''
                },
            };
            tableElements.push(record);
        });
        return tableElements;
    },
    automatStatusLogTypeFor(columnId: string): AutomatStatusTyps | null {
        if (columnId === 'mengenMeldungCellNode') {
            return AutomatStatusTyps.AT_MM;
        } else if (columnId === 'artikelstammCellNode') {
            return AutomatStatusTyps.CD_STD;
        } else if (columnId === 'quittierungCellNode') {
            return AutomatStatusTyps.CD_MMQUIT;
        } else if (columnId === 'logfileCellNode') {
            return AutomatStatusTyps.AT_LOG;
        } else if (columnId === 'aktstatusDPGCellValue') {
            return AutomatStatusTyps.AT_DPGBTB;
        } else if (columnId === 'aktstatusRNCellValue') {
            return AutomatStatusTyps.AT_EDKBTB;
        }
        return null;
    },
    statusDialogTitleFor(type: AutomatStatusTyps) {
        switch (type) {
            case AutomatStatusTyps.AT_MM:
                return 'Mengenmeldung';
            case AutomatStatusTyps.CD_STD:
                return 'Artikelstamm';
            case AutomatStatusTyps.CD_MMQUIT:
                return 'Quittierung';
            case AutomatStatusTyps.AT_LOG:
                return 'Logfile';
            case AutomatStatusTyps.AT_DPGBTB:
                return 'DPG-BTB';
            case AutomatStatusTyps.AT_EDKBTB:
                return 'RN-BTB';
        }
        return '';
    }
}

export default StatusPanelController;
