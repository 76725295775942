import { convertStatusValue } from "global/components/UI/StatusIcon/StatusIcon";
import AutomatStatusService from "service/data-service/automate-controller/AutomatStatus.service";
import { FailedAutomats } from "service/data-service/automate-controller/interface/FailedAutomatTagesstatus";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";

const AnsichtfehlerController = {
  loadData(handler: ServiceResponseHandler) {
    AutomatStatusService.getNOKZuOK(
      (result: Array<FailedAutomats>) => {
        handler.callbackOnSuccess(
          AnsichtfehlerController.convertFehlerData(result)
        );
      },
      (error: Error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error);
      }
    );
  },
  convertFehlerData(fehler: Array<FailedAutomats>): Array<FailedAutomats> {
    let failedAutomats: Array<FailedAutomats> = [];
    fehler.forEach((failedAutomat: FailedAutomats) => {
      for (let index = 1; index < 11; index++) {
        let key = `day${index}FehlerNrProtokollAtMm`;
        failedAutomat[key] = convertStatusValue(failedAutomat[key] as string);
      }
      failedAutomats.push(failedAutomat);
    });
    return failedAutomats;
  },
};

export default AnsichtfehlerController;
