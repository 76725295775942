import DepositMachineDataService from "service/data-service/automate-controller/DepositMachineData.service";

export default function findMachineKeyViaSerialNumber(
  automatSerienNr: string,
  callbackWithAutomatKey: (automatKey: string) => void
) {
  DepositMachineDataService.retrieveDepositMachineData(
    {
      serienNr: automatSerienNr,
    },
    (responseData) => {
      callbackWithAutomatKey(responseData[0].automatkey);
    },
    (error: any) => {
      console.log(error);
    }
  );
}
