import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import DateUtils from "global/util/DateUtils";
import fileDownload from "js-file-download";

const FlatFileService = {
  exportToJson: (
    tkArt: string,
    rnsGln: string,
    serienNr: string,
    aktiveAutomaten: string,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}reporting/flatfile`, {
        params: {
          tkArt: tkArt,
          rnsGln: rnsGln,
          serienNr: serienNr,
          aktiveAutomaten: aktiveAutomaten,
        },
      })
      .then((response) => {
        fileDownload(
          JSON.stringify(response.data),
          "PFD_Flatfile_" +
            DateUtils.formatDateToAPIDateString(new Date(), "yyyy_MM_dd") +
            ".json",
          "application/json"
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default FlatFileService;
