import {
  Enumerator, EnumeratorItem
} from "global/components/EnumeratedValues/Enumerator";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import AutomatModellResult, { AutomatModell } from "service/data-service/kaufanfragen-controller/interface/AutomatModell";
import KaufanfrageStatusResult, { KaufanfrageStatus } from "service/data-service/kaufanfragen-controller/interface/KaufanfrageStatus";
import KaufanfragenService from "service/data-service/kaufanfragen-controller/Kaufanfragen.Service";
import AutomatenblattStammdaten, { Stammdaten } from "service/data-service/stammdaten-controller/interface/AutomatenblattStammdaten";
import StammdatenService from "service/data-service/stammdaten-controller/Stammdaten.service";
import useSnackbarGenerator from "./use-snackbars";

export const AUTOMATENBLATT_STANDORT_ENUM_ID = "AUTOMATSTANDORT";
export const AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID = "SORTIERKONFIG";
export const AUTOMATENBLATT_KOMMFORM_ENUM_ID = "TKART";
export const AUTOMATENBLATT_KOMMART_ENUM_ID = "PROTOKOLLTYP";
export const AUTOMATENBLATT_CLEARER_ENUM_ID = "CLEARER";
export const AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID = "DATENSPEDITEUR";
export const AUTOMATENBLATT_NOTIZTYP_ENUM_ID = "NOTIZTYP";
export const AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID = "NOTIZSTATUS";
export const AUTOMATENBLATT_LOGFILEKATEGORIE_ENUM_ID = "LOGFILEKATEGORIE";
export const AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID =
  "AUTOMATSTATUSZUKUNFT";
export const AUTOMATENBLATT_AUTOMATART_ENUM_ID = "AUTOMATART";

export const AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID = "AUTOMATSTATUS";
export const AUTOMATENBLATT_AUTOMATMODELLE_REGION_ENUM_ID =
  "AUTOMATMODELLE_REGION";
export const AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID =
  "AUTOMATHERSTELLER_REGION";
export const AUTOMATENBLATT_DPGAUTOMAT_ENUM_ID = "DPGAUTOMAT";
export const AUTOMATENBLATT_RUECKNEHMER_ENUM_ID = "RUECKNEHMER";

export const AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID = "KAUFANFRAGENSTATUS";
export const AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID = "KAUFANFRAGENMODELL";

const ALL_ENUMERATOR_IDS = [
  AUTOMATENBLATT_STANDORT_ENUM_ID,
  AUTOMATENBLATT_SORTIERKONFIG_ENUM_ID,
  AUTOMATENBLATT_KOMMFORM_ENUM_ID,
  AUTOMATENBLATT_KOMMART_ENUM_ID,
  AUTOMATENBLATT_CLEARER_ENUM_ID,
  AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID,
  AUTOMATENBLATT_NOTIZTYP_ENUM_ID,
  AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID,
  AUTOMATENBLATT_LOGFILEKATEGORIE_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID,
  AUTOMATENBLATT_AUTOMATART_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUS_ENUM_ID,
  AUTOMATENBLATT_AUTOMATMODELLE_REGION_ENUM_ID,
  AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID,
  AUTOMATENBLATT_DPGAUTOMAT_ENUM_ID,
  AUTOMATENBLATT_RUECKNEHMER_ENUM_ID,
  AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID,
  AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID,
] as const;
type EnumeratorIdTuple = typeof ALL_ENUMERATOR_IDS;
export type EnumeratorID = EnumeratorIdTuple[number];

export type EnumeratorStatus = "PENDING" | "INITIAL" | "SYNC";

export interface EnumeratorWithStatus {
  id: EnumeratorID;
  status: EnumeratorStatus;
  enum: Enumerator;
}

export type Enumerators = Array<EnumeratorWithStatus>;

let globalEnumerators: Enumerators = ALL_ENUMERATOR_IDS.map((e) => {
  return {
    id: e,
    status: "INITIAL",
    enum: new Enumerator([]),
  };
});
let listeners = new Map<
  EnumeratorID,
  Array<Dispatch<SetStateAction<Enumerators>>>
>();

const broadcastEnumeratorStateChange = (enumeratorKey: EnumeratorID) => {
  const enumListeners = listeners.get(enumeratorKey) ?? [];
  for (const listener of enumListeners) {
    listener(globalEnumerators);
  }
};

const updateEnumerator = (
  enumeratorID: EnumeratorID,
  enumStatus: EnumeratorStatus,
  enumerator: Enumerator
) => {
  const index = globalEnumerators.findIndex((e) => e.id === enumeratorID);
  const updatedEnumerators = [...globalEnumerators];
  updatedEnumerators[index] = {
    ...globalEnumerators[index],
    status: enumStatus,
    enum: enumerator,
  };
  globalEnumerators = updatedEnumerators;
  broadcastEnumeratorStateChange(enumeratorID);
};

type EnumSourceArray = Array<Stammdaten> | Array<KaufanfrageStatus> | Array<AutomatModell>;

const extractEnumeratorItems = (sourceItems: EnumSourceArray): Array<EnumeratorItem> => {
  if (sourceItems.length === 0) return []
  type ObjectKey = keyof typeof sourceItems[0];
  return sourceItems.map(
    (i) => {
      return {
        id: i.key,
        value: i['value' as ObjectKey] ?? i['name' as ObjectKey],
      };
    }
  );
}

export const useEnumerator = (
  enumeratorID: EnumeratorID,
  forceSync?: boolean
) => {
  const setState = useState(globalEnumerators)[1];
  const { showError, showInfo } = useSnackbarGenerator();

  const enumIndex = globalEnumerators.findIndex((e) => e.id === enumeratorID);

  const onEnumSyncSuccess = (rawItems: EnumSourceArray) => {
    updateEnumerator(
      enumeratorID,
      "SYNC",
      new Enumerator(extractEnumeratorItems(rawItems)))
  }

  const onEnumSyncError = (message: string, error: Error) => {
    updateEnumerator(
      enumeratorID,
      "INITIAL",
      globalEnumerators[enumIndex].enum
    );
    showError(message, { error: error });
  }

  const syncEnumerator = () => {
    updateEnumerator(
      enumeratorID,
      "PENDING",
      globalEnumerators[enumIndex].enum
    );

    if (enumeratorID === AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID) {
      KaufanfragenService.status(
        (kaufanfrageStatusResult: KaufanfrageStatusResult) => onEnumSyncSuccess(kaufanfrageStatusResult.Row),
        (err: Error) => onEnumSyncError("Syncing Kaufanfragen STATUS failed", err)
      )
    } else if (enumeratorID === AUTOMAT_KAUFANFRAGENMODELL_ENUM_ID) {
      KaufanfragenService.modell(
        (result: AutomatModellResult) => onEnumSyncSuccess(result.Row),
        (err: Error) => onEnumSyncError("Syncing Kaufanfragen Automat Modell failed", err)
      )
    } else {
      StammdatenService.retrieveStammdatenInformation(
        enumeratorID,
        (stammdatenInformationDTO: AutomatenblattStammdaten) => onEnumSyncSuccess(stammdatenInformationDTO.row),
        (err: Error) => onEnumSyncError("Syncing stammdaten failed for " + enumeratorID, err)
      );
    }
  };

  useEffect(() => {
    const enumListeners = listeners.get(enumeratorID) ?? [];
    enumListeners.push(setState);
    listeners.set(enumeratorID, enumListeners);

    const enumerator = globalEnumerators[enumIndex];
    const forceSyncing = forceSync ?? false;
    if (forceSyncing || enumerator.status === "INITIAL") {
      syncEnumerator();
    }

    return () => {
      let enumListeners = listeners.get(enumeratorID);
      enumListeners = enumListeners!.filter((li) => li !== setState);
      if (enumListeners.length === 0) {
        listeners.delete(enumeratorID);
      }
    };
  }, [setState]);

  return globalEnumerators[enumIndex].enum;
};
