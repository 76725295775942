import { AxiosResponse } from "axios";
import ClearingfehlerResult, { Clearingfehler } from "../interface/Clearingfehler";

const ClearingfehlerDTO = {
  convertResponseToClearingfehlerResultDTO: (
    response: AxiosResponse
  ): ClearingfehlerResult => {
    if (response.data) {
      return {
        total: response.data.total,
        rows: response.data.Row,
      };
    }
    return {} as ClearingfehlerResult;
  },
  convertResponseToClearingfehlerDTO: (
    response: AxiosResponse
  ): Clearingfehler => {
    if (response.data) {
      return response.data as Clearingfehler;
    }
    return {} as Clearingfehler;
  },
};
export default ClearingfehlerDTO;
