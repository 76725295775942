import DPGStammdatenResult from 'service/data-service/stammdaten-controller/interface/DPGStammdaten';
import StammdatenService from 'service/data-service/stammdaten-controller/Stammdaten.service';
import AutomatAction, { AutomatActionCallback, AutomatActionPayload } from '../interface/AutomatAction';
import AutomatDataRecord from '../interface/AutomatDataRecord';
import { registerAutomatDataStoreActions } from '../use-automat-datastore';

export const FETCH_DPG_STAMMDATEN = 'FETCH_DPG_STAMMDATEN';

const configureAutomatDpgStammdatenStore = () => {
    const fetchInfo: AutomatAction =
    {
        identifier: FETCH_DPG_STAMMDATEN,
        action: (
            currentState: AutomatDataRecord,
            payload: AutomatActionPayload,
            callback: AutomatActionCallback,
            callbackOnFail: Function) => {

            StammdatenService.getDpgStammdaten(
                payload.automatKey,
                (data: DPGStammdatenResult) => callback(
                    {
                        dpgStammdaten: data,
                    } as AutomatDataRecord),
                callbackOnFail);
        }
    };
    registerAutomatDataStoreActions([fetchInfo]);
};

export default configureAutomatDpgStammdatenStore;
