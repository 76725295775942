import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import AutomatDTO from "./dto/AutomatDTO";

const basePath: string = "automat-utilities/";

const AutomatService = {
  ping: (
    ipAddress: string,
    numberOfPackages: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(`${Config.automatServiceUrl}${basePath}pingHost`, {
        params: { ipAddress: ipAddress, numberOfPackages: numberOfPackages },
      })
      .then((response) => {
        callbackOnSuccess(AutomatDTO.convertPingResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  trace: (
    ipAddress: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(`${Config.automatServiceUrl}${basePath}traceRoute`, {
        params: { ipAddress: ipAddress },
      })
      .then((response) => {
        callbackOnSuccess(AutomatDTO.convertPingResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default AutomatService;
