
import ModalController from "global/hook/modal/interface/ModalController";
import ArtikleSearchResult from "service/artikel-service/interface/ArtikleSearchResult";



export interface ArtikelSucheDisplayInfo {
    readonly artikelLangText?: string;
    readonly gueltigAb?: string;
    readonly gueltigBis?: string;
    readonly letzteRuecknahmeArtikelOfAutomat?: string;
    readonly letzteRuecknahmeAutomat?: string;
    readonly letzteRuecknhameArtikelAllg?: string;
}

const ArtikelSucheController = {

    getAbrechnungsinformationenErrorText(errorTyp: string, gtin: number | undefined, maschineId: string) {
        switch (errorTyp) {
            case "INVALID_GTIN":
                return "Die angegebene GTIN ist nicht gültig.";
            case "ARTICLE_NOT_BILLED_YET":
                return "Der Automat wurde mit der aktuellen SSID noch nicht abgerechnet. Eine Aussage zur Rücknahme ist daher nicht möglich.";
            case "NO_DATA":
                return "Es konnten keine Daten gefunden werden.";
            case "ARTICLE_NOT_VALID_RIGHT_NOW":
                return "Der Artikel ist derzeit nicht gültig.";
            case "UNKNOWN":
                return `Ein unbekannter Fehler ist aufgetreten (GTIN: ${gtin} / SchlüsselID: ${maschineId})`;
            case "INVALID_DATE":
                return "Das in der Datenbank stehende Datum kann nicht formatiert werden.";
            default:
                return "Ein unbekannter Fehler ist aufgetreten. Bitte melden Sie sich beim technischen Support. ";
        }
    },

    transmuteSearchResultToDisplayInfo(result: ArtikleSearchResult, modals?: ModalController): ArtikelSucheDisplayInfo {
        if (!result.artikel || !result.ruecknahmeInfo) {
            return {}
        }
        if (result.errorTyp !== "NO_ERROR") {
            if (modals)
                modals.showMessageDialog(
                    this.getAbrechnungsinformationenErrorText(
                        result.errorTyp,
                        result.ruecknahmeInfo.gtin,
                        result.ruecknahmeInfo.maschineId
                    )
                );
            return {};
        }

        return {
            artikelLangText: result.artikel.artikelLangText,
            gueltigAb: result.artikel.gueltigAb,
            gueltigBis: result.artikel.gueltigBis,
            letzteRuecknahmeArtikelOfAutomat:
                result.ruecknahmeInfo.letzteRuecknahmeArtikelOfAutomat,
            letzteRuecknahmeAutomat: result.ruecknahmeInfo.letzteRuecknahmeAutomat,
            letzteRuecknhameArtikelAllg:
                result.ruecknahmeInfo.letzteRuecknhameArtikelAllg,
        }
    },
}

export default ArtikelSucheController;
