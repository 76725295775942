import { Stack } from "@mui/material";
import AutomatDataRecord from "global/hook/datastore/interface/AutomatDataRecord";
import { useEffect, useState } from "react";
import { AuslastungVerfuegbarkeitAutomat } from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import ReadOnlyValue from "../ReadOnlyValue/ReadOnlyValue";
import BasicTable from "../TableContainer/TableContainer";
import { VerfügbarkeitsanalyseHeader } from "./resource/VerfugbarkeitsanalyseHeader";
import VerfuegbarkeitController from "./verfuegbarkeit-controller";
import "./verfuegbarkeit.scss";

interface VerfugbarkeitsanalyseProps {
  automatData: AutomatDataRecord;
}

const Verfugbarkeitsanalyse: React.FC<VerfugbarkeitsanalyseProps> = (
  props: VerfugbarkeitsanalyseProps
) => {
  const [verfuegbarkeit, setVerfuegbarkeit] =
    useState<AuslastungVerfuegbarkeitAutomat | null>(null);

  useEffect(() => {
    setVerfuegbarkeit(
      props.automatData.automatenblattInformation.auslastungVerfuegbarkeitDTO
    );
  }, [props.automatData]);

  return (
    <Stack className="verfuegbarkeit">
      <ReadOnlyValue
        label="Daten vom:"
        value={VerfuegbarkeitController.createDateString(
          verfuegbarkeit?.datenbestandVom
        )}
      />
      <BasicTable
        head={VerfügbarkeitsanalyseHeader}
        rows={VerfuegbarkeitController.createTable(verfuegbarkeit)}
        id="verfuegbarkeit"
      />
    </Stack>
  );
};
export default Verfugbarkeitsanalyse;
