import { Typography } from "@mui/material";
import deLocale from "date-fns/locale/de";
import { AuslastungVerfuegbarkeitAutomat } from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { TableRowProps } from "../TableContainer/TableRow";

const coloring = ["", "error.main", "warning.main", "success.main"];

const VerfuegbarkeitController = {
  createTable(
    verfuegbarkeit: AuslastungVerfuegbarkeitAutomat | null
  ): Array<TableRowProps> {
    const elements: Array<TableRowProps> = [];
    if (verfuegbarkeit !== null) {
      elements[0] = {
        row: [
          {
            value: verfuegbarkeit.durchschnittGebinde,
            props: { align: "center" },
          },
          {
            value: this.valueColor(
              verfuegbarkeit.verfuegbarkeitGrad,
              verfuegbarkeit.verfuegbarkeitLevel
            ),
            props: { align: "center" },
          },
          {
            value: verfuegbarkeit.verfuegbarkeitGradTendenzZurVorwoche,
            props: { align: "center" },
          },
          {
            value: this.valueColor(
              verfuegbarkeit.auslastungGrad,
              verfuegbarkeit.auslastungLevel
            ),
            props: { align: "center" },
          },
          {
            value: verfuegbarkeit.ranking,
            props: { align: "center" },
          },
        ],
      };
    }
    return elements;
  },
  createDateString(datenbestandVom: number[] | undefined): string {
    const dateTimeFormat: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    return datenbestandVom
      ? new Date(
          datenbestandVom[0],
          datenbestandVom[1] - 1,
          datenbestandVom[2],
          datenbestandVom[3],
          datenbestandVom[4]
        )
          .toLocaleString(deLocale.code, dateTimeFormat)
          .replace(",", "")
      : "";
  },
  valueColor(value: number, level: number): any {
    return (
      <Typography sx={{ color: coloring[level], borderColor: coloring[level] }}>
        {value}
      </Typography>
    );
  },
};

export default VerfuegbarkeitController;
