import { Stack, Typography } from "@mui/material";
import { convertStatusValue, StatusIconType } from "global/components/UI/StatusIcon/StatusIcon";
import ModalController, { DialogController } from "global/hook/modal/interface/ModalController";
import AutomatService from "service/automat-service/Automat.service";
import PingResult from "service/automat-service/interface/PingResult";
import TraceRouteResult from "service/automat-service/interface/TraceRouteResult";
import CheckAutomatResponse, { CheckAutomat } from "service/data-service/automate-controller/interface/CheckAutomat";

export enum ABRUF_REMOTE_OPERATION_TYPE {
    "ping",
    "traceroute"
}

export interface RemoteOperationResultWrapper {
    error?: Error,
    result: PingResult | TraceRouteResult
}

const AbrufPanelController = {
    transmuteToGridData(
        response: CheckAutomatResponse,
    ): Array<CheckAutomat> {
        let data: Array<CheckAutomat> = [];

        let records = response.rows ?? [];
        if (records.length === 1 && records[0].errorTyp === 'NO_DATA') {
            return data;
        }
        records.forEach((element) => {
            const record = {
                ...element,
                ...{
                    statusCellNode: convertStatusValue(this.reduceCheckStatus(element.status), false),
                    kommTyp: (element.kommTyp ?? "").replace("AT_", ""),
                },
            };
            data.push(record);
        });
        return data;
    },
    reduceCheckStatus(theValue: string | null): string | null {
        if (theValue === 'NOK' || theValue === 'FAILURE') {
            return StatusIconType.NOK
        } else if (theValue === 'OK' || theValue === 'SUCCESS') {
            return StatusIconType.OK
        } else if (theValue === 'RN' || theValue === 'RUNNING') {
            return StatusIconType.RN
        }
        return null;
    },
    executeRemoteOperation(
        operation: ABRUF_REMOTE_OPERATION_TYPE,
        remoteIp: string,
        modalController: ModalController,
        dialogController: DialogController,
    ): void {

        if (operation === ABRUF_REMOTE_OPERATION_TYPE.traceroute) {
            modalController.showLoadingDialog('Der Traceroute wird im Hintergrund ausgeführt, bitte haben Sie einen Moment Geduld.');
            AutomatService.trace(
                remoteIp,
                (result: TraceRouteResult) => remoteOperationSummary(modalController, dialogController, result),
                (error: Error) => remoteOperationSummary(modalController, dialogController, {} as TraceRouteResult, error)

            );
        } else {
            modalController.showLoadingDialog('Der Ping wird im Hintergrund ausgeführt, bitte haben Sie einen Moment Geduld.');
            AutomatService.ping(
                remoteIp,
                '64',
                (result: PingResult) => remoteOperationSummary(modalController, dialogController, result),
                (error: Error) => remoteOperationSummary(modalController, dialogController, {} as PingResult, error)
            );
        }
    },
}

function remoteOperationSummary(modalController: ModalController, dialogController: DialogController, result: PingResult | TraceRouteResult, error?: Error) {
    // close loading/executing modal
    modalController.closeModal();
    if (error) {
        modalController.showError("Aufgabe konnte nicht ausgeführt werden", error);
    } else {
        const textColor = result.color === 'red' ? "error.main" : "success.main";
        let content =
            <Stack rowGap={2}>
                <Typography fontWeight={500} color={textColor} alignSelf="center">
                    {result.resultText}
                </Typography>
                <Typography fontWeight={500}>
                    Technische Verbindungsinformationen:
                </Typography>
                <Typography>
                    {result.rawResult}
                </Typography>
            </Stack>;
        dialogController.showDialog({
            message: '',
            content: content,
            onOkClick: () => { }
        })
    }
}

export default AbrufPanelController;
