import AutomatCheckService from 'service/data-service/automate-controller/AutomatCheck.service';
import CheckAutomatResponse from 'service/data-service/automate-controller/interface/CheckAutomat';
import AutomatAction, { AutomatActionCallback, AutomatActionPayload } from '../interface/AutomatAction';
import AutomatDataRecord from '../interface/AutomatDataRecord';
import { registerAutomatDataStoreActions } from '../use-automat-datastore';

export const FETCH_AUTOMAT_CHECK = 'FETCH_AUTOMAT_CHECK';

const configureAutomatCheckStore = () => {
    const fetchCheckInfo: AutomatAction =
    {
        identifier: FETCH_AUTOMAT_CHECK,
        action: (
            currentState: AutomatDataRecord,
            payload: AutomatActionPayload,
            callback: AutomatActionCallback,
            callbackOnFail: Function) => {

            AutomatCheckService.check(
                payload.checkAction,
                payload.automatKey,
                (data: CheckAutomatResponse) => callback(
                    {
                        checkupResponse: data,
                    } as AutomatDataRecord),
                callbackOnFail);
        }
    };
    registerAutomatDataStoreActions([fetchCheckInfo]);
};

export default configureAutomatCheckStore;
