import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { TabControlContext } from "page/Automatensuche/components/TabControl/TabControlProvider";
import { useContext } from "react";
import ExportService from "service/report-service/export/Export.service";
import DsmdService from "service/ruecknehmer-service/info-controller/Dsmd.service";
import AutomatDatenDsmd from "service/ruecknehmer-service/info-controller/interface/AutomatDatenDsmd";
import DsmdColumnDefs from "./grid-column-defs";

const DsmdFehler: React.FC = () => {
  const tabControlCtx = useContext(TabControlContext);

  return (
    <Stack className="erreichbarkeitRecords">
      <Grid container spacing={1} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>Fehlerhafte DSMD-Verbindungen</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={430}
            columnDefs={DsmdColumnDefs}
            onRowClicked={(row: AutomatDatenDsmd) => {
              tabControlCtx.push(row.serienNr);
            }}
            exportDef={{ action: ExportService.downloadRnBTBDSMDFailedToday }}
            rowsDataDef={{
              uncontrolledDataFetchCall: DsmdService.getFailedDsmd,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DsmdFehler;
