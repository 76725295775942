export function getFilenameOutOfContentDisposition(dispositionContent: string) {
  let contentDispositionArray = dispositionContent.split(";");
  let indexOfFilename = contentDispositionArray.findIndex((value) =>
    value.includes("filename=")
  );
  if (indexOfFilename >= 0) {
    let filename = contentDispositionArray[indexOfFilename].replace(
      "filename=",
      ""
    );
    return filename;
  } else return "Unknown File";
}
