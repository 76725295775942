import ClearingfehlerService from 'service/data-service/clearingfehler-controller/Clearingfehler.service';
import ClearingfehlerResult, { Clearingfehler } from 'service/data-service/clearingfehler-controller/interface/Clearingfehler';
import AutomatAction, { AutomatActionCallback, AutomatActionPayload } from '../interface/AutomatAction';
import AutomatDataRecord from '../interface/AutomatDataRecord';
import { registerAutomatDataStoreActions } from '../use-automat-datastore';

export const FETCH_AUTOMAT_CLEARINGFEHLER = 'FETCH_AUTOMAT_CLEARINGFEHLER';
export const CLEARINGFEHLER_ERLEDIGT_CHANGE = 'CLEARINGFEHLER_ERLEDIGT_CHANGE';

const configureClearingFehlerStore = () => {
    const fetchClearingfehler: AutomatAction =
    {
        identifier: FETCH_AUTOMAT_CLEARINGFEHLER,
        action: (
            currentState: AutomatDataRecord,
            payload: AutomatActionPayload,
            callback: AutomatActionCallback,
            callbackOnFail: Function) => {

            ClearingfehlerService.getClearingfehlerForAutomat(
                payload.automatKey,
                (data: ClearingfehlerResult) => callback(
                    {
                        clearingFehler: data,
                    } as AutomatDataRecord),
                callbackOnFail);
        }
    };
    const onErledigtChange: AutomatAction =
    {
        identifier: CLEARINGFEHLER_ERLEDIGT_CHANGE,
        action: (
            currentState: AutomatDataRecord,
            payload: AutomatActionPayload,
            callback: AutomatActionCallback,
            callbackOnFail: Function) => {

            const record: Clearingfehler = payload.clearingfehler;
            const indexOfRecord = currentState.clearingFehler!.rows.findIndex((e) =>
                e.schluesselId === record.schluesselId &&
                e.pruefungsdatum === record.pruefungsdatum &&
                e.gueltigVon === record.gueltigVon &&
                e.gueltigBis === record.gueltigBis &&
                e.fehlerbeschreibung === record.fehlerbeschreibung &&
                e.anzahlRohdatensaetze === record.anzahlRohdatensaetze &&
                e.anzahlMm === record.anzahlMm);

            const updatedClearingFehlerRows = [...currentState.clearingFehler!.rows];
            updatedClearingFehlerRows[indexOfRecord] = record

            callback(
                {
                    clearingFehler: {
                        total: 0,
                        rows: updatedClearingFehlerRows
                    },
                } as AutomatDataRecord)
        }
    };
    registerAutomatDataStoreActions([fetchClearingfehler, onErledigtChange]);
};

export default configureClearingFehlerStore;
