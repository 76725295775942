
export type EnumeratorItemId = string | number;

export interface EnumeratorItem {
    value: string;
    id: EnumeratorItemId;
}

export class Enumerator {
    readonly items: Array<EnumeratorItem>;

    constructor(items: Array<EnumeratorItem>) {
        this.items = items;
    }

    itemForId(id: EnumeratorItemId): EnumeratorItem | undefined {
        return this.items.find((item) => item.id === id);
    }

    itemForValue(value: string): EnumeratorItem | undefined {
        return this.items.find((item) => item.value === value);
    }
}
