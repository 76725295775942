import { Card } from "@mui/material";
import {
  HREF_ADMIN_ANWENDERNACHRICHTEN,
  HREF_ADMIN_IPBEREICH,
  HREF_AUTOMATENKAUFANFRAGEN,
  HREF_AUTOMATENSUCHE,
  HREF_BERICHTE_ANSICHTFEHLER,
  HREF_BERICHTE_ANSICHTWECHSEL,
  HREF_BERICHTE_DSMD_FEHLERHAFT,
  HREF_BERICHTE_ERREICHBARKEITEN,
  HREF_BERICHTE_FLATFILE,
  HREF_BERICHTE_VERFUGBARKEISANALYSE,
  HREF_BERICHTE_ZURBEARBEITUNG,
  HREF_DATENPFLEGE_RNS,
  HREF_HOME,
  HREF_NOTIZEN_SUCHE,
  HREF_NOTIZEN_WIEDERVORLAGE,
} from "global/util/routes";
import Anwendernachrichten from "page/administration/anwendernachrichten/Anwendernachrichten";
import IpRange from "page/administration/ip-range/IpRange";
import Automatensuche from "page/Automatensuche/Automatensuche";
import Ansichtfehler from "page/berichte/ansichtfehler/Ansichtfehler";
import ZurBearbeitung from "page/berichte/bearbeitung/ZurBearbeitung";
import DsmdFehler from "page/berichte/dsmd/Dsmd";
import BerichteFlatfile from "page/berichte/flatfile/BerichteFlatfile";
import KommArt from "page/berichte/kommArt/KommArt";
import NotZuOk from "page/berichte/notZuOk/NotZuOk";
import Cockpit from "page/cockpit/Cockpit";
import DatenpflegeRns from "page/datenpflegerns/DatenpflegeRns";
import Kaufanfragen from "page/kaufanfragen/Kaufanfragen";
import NotizenSuche from "page/notizen/suche/NotizenSuche";
import NotizenWiedervorlage from "page/notizen/wiedervorlage/NotizenWiedervorlage";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AccessContext } from "../Access-Control/AccessProvider";

import AuslastungVerfuegbarkeit from "page/berichte/auslastungverfuegbarkeit/AuslastungVerfuegbarkeit";
import "../../App.scss";

const AppContent = () => {
  const accessContext = useContext(AccessContext);
  const access = accessContext.getAccessContainer();

  return (
    <Card className="content">
      <Routes>
        <Route path={HREF_HOME} element={<Cockpit />} />
        <Route
          path={HREF_AUTOMATENSUCHE + "/*"}
          element={
            access.showEntryAutomatensuche ? (
              <Automatensuche />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_AUTOMATENKAUFANFRAGEN + "/*"}
          element={
            access.showEntryKaufanfragen ? (
              <Kaufanfragen />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_DATENPFLEGE_RNS}
          element={
            access.showEntryRns ? (
              <DatenpflegeRns />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_BERICHTE_ANSICHTFEHLER}
          element={
            access.showEntryAnsichtFehlerhafteMm ? (
              <Ansichtfehler />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_BERICHTE_ANSICHTWECHSEL}
          element={
            access.showEntryAnsichtWechselNokAufOk ? (
              <NotZuOk />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_BERICHTE_ERREICHBARKEITEN}
          element={
            access.showEntryErreichbarkeitenJeKommart ? (
              <KommArt />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_BERICHTE_ZURBEARBEITUNG}
          element={
            access.showEntryZurBearbeitungAnstehend ? (
              <ZurBearbeitung />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_BERICHTE_FLATFILE}
          element={
            access.showEntryFlatfile ? (
              <BerichteFlatfile />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_BERICHTE_DSMD_FEHLERHAFT}
          element={
            access.showEntryDsmdFehlerhaft ? (
              <DsmdFehler />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_BERICHTE_VERFUGBARKEISANALYSE}
          element={
            access.showEntryVerfuegbarkeit ? (
              <AuslastungVerfuegbarkeit />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_NOTIZEN_SUCHE}
          element={
            access.showEntryNotizenSuche ? (
              <NotizenSuche />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_NOTIZEN_WIEDERVORLAGE}
          element={
            access.showEntryWiedervorlage ? (
              <NotizenWiedervorlage />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
        <Route
          path={HREF_ADMIN_ANWENDERNACHRICHTEN}
          element={<Anwendernachrichten />}
        />
        <Route
          path={HREF_ADMIN_IPBEREICH}
          element={
            access.showEntryIpBereiche ? (
              <IpRange />
            ) : (
              <Navigate to={HREF_HOME} />
            )
          }
        />
      </Routes>
    </Card>
  );
};

export default AppContent;
