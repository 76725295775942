import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import DataGrid from "global/components/UI/DataGrid/DataGrid";
import UIButton from "global/components/UI/UIButton/UIButton";
import React from "react";
import { StatusLog } from "service/data-service/automate-controller/interface/AutomatStatusLog";
import { StatusLogColumnDefs } from "./grid-column-defs";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface StatusDialogProps {
    title: string;
    open: boolean;
    onClose: Function;
    records: Array<StatusLog>;
}

const StatusLogDataDialog: React.FC<StatusDialogProps> = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            fullWidth
            maxWidth={'lg'}
            TransitionComponent={Transition}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Grid item gridColumn="span 2" xs={12}>
                    {<DataGrid
                        height={168}
                        columnDefs={StatusLogColumnDefs}
                        rowsDataDef={{ data: props.records }}
                    />}
                </Grid>
            </DialogContent>
            <DialogActions>
                <UIButton onClick={() => props.onClose()} label="Abbrechen" />
            </DialogActions>
        </Dialog>
    );
};

export default StatusLogDataDialog;
