import RnsSearchResult from "service/data-service/rns-controller/interface/RnsSearchResult";

export function generateDefaultRnsSearchResult() {
  return {
    total: 0,
    rows: [
      {
        name: "",
        strasse: "",
        plz: "",
        ort: "",
        gln: "",
        edekaOrganisation: { name: "", gln: "", email: "", region: "" },
      },
    ],
  } as RnsSearchResult;
}
