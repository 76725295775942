import { Stack } from "@mui/material";
import { FETCH_AUTOMATTENBLATT_INFORMATION } from "global/hook/datastore/automat/automatenblatt-information-store";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import useModalController from "global/hook/modal/use-modals";
import { useEffect, useRef, useState } from "react";
import AutomatenblattInformationService from "service/data-service/automate-controller/Automatenblatt.service";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import "./automatenblatt.scss";
import AutomatDetailPanelContainer from "./components/AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import AutomatSheet from "./components/AutomatSheet/AutomatSheet";
import { rnsBonNumberEnteredIsValid } from "./components/AutomatSheet/script/validateUdpateValues";
import clearUpdateValues from "./script/clearUpdateValues";

interface AutomatenblattProps {
  automatKey: string;
}

export const Automatenblatt: React.FC<AutomatenblattProps> = (
  props: AutomatenblattProps
) => {
  const modals = useModalController()
  const automatKey: string = props.automatKey;

  const [automatData, refreshAutomatData] = useAutomatData(props.automatKey);

  const updateValues = useRef<AutomatenUpdateRequest>({
    automatKey: automatKey,
  });

  const [resetValues, setResetValues] = useState<boolean>(false);

  function updateAutomat() {
    if (rnsBonNumberEnteredIsValid(updateValues.current)) {
      AutomatenblattInformationService.update(updateValues, () => {
        refreshAutomatData(FETCH_AUTOMATTENBLATT_INFORMATION);
      });
    } else {
      modals.showMessageDialog(
        'Das Datum fehlt oder liegt nicht in Zukunft oder die SAP Bon Nr. ist nicht ausgefüllt bzw. nicht zwischen 5 und 7 Zeichen lang.',
        undefined,
        'SAP Bon Nr.');
    }
  }

  // Put updateValues back to default, when "Verwerfen" is pushed or the Tab is switched
  useEffect(() => {
    clearUpdateValues(updateValues, automatData.automatenblattInformation);
  }, [automatData, resetValues]);

  return (
    <Stack className="automatenblattContainer">
      <AutomatSheet
        automatKey={props.automatKey}
        updateValues={updateValues}
        resetValues={resetValues}
        onReset={setResetValues}
        onSave={updateAutomat}
      />

      <AutomatDetailPanelContainer
        automatKey={automatKey}
        updateValues={updateValues}
        resetValues={resetValues}
      />
    </Stack>
  );
};
