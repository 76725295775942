import { Grid, Typography } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import {
  AUTOMATENBLATT_CLEARER_ENUM_ID,
  AUTOMATENBLATT_KOMMART_ENUM_ID,
  useEnumerator,
} from "App/hook/use-enumerator";
import "global/components/ui.scss";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  EpcomReducerFunctionOptional,
  EpcomReducerOptional,
} from "global/interface/EpcomReducer";
import DateUtils from "global/util/DateUtils";
import { DataControl } from "global/util/enum/DataControl";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { returnUndefinedIfOnlyWhitespace } from "global/util/utils";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { TKDaten } from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { TkDaten } from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import MarkedTkDetails from "./interface/markedTkDetails";
import TKPartnerDetailCard, {
  ARTIKELSTAMM_TYPE_ID,
  DPGBTB_TYPE_ID,
  EDKBTB_TYPE_ID,
  LOGGING_TYPE_ID,
  MENGENMELDUNG_TYP_ID,
} from "./TKPartnerDetailCard";

interface TKPartnerProps extends PanelBaseProps {
  automatKey: string;
  onOneElementMarked: (marked: boolean) => void;
}

const TKPartnerPanel = (props: TKPartnerProps) => {
  const [automatData] = useAutomatData(props.automatKey);

  const stateChangeAndReset: EpcomReducerFunctionOptional<TKDaten> = (
    state,
    action
  ) => {
    if (action.type === DataControl.CHANGE && action.valueToChange) {
      return { ...state, ...action.valueToChange };
    } else {
      return automatData.automatenblattInformation.tkDaten;
    }
  };

  const [tkDaten, dispatchTkDaten] = useReducer<EpcomReducerOptional<TKDaten>>(
    stateChangeAndReset,
    automatData.automatenblattInformation.tkDaten ?? {}
  );

  const [tkClearer, setTkClearer] = useState(
    automatData.automatenblattInformation.tkClearer
  );

  const accessContext = useContext(AccessContext);
  const accessObject = accessContext.getAccessContainer();

  const kommArtEnumerator = useEnumerator(AUTOMATENBLATT_KOMMART_ENUM_ID);
  const clearerEnumerator = useEnumerator(AUTOMATENBLATT_CLEARER_ENUM_ID);

  function changeTKDatenValue(newValue: Optional<TKDaten>) {
    dispatchTkDaten({ type: DataControl.CHANGE, valueToChange: newValue });
  }

  function changeUpdateValuesTkDaten(newValue: Optional<TkDaten>) {
    props.updateValues.current.tkDaten = {
      ...props.updateValues.current.tkDaten,
      ...newValue,
    };
  }

  const [detailsMarked, setDetailsMarked] = useState<MarkedTkDetails>({
    mengenmeldung: false,
    artikelstamm: false,
    dpgBtb: false,
    edkBtb: false,
    logfile: false,
  });
  function changeDetailsMarked(changeMarkObject: MarkedTkDetails) {
    setDetailsMarked((state) => ({ ...state, ...changeMarkObject }));
  }

  const markTabHeader = useCallback(() => {
    let changeTabHeader = false;
    Object.values(detailsMarked).forEach((value) => {
      if (value) changeTabHeader = true;
    });
    props.onOneElementMarked(changeTabHeader);
  }, [detailsMarked]);

  useEffect(() => {
    markTabHeader();
  }, [markTabHeader]);

  useEffect(() => {
    dispatchTkDaten({ type: DataControl.RESET });
  }, [props.resetValues, props.automatKey]);

  return (
    <Grid container spacing={5} width={"auto"}>
      <Grid item className="grid_item_30rem" xs={12} sm={5}>
        <SelectBox
          label="Kommunikations Art:"
          enumerator={kommArtEnumerator}
          selection={kommArtEnumerator.itemForId(tkDaten.kommArt)}
          id="kommunikations-art"
          getSelectedValue={(value) => {
            changeTKDatenValue({ kommArt: value?.id.toString() });
            changeUpdateValuesTkDaten({ kommArt: value?.id.toString() });
          }}
          reset={props.resetValues}
        />

        <TextValue
          label="Adapter:"
          value={
            automatData.automatenblattInformation.automatCredentialList[0]
              .adapter
          }
          id="adapter"
          readOnly
        />
        <SelectBox
          label="Zuständiger Clearer:"
          enumerator={clearerEnumerator}
          selection={clearerEnumerator.itemForValue(tkClearer)}
          id="zustandiger-clearer"
          getSelectedValue={(value) => {
            setTkClearer(value?.id.toString() ?? "");
            changeUpdateValuesTkDaten({ tkClearer: value?.value });
          }}
          reset={props.resetValues}
        />
        <Typography variant={"h5"}>LAN</Typography>

        <TextValue
          label="IP Addresse v4:"
          value={tkDaten.ipV4Lan ?? ""}
          id="lan-ip4"
          onChange={(event) => {
            let newValue =
              returnUndefinedIfOnlyWhitespace(event.target.value) ?? "";
            changeTKDatenValue({
              ipV4Lan: newValue,
            });
            changeUpdateValuesTkDaten({ ipLan4: newValue });
          }}
        />
        <TextValue
          label="Standard Gateway v4:"
          value={tkDaten.stdGatewayV4 ?? ""}
          id="lan-gw4"
          onChange={(event) => {
            let newValue =
              returnUndefinedIfOnlyWhitespace(event.target.value) ?? "";
            changeTKDatenValue({
              stdGatewayV4: newValue,
            });
            changeUpdateValuesTkDaten({ ipGateway4: newValue });
          }}
        />
        <TextValue
          label="Subnetz v4:"
          value={tkDaten.subnetzV4 ?? ""}
          id="lan-subnet4"
          onChange={(event) => {
            let newValue = returnUndefinedIfOnlyWhitespace(event.target.value);
            changeTKDatenValue({
              subnetzV4: newValue ?? "",
            });
            changeUpdateValuesTkDaten({ ipSubnetz4: newValue });
          }}
        />
        <TextValue
          label="Switch Nr.:"
          value={tkDaten.switchNummer}
          hidden
          onChange={(event) => {
            changeTKDatenValue({
              switchNummer:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            });
            // TODO: add updateValues change
          }}
        />
        <DatePickerElement
          id="switch-number-gueltig-von"
          label="Switch Nr. gültig von:"
          hidden
          defaultValue={
            DateUtils.parseIsoDateString(tkDaten.switchNummerGueltigVon) ??
            undefined
          }
          getSelectedValue={(value) => {
            changeTKDatenValue({
              switchNummerGueltigVon: DateUtils.formatDate(value ?? undefined),
            });
            // TODO: add updateValues change
          }}
        />
        <TextValue
          label="Info Switch Nr.:"
          value={tkDaten.infoSwitchNummer}
          hidden
          onChange={(event) => {
            changeTKDatenValue({
              infoSwitchNummer:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            });
          }}
        />
        <TextValue
          label="Port Nr.:"
          value={tkDaten.portNummer}
          hidden
          onChange={(event) => {
            changeTKDatenValue({
              portNummer:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            });
          }}
        />
        <DatePickerElement
          id="port-number-gueltig-von"
          label="Port Nr. gültig von:"
          defaultValue={
            DateUtils.parseIsoDateString(tkDaten.portNummerGueltigVon) ??
            undefined
          }
          hidden
          getSelectedValue={(value) => {
            changeTKDatenValue({
              portNummerGueltigVon: DateUtils.formatDate(value ?? undefined),
            });
            // TODO: add updateValues change
          }}
        />
        <TextValue
          label="Info Port Nr.:"
          value={tkDaten.infoPortNr}
          hidden
          onChange={(event) => {
            changeTKDatenValue({
              infoPortNr:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            });
          }}
        />
      </Grid>
      <Grid item className="grid_item_40rem" xs={12} sm={7}>
        <TKPartnerDetailCard
          label={"Mengenmeldung"}
          automatenblattInformation={automatData.automatenblattInformation}
          typ_id={MENGENMELDUNG_TYP_ID}
          resetValues={props.resetValues}
          updateValues={props.updateValues}
          visibilitySettings={accessObject.tkpartnerMengenmeldung}
          onOneEmptyField={(mark) =>
            changeDetailsMarked({ mengenmeldung: mark })
          }
        />
        <TKPartnerDetailCard
          label={"Artikelstamm"}
          automatenblattInformation={automatData.automatenblattInformation}
          typ_id={ARTIKELSTAMM_TYPE_ID}
          resetValues={props.resetValues}
          updateValues={props.updateValues}
          visibilitySettings={accessObject.tkpartnerArtikelstamm}
          onOneEmptyField={(mark) =>
            changeDetailsMarked({ artikelstamm: mark })
          }
        />
        <TKPartnerDetailCard
          label={"DPG-BTB"}
          automatenblattInformation={automatData.automatenblattInformation}
          typ_id={DPGBTB_TYPE_ID}
          resetValues={props.resetValues}
          updateValues={props.updateValues}
          visibilitySettings={accessObject.tkpartnerDpgBtb}
          onOneEmptyField={(mark) => changeDetailsMarked({ dpgBtb: mark })}
        />
        <TKPartnerDetailCard
          label={"RN-BTB"}
          automatenblattInformation={automatData.automatenblattInformation}
          typ_id={EDKBTB_TYPE_ID}
          resetValues={props.resetValues}
          updateValues={props.updateValues}
          visibilitySettings={accessObject.tkpartnerEdkBtb}
          onOneEmptyField={(mark) => changeDetailsMarked({ edkBtb: mark })}
        />
        <TKPartnerDetailCard
          label={"Logfile"}
          automatenblattInformation={automatData.automatenblattInformation}
          typ_id={LOGGING_TYPE_ID}
          resetValues={props.resetValues}
          updateValues={props.updateValues}
          visibilitySettings={accessObject.tkpartnerLogfile}
          onOneEmptyField={(mark) => changeDetailsMarked({ logfile: mark })}
        />
      </Grid>
    </Grid>
  );
};

export default TKPartnerPanel;
