import { TableCell } from "@mui/material";
import { TableCellProps as TableCellPropsInterface } from "@mui/material/TableCell/TableCell";
import { ReactNode } from "react";

export interface TableCellProps {
  value: string | ReactNode;
  props?: TableCellPropsInterface;
  isHead?: boolean;
}

const Cell: React.FC<TableCellProps> = (element: TableCellProps) => {
  const defaultHeadCellStyle = {
    backgroundColor: "secondary.main",
    color: "text.primary",
  };
  return (
    <TableCell
      className={element.isHead ? "tableHeadCell" : "tableBodyCell"}
      sx={element.isHead ? defaultHeadCellStyle : {}}
      {...element.props}
    >
      {element.value}
    </TableCell>
  );
};

export default Cell;
