import { Delete, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID,
  useEnumerator,
} from "App/hook/use-enumerator";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  EpcomReducerFunctionOptional,
  EpcomReducerOptional,
} from "global/interface/EpcomReducer";
import { DataControl } from "global/util/enum/DataControl";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { useEffect, useReducer, useState } from "react";
import { KommTypeVisibility } from "service/data-service/access-controller/interface/Access";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { AutomatCredentials as AutomatUpdateCredentials } from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import TkPartnerController, {
  TKDetailsInformation,
} from "./script/TKPartnerController";

export const MENGENMELDUNG_TYP_ID = "AT_MM";
export const ARTIKELSTAMM_TYPE_ID = "CD_STD";
export const QUITERUNG_TYPE_ID = "CD_MMQUIT";
export const DPGBTB_TYPE_ID = "AT_DPGBTB";
export const EDKBTB_TYPE_ID = "AT_EDKBTB";
export const LOGGING_TYPE_ID = "AT_LOG";

interface TKPartnerDetailCardProps extends PanelBaseProps {
  label: string;
  automatenblattInformation: AutomatenblattInformation;
  typ_id: string;
  visibilitySettings: KommTypeVisibility;
  onOneEmptyField?: (markWithColor: boolean) => void;
}

/**
 * Contains all Information shown on TKPartnerDetailCard
 */

const TKPartnerDetailCard = (props: TKPartnerDetailCardProps) => {
  const stateChangeAndReset: EpcomReducerFunctionOptional<
    TKDetailsInformation
  > = (state, action) => {
    if (action.type === DataControl.CHANGE) {
      return { ...state, ...action.valueToChange };
    } else if (action.type === DataControl.CLEAR) {
      return { ...state, credentialsInfo: {}, detail: {} };
    } else {
      return TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
        props.automatenblattInformation,
        props.typ_id
      );
    }
  };

  const [tkDetailsInformation, dispatchTkDetailsInformation] = useReducer<
    EpcomReducerOptional<TKDetailsInformation>
  >(
    stateChangeAndReset,
    TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
      props.automatenblattInformation,
      props.typ_id
    )
  );
  const [markedRed, setMarkedRed] = useState(false);

  const datenspediteurEnumerator = useEnumerator(
    AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID
  );

  function changeTkDetailsInformation(
    newValue: Optional<TKDetailsInformation>
  ) {
    dispatchTkDetailsInformation({
      type: DataControl.CHANGE,
      valueToChange: newValue,
    });
  }

  function getKommTypeUpdateElement() {
    return props.updateValues.current.kommTypen?.find(
      (e) => e.kommTyp === props.typ_id
    );
  }

  function createKommTypeUpdateElementIfNotPresentInArray() {
    if (props.updateValues.current.kommTypen === undefined) {
      props.updateValues.current.kommTypen = [];
    }
    if (getKommTypeUpdateElement() === undefined) {
      props.updateValues.current.kommTypen?.push({
        kommTyp: props.typ_id,
      });
    }
  }

  function updateAutomatCredentials(
    newValue: Optional<AutomatUpdateCredentials>
  ) {
    createKommTypeUpdateElementIfNotPresentInArray();
    let indexOfCredentials = props.updateValues.current.kommTypen!.findIndex(
      (credentials) => credentials.kommTyp === props.typ_id
    );
    if (props.updateValues.current.kommTypen) {
      props.updateValues.current.kommTypen[indexOfCredentials] = {
        ...props.updateValues.current.kommTypen[indexOfCredentials],
        ...newValue,
      };
    }
  }

  const onOneEmptyField = props.onOneEmptyField;
  useEffect(() => {
    TkPartnerController.markHeaderIfOneEntryIsEmpty(
      tkDetailsInformation,
      () => {
        setMarkedRed(true);
        if (onOneEmptyField) onOneEmptyField(true);
      },
      () => {
        setMarkedRed(false);
        if (onOneEmptyField) onOneEmptyField(false);
      }
    );
  }, [tkDetailsInformation, onOneEmptyField]);

  // Reset on Verwerfen
  useEffect(() => {
    dispatchTkDetailsInformation({ type: DataControl.RESET });
  }, [props.resetValues]);

  return (
    <Accordion>
      <AccordionSummary
        id={`${props.label}-panel-header`}
        expandIcon={<ExpandMore />}
      >
        <Typography
          variant={"accordionSummary"}
          color={markedRed ? "error" : "#222"}
        >
          {markedRed ? `*${props.label}*` : props.label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SelectBox
          label="Datenspediteur:"
          enumerator={datenspediteurEnumerator}
          selection={datenspediteurEnumerator.itemForValue(
            tkDetailsInformation.detail?.kommPartnerName ?? ""
          )}
          id={`${props.label}-ds`}
          getSelectedValue={(value) => {
            let newValue = value?.id.toString();
            changeTkDetailsInformation({
              detail: {
                ...tkDetailsInformation.detail,
                kommPartnerName: newValue,
              },
            });
            updateAutomatCredentials({ kommPartnerKey: newValue });
          }}
          reset={props.resetValues}
        />
        {tkDetailsInformation.isArtikelStammdatenDetail && (
          <TextValue
            label="Variante Artikelstamm:"
            value={tkDetailsInformation.detail?.artikelstammVariante ?? ""}
            id="artikelstamm-variante"
            onChange={(event) => {
              let newValue = event.target.value;
              changeTkDetailsInformation({
                detail: {
                  ...tkDetailsInformation.detail,
                  artikelstammVariante: newValue,
                },
              });
              updateAutomatCredentials({ artikelstammVariante: newValue });
            }}
          />
        )}
        <TextValue
          label={
            tkDetailsInformation.isArtikelStammdatenDetail
              ? "Verzeichnis EAN Stammdaten:"
              : "Verzeichnis Rohdaten:"
          }
          value={tkDetailsInformation.detail?.ftpDirectory ?? ""}
          id={`${props.label}-path`}
          onChange={(event) => {
            let newValue = event.target.value;
            changeTkDetailsInformation({
              detail: {
                ...tkDetailsInformation.detail,
                ftpDirectory: newValue,
              },
            });
            updateAutomatCredentials({ ftpDirectory: newValue });
          }}
        />
        <TextValue
          label="Automat User:"
          value={tkDetailsInformation.credentialsInfo?.username ?? ""}
          id={`${props.label}-user`}
          type={props.visibilitySettings.hideAutomatUser ? "password" : "text"}
          onChange={(event) => {
            let newValue = event.target.value;
            changeTkDetailsInformation({
              credentialsInfo: {
                ...tkDetailsInformation.credentialsInfo,
                username: newValue,
              },
            });
            updateAutomatCredentials({ username: newValue });
          }}
          TextFieldProps={{
            inputProps: {
              readOnly: props.visibilitySettings.hideAutomatPassword,
            },
          }}
        />
        <TextValue
          label="Automat Password:"
          value={tkDetailsInformation.credentialsInfo?.password ?? ""}
          id={`${props.label}-pwd`}
          type={
            props.visibilitySettings.hideAutomatPassword ? "password" : "text"
          }
          onChange={(event) => {
            let newValue = event.target.value;
            changeTkDetailsInformation({
              credentialsInfo: {
                ...tkDetailsInformation.credentialsInfo,
                password: newValue,
              },
            });
            updateAutomatCredentials({ password: newValue });
          }}
          TextFieldProps={{
            inputProps: {
              readOnly: props.visibilitySettings.hideAutomatPassword,
            },
          }}
        />

        <Stack className="stack flexRow" columnGap={2}>
          <Tooltip title="Alle Einträge zur ausgewählten TK-Art löschen">
            <IconButton
              disabled={getKommTypeUpdateElement() !== undefined}
              aria-label="delete"
              onClick={() => {
                dispatchTkDetailsInformation({ type: DataControl.CLEAR });
                updateAutomatCredentials({ delete: true });
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default TKPartnerDetailCard;
