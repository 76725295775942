import { AxiosResponse } from "axios";
import AutomatInvalidFields from "../interface/AutomatInvalidFields";

const AutomatInvalidFieldsDTO = {
  convertResponseToAutomatInvalidFieldsResult: (
    response: AxiosResponse
  ): Array<AutomatInvalidFields> => {
    if (response.data) {
      return [...response.data.Row];
    }
    return [];
  },
};
export default AutomatInvalidFieldsDTO;
