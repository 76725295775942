import DsmdStatus from "service/data-service/automate-controller/interface/DsmdStatus";
import DsmdService from "service/ruecknehmer-service/info-controller/Dsmd.service";
import AutomatAction, { AutomatActionCallback, AutomatActionPayload } from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { registerAutomatDataStoreActions } from "../use-automat-datastore";

export const FETCH_AUTOMAT_DSMD_STATUS = 'FETCH_AUTOMAT_DSMD_STATUS';

const configureDsmdStatusStore = () => {
    const fetchLatestStatus: AutomatAction =
    {
        identifier: FETCH_AUTOMAT_DSMD_STATUS,
        action: (
            currentState: AutomatDataRecord,
            payload: AutomatActionPayload,
            callback: AutomatActionCallback,
            callbackOnFail?: Function) => {

            DsmdService.getDsmdStatus(
                payload.automatKey,
                (dsmdStatusDTO: DsmdStatus) => callback(
                    {
                        dsmdStatus: dsmdStatusDTO,
                    } as AutomatDataRecord),
                callbackOnFail as Function);
        }
    };
    registerAutomatDataStoreActions([fetchLatestStatus]);
};

export default configureDsmdStatusStore;
