import { Grid } from "@mui/material";
import useSnackbarGenerator from "App/hook/use-snackbars";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { FETCH_AUTOMAT_LOGFILEFEHLER } from "global/hook/datastore/automat/automat-logfilefehler-store";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useCallback, useEffect, useState } from "react";
import Logfilefehler from "service/logfile-service/interface/Logfilefehler";
import LogfilefehlerPanelColumnDefs from "./grid-column-defs";
import LogilefehlerPanelController from "./LogfilefehlerpanelController";


interface LogfilefehlerPanelProps {
  automatKey: string;
}

const LogfilefehlerPanel = (props: LogfilefehlerPanelProps) => {

  const [records, setRecords] = useState<Array<Logfilefehler>>();
  const [automatData, dispatchAutomatDataAction] = useAutomatData(props.automatKey);
  const [updating, setUpdating] = useState<Array<boolean>>([]);
  const snackbarGenerator = useSnackbarGenerator();
  const windowViewport = useWindowViewport(0, 212)

  const setUpdatingFlags = useCallback(
    (index: number, value: boolean) => {
      setUpdating((prevFlags) => {
        const newFlags = [...prevFlags];
        newFlags[index] = value;
        return newFlags
      })
    }, [setUpdating]
  );

  useEffect(() => {
    if (records) {
      const updatingFlags: Array<boolean> = records.map((e) => false)
      setUpdating(updatingFlags)
    }
  }, [records]);

  useEffect(() => {
    if (automatData.logfilefehlerRecords) {
      setRecords(LogilefehlerPanelController.transmuteToGridData(
        automatData.logfilefehlerRecords,
        updating,
        setUpdatingFlags,
        dispatchAutomatDataAction,
        snackbarGenerator
      ));
    } else {
      dispatchAutomatDataAction(FETCH_AUTOMAT_LOGFILEFEHLER);
    }
  }, [automatData, dispatchAutomatDataAction]);

  return (
    <Grid container spacing={5} width={"auto"}>
      <Grid item xs={12}>
        <DataGrid
          height={windowViewport.height}
          columnDefs={LogfilefehlerPanelColumnDefs}
          rowsDataDef={{ data: records }}
        />
      </Grid>
    </Grid>
  );
};

export default LogfilefehlerPanel;
