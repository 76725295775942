import { ModalMessageDialogProps } from "global/components/UI/MessageDialogs/ModalMessageDialog";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalController, { DialogController } from "./interface/ModalController";

type ModalMessageDialogSetter = Dispatch<SetStateAction<ModalMessageDialogProps | null>>

const nop: Function = () => { };
let modals: Array<ModalMessageDialogProps> = [];
let currentModal: ModalMessageDialogProps | null;
let modalListener: ModalMessageDialogSetter | null

let dialog: ModalMessageDialogProps | null;
let dialogListener: ModalMessageDialogSetter | null

function broadcastModalProps(modalProps: ModalMessageDialogProps | null) {
    currentModal = modalProps
    if (modalListener) {
        modalListener(currentModal)
    }
}

function broadcastDialogProps(modalProps: ModalMessageDialogProps | null) {
    dialog = modalProps
    if (dialogListener) {
        dialogListener(dialog)
    }
}

export const useModalDialog = (dialogName = 'global'): ModalMessageDialogProps | null => {

    const [modal, setModal] = useState<ModalMessageDialogProps | null>(null)

    useEffect(() => {
        if ((dialogName === 'global' && modalListener)
            || (dialogName !== 'global' && dialogListener)) {
            throw Error('Only one modal listener allowed')
        }
        if (dialogName === 'global') {
            modalListener = setModal
        } else {
            dialogListener = setModal
        }

        return () => {
            if (dialogName === 'global') {
                modalListener = null
            } else {
                dialogListener = null
            }
        };
    }, [setModal]);

    return modal;
};

export const useDialogController = (): DialogController => {

    const showDialog = (modalProps: ModalMessageDialogProps) => {
        const aModal: ModalMessageDialogProps = {
            ...modalProps,
            onOkClick: modalProps.onOkClick ? () => {
                closeModalFN()
                modalProps.onOkClick!()
            } : modalProps.onOkClick,
            onAbortClick: modalProps.onAbortClick ? () => {
                closeModalFN()
                modalProps.onAbortClick!()
            } : modalProps.onAbortClick,
        }
        broadcastDialogProps(aModal)
    }

    const closeModalFN: Function = () => {
        broadcastDialogProps(null)
    }

    const disableOkActionFN = (value: boolean) => {
        if (dialog && dialog.onOkClick) {
            broadcastDialogProps({
                ...dialog,
                okActionDisabled: value
            })
        }
    }

    return {
        showDialog: showDialog,
        closeDialog: closeModalFN,
        disableOkAction: disableOkActionFN,
    } as DialogController;
};

const useModalController = (): ModalController => {

    const showModal = (modalProps: ModalMessageDialogProps) => {
        const aModal: ModalMessageDialogProps = {
            ...modalProps,
            onOkClick: modalProps.onOkClick ? () => {
                closeModalFN()
                modalProps.onOkClick!()
            } : modalProps.onOkClick,
            onAbortClick: modalProps.onAbortClick ? () => {
                closeModalFN()
                modalProps.onAbortClick!()
            } : modalProps.onAbortClick,
        }
        if (currentModal && modalProps.nonInteractiveMessage) {
            broadcastModalProps({
                ...currentModal,
                nonInteractiveMessage: modalProps.nonInteractiveMessage
            })
        } else if (currentModal) {
            modals.push(aModal)
        } else {
            broadcastModalProps(aModal)
        }
    }

    const closeModalFN: Function = () => {
        if (currentModal && currentModal.nonInteractiveMessage && currentModal.content) {
            broadcastModalProps({
                ...currentModal,
                nonInteractiveMessage: undefined
            })
        } else {
            const nextModal = modals.shift() ?? null
            broadcastModalProps(nextModal)
        }
    }

    return {
        showMessageDialog: (message: string, okAction?: Function, title?: string, cancelAction?: Function) => {
            showModal({
                message: message,
                title: title,
                onOkClick: okAction ?? nop,
                onAbortClick: cancelAction
            })
        },
        showLoadingDialog: (message: string, title?: string) => {
            showModal({
                message: '',
                nonInteractiveMessage: message,
                title: title,
            })
        },
        showError: (message: string, error: Error) => {
            showModal({ message: message, onOkClick: nop })
            console.log(error);
        },
        closeModal: closeModalFN,
    } as ModalController;
};

export default useModalController;
