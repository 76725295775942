import { TableRowProps } from "../../TableContainer/TableRow";

export const StatusTableHeader: Array<TableRowProps> = [
  {
    row: [
      { value: "MM", props: { align: "center" } },
      { value: "Art.", props: { align: "center" } },
      { value: "Quit", props: { align: "center" } },
      { value: "Log.", props: { align: "center" } },
      { value: "DPG", props: { align: "center" } },
      { value: "RN.", props: { align: "center" } },
    ],
  },
];
