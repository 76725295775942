/**
 * Use this in a useReducer Hook as the reducer function.
 * Type the useReducer an Interface that extends the Reducer interface.
 *
 * @param state
 * @param changeStateFunction
 * @returns Sets new state in reducer
 *
 * @example
 * interface myReducer extends Reducer<string, (value: string)=>string)> {}
 *
 * // In component
 * const [state, dispatch] = useReducer<myReducer>(genericReduder, "defaultString");
 *
 *
 * // And call the dispatch as follows
 * dispatch(()=>"My new String");
 *
 * // or
 * dispatch((oldState)=>{return oldState + " extend old State" })
 *
 * // To change the state
 */

export function genericReducer<T>(
  state: T,
  changeStateFunction: (state: T) => T
) {
  return changeStateFunction(state);
}
