import { ErrorTypesContainer } from "service/data-service/error-typ-controller/container/errorTypeContainer";
import ErrorTypeService from "service/data-service/error-typ-controller/ErrorTypeController.service";

export function loadErrorTypeContainer() {
  ErrorTypeService.getErrorTypes((errorTypes) => {
    errorTypes.forEach((errorType) => {
      ErrorTypesContainer.push(errorType);
    });
  });
}
