import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import CockpitInformationDTO from "./interface/CockpitInformation";
import CockpitVisibilitiesDTO from "./interface/CockpitVisibilities";
import EpgCardInformationDTO from "./interface/EpgCardInformation";
import {
  AxiosResponseToCockpitInformationDTOMapper,
  AxiosResponseToCockpitVisibilitiesDTOMapper,
  AxiosResponseToEpgCardInformationDTO,
} from "./mapping/CockpitMappers";

const CockpitInformationService = {
  retrieveCockpitInformation: (
    firstCallbackOnResponse: (
      cockpitInformation: CockpitInformationDTO,
      epgCardInformation: EpgCardInformationDTO
    ) => void,
    secondCallbackOnResponse: (visibilities: CockpitVisibilitiesDTO) => void,
    callbackOnFail: (error: any) => void
  ) => {
    axiosCalls
      .sendGet(Config.dataServiceUrl + "cockpit/getAll")
      .then((response) => {
        const cockpitInformationDTO =
          AxiosResponseToCockpitInformationDTOMapper.map(response);
        const epgCardInformationDTO =
          AxiosResponseToEpgCardInformationDTO.map(response);
        const cockpitVisibilitiesDTO =
          AxiosResponseToCockpitVisibilitiesDTOMapper.map(response);
        firstCallbackOnResponse(cockpitInformationDTO, epgCardInformationDTO);
        secondCallbackOnResponse(cockpitVisibilitiesDTO);
      })
      .catch((error) => {
        callbackOnFail(error);
      });
  },
};

export default CockpitInformationService;
