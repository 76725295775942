import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import AccessDTO from "./dto/AccessDTO";
import Access from "./interface/Access";

const AccessService = {
  getAccess: (
    callbackOnSuccess: (accessObject: Access) => void,
    callbackOnFail?: (error: any) => void
  ) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}ui/access/getAccess`)
      .then((response) => {
        callbackOnSuccess(AccessDTO.convertAccessResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default AccessService;
