export interface KommTypeVisibility {
  readonly kommType: string;
  readonly hideAutomatUser: boolean;
  readonly hideAutomatPassword: boolean;
}

export function generateDefaultKommTypeVisibility(): KommTypeVisibility {
  return { kommType: "", hideAutomatUser: false, hideAutomatPassword: false };
}

export default interface Access {
  readonly rollenTyp: string;
  readonly userName: string;
  readonly nationaleId: string;
  readonly ath: string | null;
  readonly auspraegung: string;
  readonly showAutomatenTabBtnAnsprechpartnerDaten: boolean;
  readonly showAutomatenTabBtnAutomatSpeichern: boolean;
  readonly showAutomatenTabBtnGlnWechsel: boolean;
  readonly showAutomatenTabBtnNotizAnlegen: boolean;
  readonly showAutomatenTabBtnNotizPopupAenderungen: boolean;
  readonly showAutomatenTabCbNotizAthLeseberechtigung: boolean;
  readonly showEntryAnwenderNachrichten: boolean;
  readonly showEntryVerfuegbarkeit: boolean;
  readonly showMenuAutomaten: boolean;
  readonly showEntryAutomatensuche: boolean;
  readonly showEntryKaufanfragen: boolean;
  readonly showMenuBerichte: boolean;
  readonly showEntryAnsichtFehlerhafteMm: boolean;
  readonly showEntryAnsichtWechselNokAufOk: boolean;
  readonly showEntryErreichbarkeitenJeKommart: boolean;
  readonly showEntryZurBearbeitungAnstehend: boolean;
  readonly showEntryFlatfile: boolean;
  readonly showEntryDsmdFehlerhaft: boolean;
  readonly showMenuDatenpflege: boolean;
  readonly showEntryRns: boolean;
  readonly showMenuNotizen: boolean;
  readonly showEntryNotizenSuche: boolean;
  readonly showEntryWiedervorlage: boolean;
  readonly showMenuRechnungsdatenAth: boolean;
  readonly showEntrySuchePositionen: boolean;
  readonly showEntryGlnOhneZuordnung: boolean;
  readonly showMenuHilfe: boolean;
  readonly showEntryBenutzerhandbuch: boolean;
  readonly showMenuAdministration: boolean;
  readonly showEntryAnlageNeuerUser: boolean;
  readonly showEntryAnlageRechnungsempfaenger: boolean;
  readonly showEntryStammdatenabholungClearer: boolean;
  readonly showEntryRechnungsdatenAth: boolean;
  readonly showEntryIpBereiche: boolean;
  readonly showEntryIpWhiteList: boolean;
  readonly tkpartnerMengenmeldung: KommTypeVisibility;
  readonly tkpartnerLogfile: KommTypeVisibility;
  readonly tkpartnerDpgBtb: KommTypeVisibility;
  readonly tkpartnerEdkBtb: KommTypeVisibility;
  readonly tkpartnerArtikelstamm: KommTypeVisibility;
}

export function generateDefaultAccess(): Access {
  return {
    rollenTyp: "EPG",
    userName: "T_epg_1",
    nationaleId: "1234567",
    auspraegung: "EPG",
    ath: null,
    showMenuAutomaten: false,
    showEntryAutomatensuche: false,
    showEntryKaufanfragen: false,
    showMenuBerichte: false,
    showEntryAnsichtFehlerhafteMm: false,
    showEntryAnsichtWechselNokAufOk: false,
    showEntryErreichbarkeitenJeKommart: false,
    showEntryZurBearbeitungAnstehend: false,
    showEntryFlatfile: false,
    showEntryDsmdFehlerhaft: false,
    showMenuDatenpflege: false,
    showEntryRns: false,
    showMenuNotizen: false,
    showEntryNotizenSuche: false,
    showEntryWiedervorlage: false,
    showMenuRechnungsdatenAth: false,
    showEntrySuchePositionen: false,
    showEntryGlnOhneZuordnung: false,
    showMenuHilfe: false,
    showEntryBenutzerhandbuch: false,
    showMenuAdministration: false,
    showEntryAnlageNeuerUser: false,
    showEntryAnlageRechnungsempfaenger: false,
    showEntryStammdatenabholungClearer: false,
    showEntryRechnungsdatenAth: false,
    showEntryIpBereiche: false,
    showEntryIpWhiteList: false,
    showAutomatenTabBtnAnsprechpartnerDaten: false,
    showAutomatenTabBtnAutomatSpeichern: false,
    showAutomatenTabBtnGlnWechsel: false,
    showAutomatenTabBtnNotizAnlegen: false,
    showAutomatenTabBtnNotizPopupAenderungen: false,
    showAutomatenTabCbNotizAthLeseberechtigung: false,
    showEntryAnwenderNachrichten: false,
    showEntryVerfuegbarkeit: false,
    tkpartnerMengenmeldung: {
      kommType: "",
      hideAutomatUser: false,
      hideAutomatPassword: false,
    },
    tkpartnerLogfile: {
      kommType: "",
      hideAutomatUser: false,
      hideAutomatPassword: false,
    },
    tkpartnerDpgBtb: {
      kommType: "",
      hideAutomatUser: false,
      hideAutomatPassword: false,
    },
    tkpartnerEdkBtb: {
      kommType: "",
      hideAutomatUser: false,
      hideAutomatPassword: false,
    },
    tkpartnerArtikelstamm: {
      kommType: "",
      hideAutomatUser: false,
      hideAutomatPassword: false,
    },
  };
}
