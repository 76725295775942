import { Card, CardHeader, colors, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CockpitListItemText } from "../ListItemText/ListItemText";

interface props {
  nachricht: string;
  nachrichtVisible: boolean;
}

const Anwendernachrichten: React.FC<props> = (props: props) => {
  // Variablen und Hooks
  const [nachricht, setNachricht] = useState(String);
  const [nachrichtVisible, setNachrichtVisible] = useState(Boolean);

  useEffect(() => {
    setNachricht(props.nachricht);
    setNachrichtVisible(
      props.nachrichtVisible !== undefined ? props.nachrichtVisible : false
    );
  }, [props.nachricht, props.nachrichtVisible]);

  // React-Tree
  return (
    <Card hidden={!nachrichtVisible}>
      <CardHeader title="Anwendernachrichten:" />
      <List disablePadding>
        <ListItem>
          <CockpitListItemText
            primary={`${nachricht}`}
            id="nachricht"
            sx={{ color: colors.red[700] }}
          />
        </ListItem>
      </List>
    </Card>
  );
};

export default Anwendernachrichten;
