import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import AnwendernachrichtDTO from "./dto/AnwendernachrichtDTO";
import Anwendernachricht from "./interface/Anwendernachricht";

const AnwendernachrichtService = {
  search: (
    activeonly: boolean,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}anw/suche/${activeonly}`)
      .then((response) => {
        callbackOnSuccess(
          AnwendernachrichtDTO.convertAnwendernachrichtSearchResponse(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  update: (
    anwendernachricht: Anwendernachricht,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendJsonDataPost(`${Config.dataServiceUrl}anw/update`, anwendernachricht)
      .then((response) => {
        callbackOnSuccess(
          AnwendernachrichtDTO.convertAnwendernachrichtResponse(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default AnwendernachrichtService;
