import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";
import AnwendernachrichtenUtil from "./AnwendernachrichtenUtil";

const AnwendernachrichtenColumnDefs: Array<ColDef> = [
  {
    headerName: "Anwendernachricht",
    field: "nachricht",
    minWidth: 600,
  },
  {
    headerName: "Anzeige von",
    field: "anzeigeDatumVon",
    minWidth: 130,
    maxWidth: 130,
    valueFormatter: (params) => DateUtils.optionalTimeArrayToGermanString(params.value)
  },
  {
    headerName: "Uhrzeit",
    field: "anzeigeZeitVon",
    minWidth: 100,
    maxWidth: 100,
    valueFormatter: (params) => AnwendernachrichtenUtil.buildHoursIntervalsValue(params.value)
  },
  {
    headerName: "Anzeige bis",
    field: "anzeigeDatumBis",
    minWidth: 130,
    maxWidth: 130,
    valueFormatter: (params) => DateUtils.optionalTimeArrayToGermanString(params.value)
  },
  {
    headerName: "Uhrzeit",
    field: "anzeigeZeitBis",
    minWidth: 100,
    maxWidth: 100,
    valueFormatter: (params) => AnwendernachrichtenUtil.buildHoursIntervalsValue(params.value)
  },
];

export default AnwendernachrichtenColumnDefs;
