import TextValue from "global/components/UI/TextValue/TextValue";
import { useState } from "react";

import "../kaufanfragen.scss";

export interface KaufanfragenGBHGrundDialogContentProps {
    onReasonTextChange: Function;
}

const KaufanfragenGBHGrundDialogContent: React.FC<KaufanfragenGBHGrundDialogContentProps> = (props: KaufanfragenGBHGrundDialogContentProps) => {

    const [reason, setReason] = useState<string>('')
    const onTextChangeHandler = (value: string) => {
        props.onReasonTextChange(value)
        setReason(value)
    }
    return (
        <TextValue
            label="Begründung:"
            value={reason}
            id="grund-text"
            multiline
            onChange={(event) => onTextChangeHandler(event.target.value)}
            TypographyProps={{ className: "labelForBegrundungDialog" }}
        />
    );
}

export default KaufanfragenGBHGrundDialogContent;
