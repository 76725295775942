import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import TextValue from "global/components/UI/TextValue/TextValue";
import { FETCH_ARTICLE_SEARCH } from "global/hook/datastore/automat/automat-artikelsuche-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import useModalController from "global/hook/modal/use-modals";
import DateUtils from "global/util/DateUtils";
import { useEffect, useState } from "react";
import ArtikleSearchResult from "service/artikel-service/interface/ArtikleSearchResult";
import ArtikelSucheController, { ArtikelSucheDisplayInfo } from "./ArtikelSucheController";

interface ArtikelsuchePanelProps {
  automatKey: string;
}

const ArtikelsuchePanel = (props: ArtikelsuchePanelProps) => {
  const [automatData, dispatch] = useAutomatData(props.automatKey);
  const [gtin, setGtin] = useState<string>(`${automatData.artikleSearchResult?.ruecknahmeInfo?.gtin ?? automatData.artikleSearchResult?.artikel?.artikelNummer ?? ''}`);
  const [displayInfo, setDisplayInfo] = useState<ArtikelSucheDisplayInfo>({});
  const [oldestDateString, setOldestDateString] = useState<string | null>(null);
  const [showModals, setShowModals] = useState(false)

  const modals = useModalController();

  useEffect(() => {
    const searchResult: ArtikleSearchResult = automatData.artikleSearchResult ?? {} as ArtikleSearchResult;
    setDisplayInfo(ArtikelSucheController.transmuteSearchResultToDisplayInfo(searchResult, showModals ? modals : undefined))
    setShowModals(false)
  }, [automatData.artikleSearchResult]);

  useEffect(() => {
    setOldestDateString(
      DateUtils.oldestDateString(
        displayInfo.letzteRuecknahmeAutomat,
        displayInfo.letzteRuecknhameArtikelAllg
      )
    );
  }, [displayInfo]);

  const loadArtikleInfo = () => {
    const gtinNumber = parseInt(gtin ?? '');

    if (isNaN(gtinNumber)) {
      modals.showMessageDialog(
        ArtikelSucheController.getAbrechnungsinformationenErrorText(
          "INVALID_GTIN",
          gtinNumber,
          automatData.automatenblattInformation.ssidAktiv)
      )
    } else {
      setShowModals(true)
      dispatch(FETCH_ARTICLE_SEARCH, { artikelGtin: gtinNumber } as AutomatActionPayload)
    }
  };

  return (
    <Grid container spacing={2} width={"auto"}>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row">
          <TextValue
            label="Bitte GTIN eingeben:"
            value={gtin}
            id={"notiz-text"}
            onChange={(event) => {
              setGtin(event.target.value);
            }}
          />

          <Button
            variant="contained"
            onClick={loadArtikleInfo}
          >
            Suchen
          </Button>
        </Stack>
      </Grid>

      <Grid container item xs={12} className="artikelSucheResponseCard">
        <Grid item xs={12}>
          <Typography variant={"body1"} fontWeight={600}>DPG Artikelinformationen</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography className="uiElement__Text" mt={2}>
            Artikelbezeichnung
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className="uiElement__Text" mt={2}>
            {" "}
            {displayInfo.artikelLangText}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography className="uiElement__Text" mt={2}>
            Abrechnungsfähig ab:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className="uiElement__Text" mt={2}>
            {" "}
            {displayInfo.gueltigAb}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography className="uiElement__Text" mt={2}>
            Abrechnungsfähig bis:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className="uiElement__Text" mt={2}>
            {" "}
            {displayInfo.gueltigBis}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} className="artikelSucheResponseCard">
        <Grid item xs={12}>
          <Box className="uiElement">
            <Typography variant={"body1"} fontWeight={600}>Abrechnungsinformationen </Typography>

            {oldestDateString && (
              <Typography variant={"body1"} ml={0.5} mr={0.5}>
                {" "}&#8212;{" "}
              </Typography>
            )}
            {oldestDateString && (
              <Typography variant={"body1"} fontWeight={600}>
                Eine Aussage über eine{" "}
                <span style={{ textDecoration: "underline" }}>
                  Rücknahme dieses Artikels auf diesem Automaten
                </span>{" "}
                ist über den {oldestDateString} hinaus nicht möglich!{" "}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography className="uiElement__Text" mt={2}>
            Letztes Abrechnungsdatum des Artikels am ausgewählten Automaten:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className="uiElement__Text" mt={2}>
            {" "}
            {displayInfo.letzteRuecknahmeArtikelOfAutomat}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography className="uiElement__Text" mt={2}>
            Letztes Abrechnungsdatum des ausgewählten Automaten:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className="uiElement__Text" mt={2}>
            {" "}
            {displayInfo.letzteRuecknahmeAutomat}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography className="uiElement__Text" mt={2}>
            Letztes Abrechnungsdatum des Artikels gesamt EDEKA:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className="uiElement__Text" mt={2}>
            {" "}
            {displayInfo.letzteRuecknhameArtikelAllg}{" "}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArtikelsuchePanel;
