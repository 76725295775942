import axios from "axios";
import { KeyCloakService } from "./keycloak.service";

const HttpMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PREFLIGHT: "PREFLIGHT",
};

const _axios = axios.create();

export const abortController = new AbortController();

const configure = () => {
  _axios.defaults.signal = abortController.signal;
  _axios.interceptors.request.use(
    async function (config) {
      await KeyCloakService.updateToken().catch(catchTokenUpdateError);
      if (KeyCloakService.isLoggedIn()) {
        config.headers!.Authorization = `Bearer ${KeyCloakService.getToken()}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;

function catchTokenUpdateError(error: any) {
  console.log(error);
  abortController.abort();
  KeyCloakService.doLogin();
}
