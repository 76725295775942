import {
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./tableContainer.scss";
import Row, { TableRowProps } from "./TableRow";

export interface TableProps {
  label?: string;
  head: Array<TableRowProps>;
  rows: Array<TableRowProps>;
  id: string;
}

const BasicTable: React.FC<TableProps> = (props: TableProps) => {
  return (
    <Stack className="tableContainer flexRow paddingBottom15" columnGap={2}>
      {props.label ? <InputLabel shrink>{props.label}</InputLabel> : ""}
      <TableContainer component={Paper}>
        <Table aria-label="simple table" id={props.id}>
          <TableHead>
            {props.head.map((element: TableRowProps, index: number) => (
              <Row
                key={index}
                row={element.row}
                props={element.props}
                isHead={true}
              ></Row>
            ))}
          </TableHead>
          <TableBody>
            {props.rows.length > 0 ? (
              props.rows.map((element: TableRowProps, index: number) => (
                <Row key={index} row={element.row} props={element.props}></Row>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={props.head[0].row.length}
                  className="keineDatenCell"
                >
                  {"keine Daten vorhanden"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default BasicTable;
