import { AxiosResponse } from "axios";
import AutomatStatusDTO from "../dto/AutomatStatusDTO";

const AxiosResponseToAutomatStatusDTOMapper = {
  map: (response: AxiosResponse) => {
    let input = response.data;
    return AutomatStatusDTO.createDTO({
      row: input.Row,
    });
  },
};

export default AxiosResponseToAutomatStatusDTOMapper;
