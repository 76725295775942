import { KAUFANFRAGE_STATUS_SEARCH } from "global/hook/kaufanfragen/use-kaufanfragen-store";
import ModalController from "global/hook/modal/interface/ModalController";
import Kaufanfrage from "service/data-service/kaufanfragen-controller/interface/Kaufanfrage";
import KaufanfragenService from "service/data-service/kaufanfragen-controller/Kaufanfragen.Service";
import { KaufanfragenGridActionProps } from "../interface/Kaufanfragen";

const KaufanfragenUtil = {
    onServiceCallSuccess(
        message: string,
        props: KaufanfragenGridActionProps,
        refreshData = true
    ) {
        props.modals.closeModal()
        props.modals.showMessageDialog(
            message,
            refreshData ? () => { props.kaufanfragenDispatcher(KAUFANFRAGE_STATUS_SEARCH) } : undefined,
        )
    },
    onServiceCallFail(props: KaufanfragenGridActionProps,) {
        props.modals.closeModal()
    },
    isGridSelectionSuitable(
        props: KaufanfragenGridActionProps,
        selectionStatus?: string): boolean {
        if (props.gridSelection) {
            if (!selectionStatus || props.gridSelection.STATUS === selectionStatus) {
                return true
            }
            props.modals.showMessageDialog(`Die ausgewählte Kaufanfrage hat nicht den Status "${selectionStatus}".`)
        } else {
            props.modals.showMessageDialog('Bitte wählen Sie mindestens eine Kaufanfrage aus.')
        }
        return false
    },
    updateKaufanfrageSelection(
        props: KaufanfragenGridActionProps,
        newStatus: string,
        extras: Kaufanfrage | undefined,
        successMessage: string,
    ) {
        KaufanfragenService.update(
            {
                ...props.gridSelection!,
                ...(extras ?? {}),
                STATUS: newStatus,
            },
            (record: Kaufanfrage) => {
                KaufanfragenUtil.onServiceCallSuccess(successMessage, props)
            },
            (err: Error) => {
                KaufanfragenUtil.onServiceCallFail(props)
            }
        )
    },
    showProgressIndicatorDialog(modals: ModalController, message: string) {
        modals.showLoadingDialog(message)
    }
}

export default KaufanfragenUtil
