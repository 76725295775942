import { AxiosResponse } from "axios";
import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import AutomatCheckDTO from "./dto/AutomatCheckDTO";


export enum AUTOMAT_CHECK_ACTION {
  'check', 'result', 'abholung'
}

const AutomatCheckService = {
  check: (
    checkAction: AUTOMAT_CHECK_ACTION,
    automateKey: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(
        Config.dataServiceUrl +
        "check/automate/" +
        AUTOMAT_CHECK_ACTION[checkAction] +
        "/" +
        automateKey
      )
      .then((response: AxiosResponse) => {
        callbackOnSuccess(
          AutomatCheckDTO.convertResponseToAutomatCheckDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default AutomatCheckService;
