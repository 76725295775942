import { useEffect, useState } from "react";

export interface WindowViewport {
    readonly width: number
    readonly height: number
}

const MIN_WINDOW_HEIGHT = 890

export const useWindowViewport = (widthPadding = 0, heightPadding = 0): WindowViewport => {
    const [viewport, setViewport] = useState<WindowViewport>({ width: 0, height: 0 })

    const calculateWindowViewport = (): WindowViewport => {
        let width = window.innerWidth
        let height = window.innerHeight
        if (window.visualViewport) {
            width = window.visualViewport.width
            height = window.visualViewport.height
        }

        return {
            width: width - widthPadding,
            height: (MIN_WINDOW_HEIGHT > height ? MIN_WINDOW_HEIGHT : height) - heightPadding
        }
    }

    useEffect(() => {
        setViewport(calculateWindowViewport());
        function handleResize() {
            setViewport(calculateWindowViewport());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window.visualViewport])

    return viewport;
};
