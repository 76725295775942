import { Button, Grid } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import { FETCH_AUTOMAT_CHECK } from "global/hook/datastore/automat/automat-check-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import useModalController, { useDialogController, useModalDialog } from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useEffect, useState } from "react";
import { AUTOMAT_CHECK_ACTION } from "service/data-service/automate-controller/AutomatCheck.service";
import { CheckAutomat } from "service/data-service/automate-controller/interface/CheckAutomat";
import "./abruf.scss";
import AbrufPanelController, { ABRUF_REMOTE_OPERATION_TYPE } from "./AbrufPanelController";
import AbrufPanelColumnDefs from "./grid-column-defs";

interface AbrufPanelProps {
  automatKey: string;
}

const AbrufPanel = (props: AbrufPanelProps) => {
  const modalGenerator = useModalController();
  const dialog = useModalDialog('abruf')
  const dialogController = useDialogController()

  const [automatData, dispatchCheckAction] = useAutomatData(props.automatKey);
  const [enableAbruf, setEnableAbruf] = useState(false);
  const [statusRecords, setStatusRecords] = useState<Array<CheckAutomat>>();
  const [isFetchingData, setIsFetchingData] = useState(false)

  const loadCheckAutomatRecords = (action: AUTOMAT_CHECK_ACTION): void => {
    dispatchCheckAction(
      FETCH_AUTOMAT_CHECK,
      { checkAction: action } as AutomatActionPayload,
      undefined,
      setIsFetchingData)
  }

  useEffect(() => {
    if (!automatData.checkupResponse) {
      loadCheckAutomatRecords(AUTOMAT_CHECK_ACTION.result)
    } else {
      setStatusRecords(AbrufPanelController.transmuteToGridData(automatData.checkupResponse!));
    }
  }, [automatData.checkupResponse]);

  useEffect(() => {
    const records = statusRecords ?? []
    setEnableAbruf(records.length > 0 && records[0].checkHasFiles === 'true');
  }, [statusRecords]);

  const windowViewport = useWindowViewport(0, 283)

  return (
    <>
      <Grid container spacing={5} width={"auto"}>
        <Grid item xs={12}>
          <Button className="abrufButton" size="small" variant="contained"
            onClick={loadCheckAutomatRecords.bind(null, AUTOMAT_CHECK_ACTION.check)}
          >
            Prüfung
          </Button>
          <Button className="abrufButton" size="small" variant="contained" disabled={!enableAbruf}
            onClick={loadCheckAutomatRecords.bind(null, AUTOMAT_CHECK_ACTION.abholung)}
          >
            Abruf starten
          </Button>
          <Button className="abrufButton" size="small" variant="contained"
            onClick={loadCheckAutomatRecords.bind(null, AUTOMAT_CHECK_ACTION.result)}
          >
            Aktualisieren
          </Button>
          <Button
            className="abrufButton"
            size="small"
            variant="contained"
            onClick={() => {
              AbrufPanelController.executeRemoteOperation(
                ABRUF_REMOTE_OPERATION_TYPE.ping,
                automatData.automatenblattInformation.tkDaten.ipV4Lan ?? '',
                modalGenerator,
                dialogController
              )
            }}
          >
            Ping
          </Button>
          <Button
            className="abrufButton"
            size="small"
            variant="contained"
            onClick={() => {
              AbrufPanelController.executeRemoteOperation(
                ABRUF_REMOTE_OPERATION_TYPE.traceroute,
                automatData.automatenblattInformation.tkDaten.ipV4Lan ?? '',
                modalGenerator,
                dialogController
              )
            }}
          >
            Traceroute
          </Button>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={AbrufPanelColumnDefs}
            rowsDataDef={{
              data: statusRecords,
              isFetchingData: isFetchingData,
            }}
          />
        </Grid>
      </Grid>
      {dialog && <ModalMessageDialog {...dialog} />}
    </>
  );
};

export default AbrufPanel;
