import { Card, CardHeader, List } from "@mui/material";
import { ColoredSmallListItemText } from "page/cockpit/components/ListItemText/ListItemText";
import { ColoredSmallListItemTypography } from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { SlimListItemButton } from "../ListItemButton/ListItemButton";

interface props {
  alsLoginDritterAnzahl: string;
  alsLoginDritterExists: boolean;
  erreichbarkeitIpBereicheFailed: boolean;
  erreichbarkeitIpBereicheFailedAnzahl: number;
  sapRdPossesWithoutEpcomRegions: boolean;
  sapRdPossesWithoutEpcomRegionsAnzahl: string;
  archivTooManyFiles: boolean;
  archivTooManyFilesThreshold: string;
  pingInvalidAutomatenAnzahl: number;
  archivAuslastungExceeded: boolean;
  jobsOk: boolean;
  epgCardVisible: boolean;
}

const EpgMonitoring: React.FC<props> = (props: props) => {
  // Variablen und Hooks
  const [alsLoginDritterAnzahl, setAlsLoginDritterAnzahl] = useState(String);
  const [alsLoginDritterExists, setAlsLoginDritterExists] = useState(Boolean);

  const [erreichbarkeitIpBereicheFailed, setErreichbarkeitIpBereicheFailed] =
    useState(Boolean);
  const [
    erreichbarkeitIpBereicheFailedAnzahl,
    setErreichbarkeitIpBereicheFailedAnzahl,
  ] = useState(Number);

  const [sapRdPossesWithoutEpcomRegions, setSapRdPossesWithoutEpcomRegions] =
    useState(Boolean);
  const [
    sapRdPossesWithoutEpcomRegionsAnzahl,
    setSapRdPossesWithoutEpcomRegionsAnzahl,
  ] = useState(String);

  const [archivTooManyFiles, setArchivTooManyFiles] = useState(Boolean);
  const [archivTooManyFilesThreshold, setArchivTooManyFilesThreshold] =
    useState(String);

  const [pingInvalidAutomatenAnzahl, setPingInvalidAutomatenAnzahl] =
    useState(Number);

  const [archivAuslastungExceeded, setArchivAuslastungExceeded] =
    useState(Boolean);

  const [jobsOk, setJobsOk] = useState(Boolean);

  const [epgCardVisible, setEpgCardVisible] = useState(Boolean);

  useEffect(() => {
    setAlsLoginDritterAnzahl(props.alsLoginDritterAnzahl);
    setAlsLoginDritterExists(props.alsLoginDritterExists);
    setErreichbarkeitIpBereicheFailed(props.erreichbarkeitIpBereicheFailed);
    setErreichbarkeitIpBereicheFailedAnzahl(
      props.erreichbarkeitIpBereicheFailedAnzahl
    );
    setSapRdPossesWithoutEpcomRegions(props.sapRdPossesWithoutEpcomRegions);
    setSapRdPossesWithoutEpcomRegionsAnzahl(
      props.sapRdPossesWithoutEpcomRegionsAnzahl
    );
    setArchivTooManyFiles(props.archivTooManyFiles);
    setArchivTooManyFilesThreshold(props.archivTooManyFilesThreshold);
    setPingInvalidAutomatenAnzahl(props.pingInvalidAutomatenAnzahl);
    setArchivAuslastungExceeded(props.archivAuslastungExceeded);
    setJobsOk(props.jobsOk);
    setEpgCardVisible(
      props.epgCardVisible !== undefined ? props.epgCardVisible : false
    );
  }, [
    props.alsLoginDritterAnzahl,
    props.alsLoginDritterExists,
    props.erreichbarkeitIpBereicheFailed,
    props.erreichbarkeitIpBereicheFailedAnzahl,
    props.sapRdPossesWithoutEpcomRegions,
    props.sapRdPossesWithoutEpcomRegionsAnzahl,
    props.archivTooManyFiles,
    props.archivTooManyFilesThreshold,
    props.pingInvalidAutomatenAnzahl,
    props.archivAuslastungExceeded,
    props.jobsOk,
    props.epgCardVisible,
  ]);

  // React-Tree
  return (
    <Card hidden={!epgCardVisible}>
      <CardHeader title="EPG-Monitoring" />
      <List disablePadding>
        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="alsLoginDritterExists"
            primary={
              alsLoginDritterExists ? "Login Dritter: " : "Login Dritter"
            }
            colorChangeBool={!alsLoginDritterExists}
          />
          <ColoredSmallListItemTypography
            id="alsLoginDritterAnzahl"
            text={`${alsLoginDritterAnzahl}`}
            colorChangeBool={!alsLoginDritterExists}
            sx={{
              display: alsLoginDritterExists ? "inline" : "none",
            }}
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="erreichbarkeitIpBereicheFailed"
            primary={
              erreichbarkeitIpBereicheFailed
                ? "Erreichbarkeit IP-Bereiche:"
                : "Erreichbarkeit IP-Bereiche"
            }
            colorChangeBool={!erreichbarkeitIpBereicheFailed}
          />
          <ColoredSmallListItemTypography
            id="erreichbarkeitIpBereicheFailedAnzahl"
            text={`${erreichbarkeitIpBereicheFailedAnzahl}`}
            colorChangeBool={!erreichbarkeitIpBereicheFailed}
            sx={{
              display: erreichbarkeitIpBereicheFailed ? "inline" : "none",
            }}
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="sapRdPossesWithoutEpcomRegions"
            primary={
              sapRdPossesWithoutEpcomRegions
                ? "SAP-RD ohne Region:"
                : "SAP-RD ohne Region"
            }
            colorChangeBool={!sapRdPossesWithoutEpcomRegions}
          />
          <ColoredSmallListItemTypography
            id="sapRdPossesWithoutEpcomRegionsAnzahl"
            text={`${sapRdPossesWithoutEpcomRegionsAnzahl}`}
            colorChangeBool={!sapRdPossesWithoutEpcomRegions}
            sx={{
              display: sapRdPossesWithoutEpcomRegions ? "inline" : "none",
            }}
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            primary={`Automaten-Dateien im Archiv > ${archivTooManyFilesThreshold}`}
            colorChangeBool={!archivTooManyFiles}
            id="archivTooManyFiles"
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            id="pingInvalidAutomatenText"
            primary="Ping nicht aktiver Automaten: "
            colorChangeBool={pingInvalidAutomatenAnzahl <= 0}
          />
          <ColoredSmallListItemTypography
            id="pingInvalidAutomatenAnzahl"
            colorChangeBool={pingInvalidAutomatenAnzahl <= 0}
            text={`${pingInvalidAutomatenAnzahl}`}
          />
        </SlimListItemButton>

        <SlimListItemButton divider>
          <ColoredSmallListItemText
            primary={
              archivAuslastungExceeded
                ? "Archiv Plattenplatz knapp"
                : "Archiv Plattenplatz ok"
            }
            colorChangeBool={!archivAuslastungExceeded}
            id="isArchiveHardDriveOk"
          />
        </SlimListItemButton>

        <SlimListItemButton>
          <ColoredSmallListItemText
            primary={jobsOk ? "Jobs ok" : "Jobs n. ok"}
            id="jobsOk"
            colorChangeBool={jobsOk}
          />
        </SlimListItemButton>
      </List>
    </Card>
  );
};

export default EpgMonitoring;
