import { Button } from "@mui/material";
import KaufanfragenGBHController from "../controller/KaufanfragenGBHController";
import KaufanfragenUtil from "../controller/KaufanfragenUtil";
import { KaufanfragenGridActionProps } from "../interface/Kaufanfragen";
import KaufanfragenGBHGrundDialogContent from "./KaufanfragenGBHRejectDialog";



const KaufanfragenGBHGridActions = (props: KaufanfragenGridActionProps) => {
    let rejectReason: string | undefined = undefined;

    const onRejectReasonTextChange = (value: string | undefined) => {
        rejectReason = value
    }

    const showKaufanfrageRejectDialog = () => {
        onRejectReasonTextChange(undefined)
        if (KaufanfragenUtil.isGridSelectionSuitable(
            props,
            'ANGEFRAGT'
        )) {
            props.dialogController.showDialog({
                message: '',
                title: 'Kaufanfrage ablehnen',
                content: <KaufanfragenGBHGrundDialogContent onReasonTextChange={onRejectReasonTextChange} />,
                onOkClick: () => {
                    doReject()
                },
                okCaption: 'Kaufanfrage ablehnen',
                onAbortClick: () => { },
                abortCaption: 'Abbrechen'
            })
        }
    }

    const doReject = () => {
        KaufanfragenGBHController.reject(
            props,
            rejectReason,
        )
    }
    const doApprove = () => {
        KaufanfragenGBHController.approve(props)
    }

    return (
        <>
            <Button
                size="small"
                variant="contained"
                onClick={doApprove}
            >
                Genehmigen
            </Button>
            <Button
                size="small"
                variant="contained"
                onClick={showKaufanfrageRejectDialog}
            >
                Ablehnen
            </Button>
        </>
    );
}

export default KaufanfragenGBHGridActions;
