import { ColDef } from "ag-grid-community";

export const DPGStammdatenPanelColumnDefs: Array<ColDef> = [
    {
        headerName: "Schlüssel-ID",
        field: "schluesselId",
        minWidth: 230,
        maxWidth: 230,
    },
    {
        headerName: "Gültig von",
        field: "gueltigVon",
        minWidth: 110,
        maxWidth: 110,
    },
    {
        headerName: "Gültig bis",
        field: "gueltigBis",
        minWidth: 110,
        maxWidth: 110,
    },
    {
        headerName: "RN-GLN",
        field: "edkOrgGln",
        minWidth: 140,
        maxWidth: 140,
    },
    {
        headerName: "Rücknehmer",
        field: "edkOrgName",
        minWidth: 160,
        maxWidth: 360,
    },
    {
        headerName: "Nutzungsart",
        field: "nutzungsartBezeichnung",
        minWidth: 160,
        maxWidth: 260,
    },
    {
        headerName: "Zählstelle",
        field: "zaehlstelleBezeichnung",
        minWidth: 110,
        maxWidth: 110,
    },
    {
        headerName: "Sperrkennzeichen",
        field: "sperrkennzeichenBezeichnung",
        minWidth: 170,
        maxWidth: 170,
    },
    {
        headerName: "Letzte Änderung",
        field: "letztesAenderungsdatum",
        minWidth: 150,
        maxWidth: 150,
    },
];
