import { TabContainerObject } from "page/Automatensuche/interface/TabControlTypes";
import { createContext, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateDefaultTabContainer } from "./generator/generateDefaultValues";
import TabControl from "./TabControl";

interface ITabControlContext {
  tabContainer: TabContainerObject;
  push: (automatSerienNr: string, automatKey?: string) => void;
  switchTab: (newValue: number) => void;
}

export const TabControlContext = createContext<ITabControlContext>({
  tabContainer: generateDefaultTabContainer(),
  push: () => {},
  switchTab: () => {},
});

const TabControlProvider = (props: {
  children: ReactElement | ReactElement[];
}) => {
  const [, switchTab] = useState<number>(0);
  const [tabContainer, setTabContainer] = useState<TabContainerObject>(
    generateDefaultTabContainer()
  );
  const navigate = useNavigate();

  const tabControl = new TabControl(1, navigate, setTabContainer, switchTab);

  return (
    <TabControlContext.Provider
      value={{
        tabContainer: tabContainer,
        push: (automatSerienNr, automatKey) => {
          tabControl.push(automatSerienNr, automatKey);
        },
        switchTab: (newValue) => tabControl.switchTab(newValue),
      }}
    >
      {props.children}
    </TabControlContext.Provider>
  );
};

export default TabControlProvider;
