import { Grid, Stack } from "@mui/material";
import { Enumerator } from "global/components/EnumeratedValues/Enumerator";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import DateUtils, { getTomorrow } from "global/util/DateUtils";
import { useEffect, useState } from "react";
import AnwendernachrichtService from "service/data-service/anwender-controller/Anwendernachricht.service";
import Anwendernachricht from "service/data-service/anwender-controller/interface/Anwendernachricht";
import AnwendernachrichtenGridActions from "./AnwendernachrichtenGridActions";
import AnwendernachrichtenUtil from "./AnwendernachrichtenUtil";
import AnwendernachrichtenColumnDefs from "./grid-column-defs";

import { GridApi } from "ag-grid-community";
import "./anwendernachrichten.scss";

const Anwendernachrichten = () => {
    const [records, setRecords] = useState<Array<Anwendernachricht>>()
    const [selection, setSelection] = useState<Anwendernachricht>()
    const [hoursIntervals, setHoursIntervals] = useState<Enumerator>(new Enumerator([]))
    const [gridApi, setGridApi] = useState<GridApi>();
    const [selectionKey, setSelectionKey] = useState<string>()
    const [isFetchingData, setIsFetchingData] = useState(false)

    const onSelectionDataChange = (changes: Anwendernachricht, isGridSelection = false) => {
        if (isGridSelection) {
            setSelection({
                key: changes.key,
                nachricht: changes.nachricht,
                anzeigeDatumBis: changes.anzeigeDatumBis,
                anzeigeDatumVon: changes.anzeigeDatumVon,
                anzeigeZeitBis: changes.anzeigeZeitBis,
                anzeigeZeitVon: changes.anzeigeZeitVon,
            })
        } else {
            setSelection((oldValue) => {
                return {
                    ...oldValue,
                    ...changes
                }
            })
        }
        if (selectionKey && records) {
            const rowIndex = records.findIndex((r) => r.key === selectionKey)
            gridApi?.forEachNode((node) => node.selectThisNode(node.rowIndex === rowIndex))
            setSelectionKey(undefined)
        }
    }

    const resetRecord = () => {
        onSelectionDataChange({
            nachricht: '',
            anzeigeDatumVon: DateUtils.dateToArray(new Date()),
            anzeigeZeitVon: [6, 0],
            anzeigeDatumBis: DateUtils.dateToArray(getTomorrow()),
            anzeigeZeitBis: [20, 0],
        }, true)
    }

    const reloadRecords = (selectionKey?: string) => {
        setIsFetchingData(true)
        AnwendernachrichtService.search(
            false,
            (data: Array<Anwendernachricht>) => {
                setIsFetchingData(false)
                setSelectionKey(selectionKey)
                setRecords(data)
            },
            () => { setIsFetchingData(false) }
        )
    }

    useEffect(() => {
        setHoursIntervals(new Enumerator(AnwendernachrichtenUtil.buildHoursIntervalsItems()))
        reloadRecords()
    }, [])

    return (
        <Grid container spacing={5} width={"auto"}>
            <Grid item xs={12} mt={4}>
                <TextValue
                    id={"nachricht-text"}
                    label="Anwendernachricht:"
                    value={selection?.nachricht ?? ''}
                    onChange={(event) => onSelectionDataChange({ nachricht: event.target.value })}
                    TypographyProps={{ className: "anwendernachrichten__TextStyle" }}
                    TextFieldProps={{
                        className: "anwendernachrichten__inputTextFieldForNachricht",
                        multiline: true,
                        rows: 3,
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
                <Stack className={'rowStack'}>
                    <DatePickerElement
                        label="Anzeige von:"
                        enablePast
                        defaultValue={DateUtils.arrayToDate(selection?.anzeigeDatumVon ?? [])}
                        getSelectedValue={(value) => onSelectionDataChange(
                            {
                                anzeigeDatumVon: DateUtils.dateToArray(value ?? undefined)
                            }
                        )}
                        id="datum-von"
                        TypographyProps={{ className: "anwendernachrichten__Label anwendernachrichten__TextStyle" }}
                        TextFieldProps={{ className: "logfile__inputTextFieldForDate uiElement__InputWithBox" }}
                    />
                    <SelectBox
                        label={''}
                        selection={
                            hoursIntervals.itemForId(
                                AnwendernachrichtenUtil.buildHoursIntervalsId(selection?.anzeigeZeitVon)
                            )}
                        enumerator={hoursIntervals}
                        id="zeit-von"
                        getSelectedValue={(value) => onSelectionDataChange(
                            {
                                anzeigeZeitVon: AnwendernachrichtenUtil.transmuteToTimeArray(value)
                            }
                        )}
                        AutocompleteProps={{ className: "anwendernachrichten__AutocompleteContainer" }}
                        TypographyProps={{ className: "anwendernachrichten__TextStyle" }}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
                <Stack className={'rowStack'}>
                    <DatePickerElement
                        label="Anzeige bis:"
                        enablePast
                        defaultValue={DateUtils.arrayToDate(selection?.anzeigeDatumBis ?? [])}
                        getSelectedValue={(value) => onSelectionDataChange(
                            {
                                anzeigeDatumBis: DateUtils.dateToArray(value ?? undefined)
                            }
                        )}
                        id="datum-bis"
                        TypographyProps={{ className: "anwendernachrichten__Label anwendernachrichten__TextStyle" }}
                        TextFieldProps={{ className: "logfile__inputTextFieldForDate uiElement__InputWithBox" }}
                    />
                    <SelectBox
                        label={''}
                        selection={
                            hoursIntervals.itemForId(
                                AnwendernachrichtenUtil.buildHoursIntervalsId(selection?.anzeigeZeitBis)
                            )}
                        enumerator={hoursIntervals}
                        id="zeit-bis"
                        getSelectedValue={(value) => onSelectionDataChange(
                            {
                                anzeigeZeitBis: AnwendernachrichtenUtil.transmuteToTimeArray(value)
                            }
                        )}
                        AutocompleteProps={{ className: "anwendernachrichten__AutocompleteContainer" }}
                        TypographyProps={{ className: "anwendernachrichten__TextStyle" }}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    columnDefs={AnwendernachrichtenColumnDefs}
                    rowsDataDef={{
                        data: records,
                        isFetchingData: isFetchingData,
                    }}
                    onGridReady={(event) => {
                        setGridApi(event.api);
                    }}
                    gridActions={<AnwendernachrichtenGridActions
                        record={selection}
                        reloadTrigger={reloadRecords}
                        resetTrigger={resetRecord}
                    />}

                    onRowSelected={(data) => onSelectionDataChange(data ?? {}, true)}
                />
            </Grid>
        </Grid>
    );
};

export default Anwendernachrichten;
