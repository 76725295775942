import { Enumerator } from "global/components/EnumeratedValues/Enumerator";
import AutomatenblattInformation, {
  Details,
  HistorieRecord,
  Rns,
  SapRechnungResponse,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { Clearingfehler } from "service/data-service/clearingfehler-controller/interface/Clearingfehler";
import RnsHistory from "service/data-service/rns-controller/interface/RnsHistory";
import { DPGStammdaten } from "service/data-service/stammdaten-controller/interface/DPGStammdaten";

export function generateDefaultAutomatenblattRnsInformation(): Rns {
  return {
    gln: "",
    name: "",
    ort: "",
    plz: "",
    strasse: "",
    sapGueltigVon: [],
    sapGueltigBis: [],
    bonNummer: "",
    bonNummerGueltigVon: undefined,
    infofeld1: "",
    infofeld2: "",
    infofeld3: "",
    kontaktEmail: "",
    kontaktName: "",
    kontaktTelefon1: "",
    kontaktTelefon2: "",
    edekaOrganisation: {
      name: "",
      gln: "",
      email: "",
      region: "",
      errorMessage: undefined,
      errorTyp: undefined,
    },
    bemerkung1: "",
    bemerkung2: "",
    bemerkung3: "",
  };
}

export function generateDefaultAutomatenblattInformation(): AutomatenblattInformation {
  return {
    aktiveSerienNrInRns: "",
    andereRegion: false,
    anlageBenutzer: "",
    anlageDatum: [],
    anzahlAktiveAutomatenInRns: 0,
    art: "",
    artKey: "",
    athGln: 0,
    auslastungVerfuegbarkeitDTO: null,
    automatCredentialList: [],
    automatKey: "",
    automatStatusAktuell: "",
    automatStatusGueltigBisAktuell: [],
    automatStatusGueltigVonAktuell: "",
    automatStatusGueltigVonInZukunft: undefined,
    automatStatusInZukunft: "",
    eigentuemer: "",
    epcomGroupNameForGui: "",
    garantieEnde: undefined,
    herstellerKuerzel: "",
    herstellerName: "",
    herstellungsdatum: new Date(),
    inbetriebnahme: undefined,
    infofeld1: "",
    infofeld2: "",
    infofeld3: "",
    kommTypen: [
      {
        adapter: "",
        artikelstammVariante: undefined,
        ftpDirectory: "",
        gueltigVon: [],
        knzKommTypRequired: true,
        kommPartnerName: "",
        kommTyp: "",
        passwordLan: "",
        protokollTyp: "",
        userLan: "",
      },
    ],
    letzterErfolgreicherAufruf: "",
    letzterZugriffAlle: "",
    modell: "",
    modellKey: "",
    modellMerkmale: {
      automatModellKey: "",
      automatModellName: "",
      bulkType: "",
      bulkTypeBeschreibung: "",
      erkennungTechnikType: "",
      erkennungTechnikTypeBeschreibung: "",
      herstellerKuerzel: "",
      kistenannahmeType: "",
      kistenannahmeTypeBeschreibung: "",
      kompaktorImAutomatkopfType: "",
      kompaktorImAutomatkopfTypeBeschreibung: "",
      mehrwegType: "",
      mehrwegTypeBeschreibung: "",
      modifiedTimestamp: [],
      modifiedUser: "",
      sammlungAufTischType: "",
      sammlungAufTischTypeBeschreibung: "",
      throwInSeveralType: "",
      throwInSeveralTypeBeschreibung: "",
    },
    notVisible: false,
    notizen: [],
    nutzungsart: "",
    readonly: false,
    rns: generateDefaultAutomatenblattRnsInformation(),
    sapBonNummerDTO: {
      bonNummer: "",
      gueltigVon: [],
    },
    ruhtBis: undefined,
    ruhtVon: undefined,
    sammlungAufTisch: false,
    seriennummer: 0,
    sortierKonf: "",
    sortierKonfText: "",
    ssidAktiv: "",
    ssidGueltigVon: "",
    standort: "",
    standortHinweis: "",
    tkClearer: "",
    tkDaten: {
      automatID: 0,
      automatImInternet: false,
      automatKey: "",
      ipV4Lan: "",
      ipV6Lan: undefined,
      kommArt: "",
      stdGatewayV4: "",
      stdGatewayV6: undefined,
      subnetzV4: "",
      subnetzV6: undefined,
      tkArt: "",
    },
    zaehlstelle: "",
  };
}

export function generateDefaultAutomatenblattRnsHistorie(): Array<RnsHistory> {
  return [
    {
      gueltigBis: [],
      gueltigVon: [],
      name: "",
      region: "",
      rnsGln: "",
    },
    {
      gueltigBis: [],
      gueltigVon: [],
      name: "",
      region: "",
      rnsGln: "",
    },
  ];
}

export const DefaultAutomatenblattDetails: Details = {
  standortHinweis: "standortHinweis",
  nutzungsart: "Verbraucherrücknahmestelle",
  zaehlstelle: "Automat",
  sammlungAufTisch: true,
  modellMerkmalBulkBeschreibung: "nie",
  modellMerkmalErkennungTechnikBeschreibung: "Klassisch",
  modellMerkmalKompaktorImAutomatkopfBeschreibung: "nie",
  modellMerkmalKistenannahmeBeschreibung: "möglich",
  modellMerkmalMehrwegBeschreibung: "möglich",
  modellMerkmalSammlungAufTischBeschreibung: "möglich",
  modellMerkmalThrowInSeveralBeschreibung: "nie",
  garantieEnde: new Date(),
  sortierKonf: "C1",
  standort: "separater Raum außerhalb des Marktbereichs",
};

export const DefaultAutomatenblattKommunikationFormEnumerator = new Enumerator([
  { id: "ISDN", value: "ISDN" },
  { id: "LAN", value: "LAN" },
]);

export const DefaultAutomatenblattKommunikationArtEnumerator = new Enumerator([
  { id: "FTP_A", value: "FTP aktiv" },
  { id: "FTP_P", value: "FTP passiv" },
  { id: "SFTP", value: "SFTP" },
]);

export const DefaultAutomatenblattClearerEnumerator = new Enumerator([
  { id: "7b464c0a-9dac-470d-acd2-432daaac6648", value: "Getec" },
]);

export const DefaultAutomatenblattDatenspediteurEnum = new Enumerator([
  {
    id: "18731e8f-13b1-4cd1-a914-966581712a37",
    value: "LUNAR",
  },
]);

export const DefaultAutomatenblattDPGStammdatenRecords: Array<DPGStammdaten> = [
  {
    bemerkung: null,
    edkOrgGln: "4304743000003",
    edkOrgName: "EDEKA Rhein-Ruhr Stiftung & Co. KG",
    fehlerhaft: false,
    gueltigBis: "",
    gueltigVon: "18.09.2013",
    letztesAenderungsdatum: "19.09.2013",
    newest: true,
    nutzungsart: "03",
    nutzungsartBezeichnung: "Verbraucherrücknahmestelle",
    schluesselId: "5701005156-01",
    serienNr: "5701005156",
    sperrkennzeichenArt: "00",
    sperrkennzeichenBezeichnung: "nicht gesperrt",
    zaehlstelleArt: "02",
    zaehlstelleBezeichnung: "Automat",
  },
];

export const DefaultAutomatenblattClearingfehlerRecords: Array<Clearingfehler> =
  [];

export const DefaultAutomatenblattSapRechnungResponse: SapRechnungResponse = {
  anlagen: null,
  anzahlPositionen: null,
  cntDoublettenDb: 0,
  cntImported: 0,
  cntMovedToPostion: 0,
  cntNotForRegion: 0,
  cntPositions: 0,
  cntPositionsWithoutWarengruppe: 0,
  copyResult: null,
  copyResultError: null,
  doublettenCheckDb: null,
  errorTyp: "NO_ERROR",
  excelDTO: null,
  exportPositions: null,
  id: null,
  importPositions: null,
  importTimestampStart: null,
  lieferDatumEnde: null,
  lieferDatumStart: null,
  message: "Mit RNS-GLN und SN gefunden",
  page: null,
  pageSize: null,
  positionEdits: [
    {
      angelegtAm: "07.04.2022",
      angelegtAmUhrzeit: "07.04.2022 14:24:19",
      anlagen:
        "2658403688_Anlage1.pdf;2658403688_Anlage2.pdf;2658403688_Anlage3.pdf;",
      anzahlPositionen: null,
      artikel: "30030521",
      automatHerstellerName: "Trautwein SB Technik GmbH",
      automatModell: "B 430",
      automatStatus: null,
      automatTyp: null,
      belegDatum: "30.03.2022",
      bemerkung: null,
      buchungsDatum: "08.04.2022",
      datumInbetriebnahme: "21.03.2016",
      fakturaMengenEinheit: "ST",
      fakturaMengenEinheitExcelValue: "ST",
      fakturierteMenge: 1,
      id: 409240,
      knzHide: false,
      knzNotEpcom: false,
      knzSerienNrByUser: false,
      kundeGln: "4304743006043",
      kurzText: "Fullservice-/Wartungsvertrag /300,",
      lieferLeistungsDatum: "30.03.2022",
      lieferantenArtikelNr: "4076300000015",
      modifiedTimestamp: "08.04.2022 22:00:06",
      modifiedUser: "DS_RNSWITH_ONEAUTOMAT",
      nameRechnungsEmpfaenger: "EDEKA Rhein-Ruhr Stiftung & Co. KG",
      nameRechnungssteller: "Trautwein SB-Technik GmbH",
      nettoWert: 2002.95,
      nettoWertAsText: "2002.95",
      nettoWertEinzelPreis: 2002.95,
      positionLieferant: "000001",
      rechnungsEmpfaenger: "179003",
      rechnungssteller: "5010216",
      rechnungsstellerKuerzel: null,
      referenz: "FA152652",
      region: "RRST",
      regionName: null,
      regulierungsBeleg: "2658403688",
      rnGln: "4304743000003",
      rnsGln: "4304743006043",
      rnsGlnAdressMatch: null,
      rnsGlnChanged: null,
      rnsName: "Rotthowe GmbH (604)",
      rnsOrt: "Münster",
      rnsPlz: "48143",
      rnsStrasse: "Aegidiimarkt 7",
      sapStammdatenRnGln: null,
      serienNr: null,
      serienNrEpcom: "18800171",
      storniert: "",
      stornierterBeleg: "",
      stornoGruppeNr: null,
      unterWarengruppeName: "LRS-0411 Hersteller Serviceverträge Vollwartung",
      warengruppeName: "Dienstleistung",
      warengruppeNr: 770804,
    },
    {
      angelegtAm: "05.03.2020",
      angelegtAmUhrzeit: "05.03.2020 13:26:35",
      anlagen:
        "2626721710_Anlage1.pdf;2626721710_Anlage3.pdf;2626721710_Anlage2.pdf",
      anzahlPositionen: null,
      artikel: "30030521",
      automatHerstellerName: "Trautwein SB Technik GmbH",
      automatModell: "B 430",
      automatStatus: null,
      automatTyp: null,
      belegDatum: "04.03.2020",
      bemerkung: null,
      buchungsDatum: "06.03.2020",
      datumInbetriebnahme: "21.03.2016",
      fakturaMengenEinheit: "ST",
      fakturaMengenEinheitExcelValue: "ST",
      fakturierteMenge: 1,
      id: 112962,
      knzHide: false,
      knzNotEpcom: false,
      knzSerienNrByUser: false,
      kundeGln: "4304743006043",
      kurzText: "Fullservice-/Wartungsvertrag /300,",
      lieferLeistungsDatum: "04.03.2020",
      lieferantenArtikelNr: "4076300000015",
      modifiedTimestamp: "18.05.2021 10:27:42",
      modifiedUser: "pemeye",
      nameRechnungsEmpfaenger: "EDEKA Rhein-Ruhr Stiftung & Co. KG",
      nameRechnungssteller: "Trautwein SB-Technik GmbH",
      nettoWert: 2002.95,
      nettoWertAsText: "2002.95",
      nettoWertEinzelPreis: 2002.95,
      positionLieferant: "000001",
      rechnungsEmpfaenger: "179003",
      rechnungssteller: "5010216",
      rechnungsstellerKuerzel: null,
      referenz: "FA141824",
      region: "RRST",
      regionName: null,
      regulierungsBeleg: "2626721710",
      rnGln: "4304743000003",
      rnsGln: "4304743006043",
      rnsGlnAdressMatch: null,
      rnsGlnChanged: null,
      rnsName: "Rotthowe GmbH (604)",
      rnsOrt: "Münster",
      rnsPlz: "48143",
      rnsStrasse: "Aegidiimarkt 7",
      sapStammdatenRnGln: null,
      serienNr: null,
      serienNrEpcom: "18800171",
      storniert: "",
      stornierterBeleg: "",
      stornoGruppeNr: null,
      unterWarengruppeName: "LRS-0411 Hersteller Serviceverträge Vollwartung",
      warengruppeName: "Dienstleistung",
      warengruppeNr: 770804,
    },
    {
      angelegtAm: "06.03.2019",
      angelegtAmUhrzeit: "06.03.2019 13:58:37",
      anlagen: null,
      anzahlPositionen: null,
      artikel: "30030521",
      automatHerstellerName: "Trautwein SB Technik GmbH",
      automatModell: "B 430",
      automatStatus: null,
      automatTyp: null,
      belegDatum: "05.03.2019",
      bemerkung: null,
      buchungsDatum: "07.03.2019",
      datumInbetriebnahme: "21.03.2016",
      fakturaMengenEinheit: "ST",
      fakturaMengenEinheitExcelValue: "ST",
      fakturierteMenge: 1,
      id: 155792,
      knzHide: false,
      knzNotEpcom: false,
      knzSerienNrByUser: false,
      kundeGln: "4304743006043",
      kurzText: "Fullservice-/Wartungsvertrag /300,",
      lieferLeistungsDatum: "05.03.2019",
      lieferantenArtikelNr: "4076300000015",
      modifiedTimestamp: "18.05.2021 10:27:42",
      modifiedUser: "pemeye",
      nameRechnungsEmpfaenger: "EDEKA Rhein-Ruhr Stiftung & Co. KG",
      nameRechnungssteller: "Trautwein SB-Technik GmbH",
      nettoWert: 2002.95,
      nettoWertAsText: "2002.95",
      nettoWertEinzelPreis: 2002.95,
      positionLieferant: "000001",
      rechnungsEmpfaenger: "179003",
      rechnungssteller: "5010216",
      rechnungsstellerKuerzel: null,
      referenz: "FA135782",
      region: "RRST",
      regionName: null,
      regulierungsBeleg: "2611747592",
      rnGln: "4304743000003",
      rnsGln: "4304743006043",
      rnsGlnAdressMatch: null,
      rnsGlnChanged: null,
      rnsName: "Rotthowe GmbH (604)",
      rnsOrt: "Münster",
      rnsPlz: "48143",
      rnsStrasse: "Aegidiimarkt 7",
      sapStammdatenRnGln: null,
      serienNr: null,
      serienNrEpcom: "18800171",
      storniert: "",
      stornierterBeleg: "",
      stornoGruppeNr: null,
      unterWarengruppeName: "LRS-0411 Hersteller Serviceverträge Vollwartung",
      warengruppeName: "Dienstleistung",
      warengruppeNr: 770804,
    },
  ],
  region: null,
  rnGln: null,
  rnsGln: null,
  ruecknahmeStelle: null,
  serienNr: null,
};

export const DefaultAutomatenblattHistorieRecords: Array<HistorieRecord> = [
  {
    automatKey: "b2e2466d-43af-4cf4-91fa-30479e2d7825",
    benutzer: "4826739",
    beschreibung: "Garantie-Ende",
    created: "2020-11-04T16:07:22.902",
    gueltigVon: null,
    id: 153384,
    rolle: "EPG",
    wertAlt: "2021-04-03",
    wertNeu: "2021-04-04",
  },
  {
    automatKey: "b2e2466d-43af-4cf4-91fa-30479e2d7825",
    benutzer: "4826739",
    beschreibung: "Garantie-Ende",
    created: "2020-11-04T16:07:14.811",
    gueltigVon: null,
    id: 153383,
    rolle: "EPG",
    wertAlt: "2021-04-04",
    wertNeu: "2021-04-03",
  },
  {
    automatKey: "b2e2466d-43af-4cf4-91fa-30479e2d7825",
    benutzer: "DPG_AUTOMAT_STAMMDATEN",
    beschreibung: "Inbetriebnahme-Datum automatisch aktualisiert",
    created: "2019-10-23T00:32:07.867",
    gueltigVon: "2019-10-23",
    id: 100752,
    rolle: "SYS",
    wertAlt: "2019-04-09",
    wertNeu: "2019-04-08",
  },
];
