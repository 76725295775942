import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { KaufanfrageSucheResult } from "service/data-service/kaufanfragen-controller/interface/Kaufanfrage";
import KaufanfragenService from "service/data-service/kaufanfragen-controller/Kaufanfragen.Service";
import ModalController from "../modal/interface/ModalController";
import useModalController from "../modal/use-modals";
import { KaufanfrageActionPayload, KaufanfrageStore, KaufanfrageStoreData } from "./interface/KaufanfrageStore";

export const KAUFANFRAGE_STATUS_SEARCH = 'KAUFANFRAGE_STATUS_SEARCH'
export const KAUFANFRAGE_STATUS_ITEMS = 'KAUFANFRAGE_STATUS_ITEMS'
export const KAUFANFRAGE_GRID_SELECTION = 'KAUFANFRAGE_GRID_SELECTION'

let kaufanfrageStatusStore: KaufanfrageStore = {
    data: {
        statusItems: [],
        result: null
    }
}
let listeners: Array<Dispatch<SetStateAction<KaufanfrageStoreData>>> = []

export type DispatchKaufanfrageFunction = (actionIdentifier: string, payload?: KaufanfrageActionPayload, callbackOnFail?: Function) => void;

const kaufanfragenReducer = (
    actionIdentifier: string,
    modals: ModalController,
    payload?: KaufanfrageActionPayload,
    callbackOnFail?: Function) => {

    switch (actionIdentifier) {
        case KAUFANFRAGE_STATUS_SEARCH:
            modals.showLoadingDialog('Kaufanfragen werden geladen...')
            KaufanfragenService.suche(
                kaufanfrageStatusStore.data.statusItems.map((e) => e.id).join(','),
                (resultData: KaufanfrageSucheResult) => {
                    modals.closeModal()
                    kaufanfrageStatusStore.data = {
                        ...kaufanfrageStatusStore.data,
                        result: resultData
                    }
                    listeners.forEach((l) => l(kaufanfrageStatusStore.data))
                },
                (err: Error) => {
                    modals.closeModal()
                    if (callbackOnFail) { callbackOnFail(err) }
                }
            )
            break;
        case KAUFANFRAGE_STATUS_ITEMS:
            kaufanfrageStatusStore.data = {
                ...kaufanfrageStatusStore.data,
                statusItems: payload?.queryStatusItems ?? []
            }
            break;
        case KAUFANFRAGE_GRID_SELECTION:
            kaufanfrageStatusStore.data = {
                ...kaufanfrageStatusStore.data,
                gridSelection: payload?.gridSelection
            }
            listeners.forEach((l) => l(kaufanfrageStatusStore.data))
            break;
        default:
            break;
    }
}


export const useKaufanfragen = (shouldListen = true): [KaufanfrageStoreData, DispatchKaufanfrageFunction] => {

    const setState = useState<KaufanfrageStoreData>(kaufanfrageStatusStore.data)[1]

    const modals = useModalController()

    const dispatch = useCallback(
        (
            actionIdentifier: string,
            payload?: KaufanfrageActionPayload,
            callbackOnFail?: Function
        ) => kaufanfragenReducer(actionIdentifier, modals, payload, callbackOnFail)
        , []);

    useEffect(() => {
        if (shouldListen)
            listeners.push(setState);

        return () => {
            listeners = listeners.filter(li => li !== setState);
        };
    }, [setState]);

    return [kaufanfrageStatusStore.data, dispatch];
}
