import { ColDef } from "ag-grid-community";

const VertragePanelColumnDefs: Array<ColDef> = [
    {
        headerName: "Lief.Datum",
        field: "lieferLeistungsDatum",
        width: 80,
    },
    {
        headerName: "Kurztext",
        field: "kurzText",
        width: 220,
    },
    {
        headerName: "Unter-WGR",
        field: "unterWarengruppeName",
        width: 260,
    },
    {
        headerName: "Seriennr.",
        field: "serienNrEpcom",
        width: 140,
    },
    {
        headerName: "Rechnungssteller",
        field: "nameRechnungssteller",
        width: 170,
    },
    {
        headerName: "Referenz",
        field: "referenz",
        width: 70,
    },
    {
        headerName: "Netto-Wert",
        field: "nettoWertAsText",
        width: 80,
    },
    {
        headerName: "Regulierungsbeleg",
        field: "regulierungsBeleg",
        width: 130,
    },
    {
        headerName: "Stornobeleg",
        field: "stornierterBeleg",
        width: 100,
    },
    {
        headerName: "Stornogruppe",
        field: "stornoGruppeNr",
        flex: 1,
    },
];

export default VertragePanelColumnDefs;
