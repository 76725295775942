import { AxiosResponse } from "axios";
import DateUtils from "global/util/DateUtils";
import AutomatenblattInformationDTO from "../dto/AutomatenblattInformationDTO";

const AxiosResponseToAutomatenblattInformationDTOMapper = {
  map: (response: AxiosResponse) => {
    let input = response.data;
    return AutomatenblattInformationDTO.createDTO({
      aktiveSerienNrInRns: input.aktiveSerienNrInRns,
      andereRegion: input.andereRegion,
      anlageBenutzer: input.anlageBenutzer,
      anlageDatum: input.anlageDatum,
      anzahlAktiveAutomatenInRns: input.anzahlAktiveAutomatenInRns,
      art: input.art,
      artKey: input.artKey,
      athGln: input.athGln,
      auslastungVerfuegbarkeitDTO: input.auslastungVerfuegbarkeitAutomatDTO,
      automatCredentialList: input.automatCredentialList,
      automatKey: input.automatKey,
      automatStatusAktuell: input.automatStatusAktuell,
      automatStatusGueltigBisAktuell: input.automatStatusGueltigBisAktuell,
      automatStatusGueltigVonAktuell: DateUtils.arrayToDateString(
        input.automatStatusGueltigVonAktuell
      ),
      automatStatusGueltigVonInZukunft: DateUtils.arrayToDate(
        input.automatStatusGueltigVonInZukunft
      ),
      automatStatusInZukunft: input.automatStatusInZukunft,
      eigentuemer: input.eigentuemer,
      epcomGroupNameForGui: input.epcomGroupNameForGui,
      garantieEnde: DateUtils.arrayToDate(input.garantieEnde),
      herstellerKuerzel: input.herstellerKuerzel,
      herstellerName: input.herstellerName,
      herstellungsdatum: input.herstellungsdatum,
      inbetriebnahme: DateUtils.arrayToDateString(input.inbetriebnahme),
      infofeld1: input.infofeld1,
      infofeld2: input.infofeld2,
      infofeld3: input.infofeld3,
      kommTypen: input.kommTypen,
      letzterErfolgreicherAufruf: input.letzterErfolgreicherAufruf,
      letzterZugriffAlle: input.letzterZugriffAlle,
      modell: input.modell,
      modellKey: input.modellKey,
      modellMerkmale: input.modellMerkmale,
      notVisible: input.notVisible,
      notizen: input.notizen,
      nutzungsart: input.nutzungsart,
      readonly: input.readonly,
      rns: input.rns,
      sapBonNummerDTO: input.rnsBonNummerDTO,
      ruhtBis: DateUtils.arrayToDate(input.ruhtBis),
      ruhtVon: DateUtils.arrayToDate(input.ruhtVon),
      sammlungAufTisch: input.sammlungAufTisch,
      seriennummer: input.seriennummer,
      sortierKonf: input.sortierKonf,
      sortierKonfText: input.sortierKonfText,
      ssidAktiv: input.ssidAktiv,
      ssidGueltigVon: DateUtils.arrayToDateString(input.ssidGueltigVon),
      standort: input.standort,
      standortHinweis: input.standortHinweis,
      tkClearer: input.tkClearer,
      tkDaten: input.tkDaten,
      zaehlstelle: input.zaehlstelle,
    });
  },
};

export default AxiosResponseToAutomatenblattInformationDTOMapper;
