import {
  Box,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { genericReducer } from "global/util/hook/reducer";
import { ChangeEvent, useEffect, useReducer } from "react";
import { SearchFieldReducer } from "./interface/reducer";

interface props {
  label: string;
  reset: boolean;
  getSelectedValue: (value: string) => void;
  defaultValue?: string;
  sx?: SxProps;
  textFieldProps?: TextFieldProps;
  onEnterKey?: () => void;
}

const SearchField: React.FC<props> = (props: props) => {
  const getSelectedValue = props.getSelectedValue;

  const [value, valueDispatch] = useReducer<SearchFieldReducer>(
    genericReducer,
    ""
  );

  useEffect(() => {
    function resetData() {
      getSelectedValue(props.defaultValue ?? "");
      return props.defaultValue ?? "";
    }

    valueDispatch(resetData);
  }, [props.reset, getSelectedValue, props.defaultValue]);

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="textFieldLabel" width="6rem">
        {props.label}
      </Typography>
      <Box width="80%">
        <TextField
          size="small"
          fullWidth
          sx={{ display: "flex", ...props.sx }}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            valueDispatch(() => {
              props.getSelectedValue(event.target.value);
              return event.target.value;
            });
          }}
          {...props.textFieldProps}
          onKeyUp={(event) => {
            if (event.key === "Enter" && props.onEnterKey) {
              props.onEnterKey();
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export default SearchField;
