import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import KaufanfragenDTO from "./dto/KaufanfragenDTO";
import Kaufanfrage from "./interface/Kaufanfrage";

const baseUrl: string = "kaufanfragen";
const KaufanfragenService = {
  insert: (
    kaufanfrage: Kaufanfrage,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendJsonDataPost(
        `${Config.dataServiceUrl}${baseUrl}/insert`,
        kaufanfrage
      )
      .then((response) => {
        callbackOnSuccess(response.data);
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  update: (
    kaufanfrage: Kaufanfrage,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendJsonDataPost(
        `${Config.dataServiceUrl}${baseUrl}/update`,
        kaufanfrage
      )
      .then((response) => {
        callbackOnSuccess(response.data);
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  suche: (
    statumsen: string,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}${baseUrl}/suche`, {
        params: {
          statumsen: statumsen,
        },
      })
      .then((response) => {
        callbackOnSuccess(
          KaufanfragenDTO.convertKaufanfragenResponse(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  status: (callbackOnSuccess: Function, callbackOnFail?: Function) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}${baseUrl}/status`)
      .then((response) => {
        callbackOnSuccess(
          KaufanfragenDTO.convertKaufanfragenStatusResponse(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  modell: (callbackOnSuccess: Function, callbackOnFail?: Function) => {
    axiosCalls
      .sendGet(`${Config.dataServiceUrl}ath/modell`)
      .then((response) => {
        callbackOnSuccess(KaufanfragenDTO.convertModellResponse(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};
export default KaufanfragenService;
