import { convertStatusValue } from "global/components/UI/StatusIcon/StatusIcon";
import { useEffect, useState } from "react";
import AutomatStatusList from "service/data-service/automate-controller/interface/AutomatStatus";
import BasicTable from "../TableContainer/TableContainer";
import { TableRowProps } from "../TableContainer/TableRow";
import "./automatStatus.scss";
import { StatusTableHeader } from "./resource/StatusTableHeader";

interface AutomatStatusProps {
  automatStatusList: AutomatStatusList;
}

const AutomatStatusElement: React.FC<AutomatStatusProps> = (
  props: AutomatStatusProps
) => {
  const [automatStatus, setAutomatStatus] = useState<AutomatStatusList>({
    row: [],
  });

  useEffect(() => {
    setAutomatStatus(props.automatStatusList);
  }, [props.automatStatusList]);

  return (
    <BasicTable
      head={StatusTableHeader}
      rows={createTable(automatStatus, true)}
      id="automatStatus"
    />
  );
};
export default AutomatStatusElement;

/**
 * Create table rows based on AutomatStatus.
 *
 * @param automatStatus
 * @param onlyFirst render only the first element
 * @returns Array<TableRowProps>
 */
function createTable(
  automatStatus: AutomatStatusList,
  onlyFirst?: boolean
): Array<TableRowProps> {
  const elements: Array<TableRowProps> = [];
  if (
    automatStatus !== undefined &&
    automatStatus.row !== undefined &&
    automatStatus.row.length > 0
  ) {
    automatStatus.row.forEach((element, index) => {
      if (onlyFirst === true && index !== 0) {
        return;
      }
      elements[index] = {
        row: [
          { value: convertStatusValue(element.fehlerNrProtokollAtMm, false) },
          { value: convertStatusValue(element.fehlerNrProtokollCdStd, false) },
          {
            value: convertStatusValue(element.fehlerNrProtokollCdMmquit, false),
          },
          { value: convertStatusValue(element.fehlerNrProtokollAtLog, false) },
          {
            value: convertStatusValue(element.fehlerNrProtokollAtDpgbtb, false),
          },
          {
            value: convertStatusValue(element.fehlerNrProtokollAtEpgbtb, false),
          },
        ],
      };
    });
  }
  return elements;
}
