import { AxiosResponse } from "axios";
import ArtikleSearchResult from "../interface/ArtikleSearchResult";

const ArtikleSearchDTO = {
  convertArtikleResponse: (response: AxiosResponse): ArtikleSearchResult => {
    if (response.data) {
      return {
        artikel: response.data.artikel,
        ruecknahmeInfo: response.data.ruecknahmeInfo,
        errorTyp: response.data.errorTyp,
      };
    }
    return {} as ArtikleSearchResult;
  },
};
export default ArtikleSearchDTO;
