import { SvgIconProps } from "@mui/material";
import { hasChildren } from "global/util/utils";

export interface SideMenuItem {
  id: string;
  text: string;
  link?: string;
  icon?: React.ElementType<SvgIconProps>;
  children?: readonly SideMenuItem[];
}

export type SideMenuItemToggleFunction = (itemIds: string[]) => void;
export type SideMenuItemLinkFollowFunction = (
  itemLink: string,
  itemId: string
) => void;

export type SideMenuItemTargetLink = string | undefined;

export function targetLinkFor(item: SideMenuItem): SideMenuItemTargetLink {
  if (item.link && !hasChildren(item)) {
    return item.link;
  }
}
