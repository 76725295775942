import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { KeyCloakService } from "App/service/keycloak.service";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import EpcomTreeView from "./components/Sidemenu/EpcomTreeView";
import {
  SideMenuItemLinkFollowFunction,
  SideMenuItemToggleFunction,
} from "./components/Sidemenu/SideMenuItem";
import epcommTreeViewItems from "./epcomm-treeview-items";
import "./titlebar.scss";

const TitleBar = () => {
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const [treeExpandedItems, setTreeExpandedItems] = useState([""]);
  const [treeSelectedItem, setTreeSelectedItem] = useState("");

  const navigate = useNavigate();

  const toggleMenuDrawer = () => {
    setMenuDrawerOpen(!menuDrawerOpen);
  };

  const linkFollowHandler: SideMenuItemLinkFollowFunction = useCallback(
    (itemLink: string, itemId: string) => {
      navigate(itemLink);
      setMenuDrawerOpen(false);
      setTreeSelectedItem(itemId);
    },
    [navigate]
  );

  const itemToggleHandler: SideMenuItemToggleFunction = useCallback(
    (nodeIds) => {
      setTreeExpandedItems(nodeIds);
    },
    []
  );

  return (
    <Stack>
      <AppBar
        position="static"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar variant="dense" className="toolbar">
          <IconButton
            className={menuDrawerOpen ? "menu-open" : "menu-closed"}
            edge="start"
            onClick={toggleMenuDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="title"
            noWrap
            component="div"
            className="title"
            color={"text.primary"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            EPCOM
          </Typography>
          <IconButton
            id="logout"
            className="logout"
            onClick={KeyCloakService.doLogout}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        variant="temporary"
        open={menuDrawerOpen}
        ModalProps={{
          onClose: (event, reason) => {
            setMenuDrawerOpen(false);
          },
        }}
      >
        <Toolbar variant="dense" />
        <EpcomTreeView
          onLinkFollow={linkFollowHandler}
          selectedItem={treeSelectedItem}
          expandedItems={treeExpandedItems}
          onItemToggle={itemToggleHandler}
          items={epcommTreeViewItems}
        />
      </Drawer>
    </Stack>
  );
};

export default TitleBar;
