import { ColDef } from "ag-grid-community";

const IpRangeColumnDefs: Array<ColDef> = [
  {
    headerName: "Ip-Bereich",
    field: "ipRange",
    minWidth: 100,
  },
  {
    headerName: "Region",
    field: "region",
    minWidth: 100,
  },
  {
    headerName: "Anzahl Automaten",
    field: "anzahl",
    minWidth: 100,
  },
  {
    headerName: "davon erreicht",
    field: "erreicht",
    minWidth: 100,
  },
  {
    headerName: "Erreichbarkeit",
    field: "erreichbarkeit",
    minWidth: 100,
    valueFormatter: (params) => {
      return `${params.value}%`;
    },
  },
];

export default IpRangeColumnDefs;
