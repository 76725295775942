import { InputLabel, Stack } from "@mui/material";
import React from "react";
import "./readOnlyValue.scss";

interface InputProps {
  value: string | number | undefined;
  isValue?: boolean;
  id?: string;
}

interface ReadOnlyProps {
  label: string;
  value: string | number | undefined;
  id?: string;
}

const InputLabelElement: React.FC<InputProps> = (props: InputProps) => {
  const width: string = "21rem";
  const labelWidth: string = "14rem";
  return (
    <InputLabel
      id={props.id}
      sx={{
        width: props.isValue ? width : labelWidth,
        display: "inline-flex",
        float: "left",
      }}
    >
      {props.value}
    </InputLabel>
  );
};

const ReadOnlyValue: React.FC<ReadOnlyProps> = (props: ReadOnlyProps) => {
  return (
    <Stack className="readOnlycontainer flexRow" columnGap={2}>
      <InputLabelElement value={props.label} />
      <InputLabelElement value={props.value} isValue={true} id={props.id} />
    </Stack>
  );
};
export default ReadOnlyValue;
