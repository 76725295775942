import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import DPGStammdatenDTO from "./dto/DPGStammdatenDTO";
import AutomatenblattStammdaten from "./interface/AutomatenblattStammdaten";
import { AxiosResponseToStammdatenDTOMapper } from "./mapping/AxiosResponseToStammdatenDTOMapper";

const StammdatenService = {
  retrieveStammdatenInformation: (
    type: string,
    callbackOnSuccess: Function,
    callbackOnFail: Function
  ) => {
    axiosCalls
      .sendGet(Config.dataServiceUrl + "stammdaten/" + type)
      .then((response) => {
        const data: AutomatenblattStammdaten =
          AxiosResponseToStammdatenDTOMapper.map(response);
        callbackOnSuccess(data);
      })
      .catch((error) => {
        callbackOnFail(error);
      });
  },
  getDpgStammdaten: (automatKey: String,
    callbackOnSuccess: Function,
    callbackOnFail?: Function) => {
    axiosCalls
      .sendGet(Config.dataServiceUrl + "dpg/" + automatKey)
      .then((response) => {
        callbackOnSuccess(
          DPGStammdatenDTO.convertResponseToDPGStammdatenDTO(response)
        );
      })
      .catch((error) => {
        if (callbackOnFail) { callbackOnFail(error) }
        else {
          console.log(error);
          // @TODO fire error message
        }
      });
  },
};

export default StammdatenService;
