import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import DsmdStatusDTO from "./dto/DsmdStatusDTO";

const DsmdService = {
  getDsmdStatus: (automatkey: string, callbackOnSuccess: Function, callbackOnFail: Function) => {
    axiosCalls
      .sendGet(`${Config.btbApiUrl}info/getAllInfoByAutomateKey/${automatkey}`)
      .then((response) => {
        callbackOnSuccess(DsmdStatusDTO.convertResponseToDsmdStatus(response));
      })
      .catch((error) => {
        if (callbackOnFail) {
          callbackOnFail(error);
          // @TODO fire error message
        }
      });
  },
  getFailedDsmd: (handler: ServiceResponseHandler) => {
    axiosCalls
      .sendGet(`${Config.btbApiUrl}info/getDsmdFailedForRegionAndRole`)
      .then((response) => {
        handler.callbackOnSuccess(DsmdStatusDTO.convertAutomatDatenDsmd(response));
      })
      .catch((error) => {
        if (handler.callbackOnFail) handler.callbackOnFail(error)
      });
  },
};

export default DsmdService;
