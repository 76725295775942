import { Checkbox } from "@mui/material";
import { ColDef, ICellRendererParams } from "ag-grid-community";

function createGlnChangeColumnDefs(
  changeBooleanInArray: (serialNumber: string, checkboxValue: boolean) => void
): Array<ColDef> {
  return [
    {
      field: "saveAlso",
      headerName: "RNS ebenfalls übernehmen",
      resizable: false,
      headerClass: "checkboxColumn",
      cellClass:
        "checkboxCells checkboxColumn ag-cell-center-justified-content",
      cellRenderer: (row: ICellRendererParams) => {
        return (
          <Checkbox
            size="small"
            value={row.data.saveAlso}
            onChange={(event) =>
              changeBooleanInArray(row.data.serialNumber, event.target.checked)
            }
          />
        );
      },
    },
    {
      field: "serialNumber",
      cellClass: "serialNumberCells",
      headerName: "Seriennummer",
      resizable: false,
    },
  ];
}

export default createGlnChangeColumnDefs;
