import HttpService from "App/service/http.service";
import ModalController from "global/hook/modal/interface/ModalController";
import { ErrorTypesContainer } from "service/data-service/error-typ-controller/container/errorTypeContainer";
import { returnUndefinedIfOnlyWhitespace } from "../utils";

const axiosWithKeycloak = HttpService.getAxiosClient();

function getMessageFromErrorType(givenType: string) {
  if (givenType !== 'NO_ERROR')
    return ErrorTypesContainer.find((errorType) => {
      return errorType.key === givenType;
    })?.value;
}

export function registerMessageDialogueInterceptor(
  dialogController: ModalController
) {
  axiosWithKeycloak.interceptors.response.use(
    (response) => {
      if (
        (response.data.errorTyp && response.status === 200) ||
        response.status === 201
      ) {
        let messageToShow = getMessageFromErrorType(response.data.errorTyp);
        if (messageToShow) dialogController.showMessageDialog(messageToShow);
      }
      return response;
    },
    (error) => {
      let errorMessage = 'Beim Laden der Daten ist ein Fehler aufgetreten!'
      if (!error.response) {
        errorMessage = 'Beim Abruf der Daten ist ein Netzwerkfehler aufgetreten!'
      } else if (error.response.status === 400 || error.response.status === 500) {
        errorMessage = getMessageFromErrorType(error.response.data.errorTyp) ??
          returnUndefinedIfOnlyWhitespace(error.response.data.message) ??
          error.response.data.error
      }
      if (errorMessage)
        dialogController.showError(errorMessage, error);
      return Promise.reject(error)
    }
  );
}
