import { AxiosResponse } from "axios";
import IpBereich from "../interface/IpBereich";

const IpBereichDTO = {
  convertIpBereichResponse: (response: AxiosResponse): Array<IpBereich> => {
    if (response.data) {
      return [...response.data];
    }
    return [] as Array<IpBereich>;
  },
};

export default IpBereichDTO;
