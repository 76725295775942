import { convertStatusValue } from "global/components/UI/StatusIcon/StatusIcon";
import { useEffect, useState } from "react";
import DsmdStatus from "service/data-service/automate-controller/interface/DsmdStatus";
import BasicTable from "../TableContainer/TableContainer";
import { TableRowProps } from "../TableContainer/TableRow";
import { DsmdTableHeader } from "./resource/DsmdTableHeader";

interface DsmdStatusProps {
  dsmdStatus?: DsmdStatus;
}

const DsmdStatusElement: React.FC<DsmdStatusProps> = (
  props: DsmdStatusProps
) => {
  const [dsmdStatus, setDsmdStatus] = useState<Array<TableRowProps>>([]);

  useEffect(() => {
    if (props.dsmdStatus?.status) {
      setDsmdStatus([
        {
          row: [
            { value: convertStatusValue(props.dsmdStatus.status, false) },
            { value: props.dsmdStatus.posDatum },
          ],
        },
      ]);
    } else {
      setDsmdStatus([]);
    }
  }, [props.dsmdStatus]);

  return (
    <BasicTable head={DsmdTableHeader} rows={dsmdStatus} id="dsmdStatus" />
  );
};
export default DsmdStatusElement;
