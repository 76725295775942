import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import {
  EpcomReducer,
  EpcomReducerFunction
} from "global/interface/EpcomReducer";
import DateUtils from "global/util/DateUtils";
import { DataControl } from "global/util/enum/DataControl";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
  useState
} from "react";
import "../../ui.scss";

interface DatePickerProps {
  label?: string;
  placeholder?: string;
  defaultValue?: Date;
  getSelectedValue: (values: Date | null) => void;
  id: string;
  enablePast?: boolean;
  resetToDefault?: boolean;
  TypographyProps?: TypographyProps;
  TextFieldProps?: TextFieldProps;
  hidden?: boolean;
  startDate?: Date;
}

const DatePickerElement: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  const [, setPreviousDefaultValues] = useState<null | Date>(null);
  const stateChangeAndReset: EpcomReducerFunction<Date | null> = (
    state,
    action
  ) => {
    if (action.type === DataControl.CHANGE) {
      return action.valueToChange ?? null;
    } else if (action.type === DataControl.CLEAR) {
      return null;
    } else {
      return props.defaultValue ?? null;
    }
  };

  const [date, dispatchDate] = useReducer<EpcomReducer<Date | null>>(
    stateChangeAndReset,
    props.defaultValue ?? null
  );

  const resetIfDefaultValueChanged = useCallback(() => {
    setPreviousDefaultValues((state) => {
      if (
        props.defaultValue &&
        state?.getTime() !== props.defaultValue.getTime()
      ) {
        dispatchDate({ type: DataControl.RESET });
        return props.defaultValue;
      } else if (state && !props.defaultValue) {
        dispatchDate({ type: DataControl.CLEAR });
        return null;
      } else {
        return state;
      }
    });
  }, [props.defaultValue]);

  useEffect(() => {
    resetIfDefaultValueChanged();
  }, [resetIfDefaultValueChanged]);

  useEffect(() => {
    dispatchDate({ type: DataControl.RESET });
  }, [props.resetToDefault]);

  const givenDate = DateUtils.formatDateToAPIDateString(
    props.defaultValue,
    "dd.MM.yyyy"
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
      <DesktopDatePicker
        value={date}
        label={props.placeholder}
        onChange={(newValue) => {
          dispatchDate({ type: DataControl.CHANGE, valueToChange: newValue });
          props.getSelectedValue(newValue);
        }}
        disablePast={!props.enablePast}
        minDate={props.startDate}
        renderInput={(params) => {
          // disable error highlighting for dates given by parent component
          const textFieldParams = {
            ...params,
            error:
              params.error && givenDate
                ? givenDate !== params.inputProps?.value
                : params.error,
          };

          let endAdornment = params.InputProps!.endAdornment as ReactElement;
          let calendarButton = React.cloneElement(endAdornment, {
            ...endAdornment.props,
            key: `${props.id}-calendarButton`,
          });

          return (
            <Stack
              className="uiElement__Container"
              columnGap={2}
              display={props.hidden ? "none" : "flex"}
            >
              {props.label && (
                <Typography
                  className="uiElement__Text uiElement__Label"
                  id={`${props.id}-label`}
                  {...props.TypographyProps}
                >
                  {props.label}
                </Typography>
              )}
              <TextField
                id={props.id}
                size="small"
                className="uiElement__TextElement uiElement__InputWithBox"
                {...textFieldParams}
                InputProps={{
                  endAdornment: [
                    <IconButton
                      key={`${props.id}-iconButton`}
                      edge="start"
                      sx={{ marginLeft: -10, marginRight: 2 }}
                      onClick={() => {
                        dispatchDate({ type: DataControl.CLEAR });
                        props.getSelectedValue(null);
                      }}
                      disableRipple
                      size="small"
                    >
                      <ClearIcon />
                    </IconButton>,
                    calendarButton,
                  ],
                }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  placeholder: "dd.mm.yyyy",
                }}
                {...props.TextFieldProps}
              />
            </Stack>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerElement;
