import { AutomatPanelRoutes } from "page/Automatensuche/components/Automatenblatt/components/AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import AutomatAction, { AutomatActionCallback, AutomatActionPayload } from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { registerAutomatDataStoreActions } from "../use-automat-datastore";

export const UPDATE_VIEW_PANEL_INFO = 'UPDATE_VIEW_PANEL_INFO';

const configureViewInfoStore = () => {
    const updateViewPanelInfo: AutomatAction =
    {
        identifier: UPDATE_VIEW_PANEL_INFO,
        action: (
            currentState: AutomatDataRecord,
            payload: AutomatActionPayload,
            callback: AutomatActionCallback,
            callbackOnFail: Function) => {

            const panelIndex = payload.viewPanelIndex;
            callback(
                {
                    viewInfo: {
                        ...currentState.viewInfo,
                        infoPanel: AutomatPanelRoutes[panelIndex],
                        infoPanelIndex: panelIndex,
                    }
                } as AutomatDataRecord);
        }
    };
    registerAutomatDataStoreActions([updateViewPanelInfo]);
};

export default configureViewInfoStore;
