import { Grid, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { FETCH_DPG_STAMMDATEN } from "global/hook/datastore/automat/automat-dpg-stammdaten-store";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useEffect } from "react";
import { DPGStammdatenPanelColumnDefs } from "./grid-column-defs";

interface DPGStammdatenPanelProps {
  automatKey: string;
}
const DPGStammdatenPanel = (props: DPGStammdatenPanelProps) => {
  const [automatData, dispatchAutomatDataAction] = useAutomatData(
    props.automatKey
  );

  useEffect(() => {
    if (!automatData.dpgStammdaten) {
      dispatchAutomatDataAction(FETCH_DPG_STAMMDATEN);
    }
  }, [automatData, dispatchAutomatDataAction]);

  const windowViewport = useWindowViewport(0, 276)

  return (
    <Grid container spacing={5} width={"auto"}>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          Datenbestand vom Vortag. Erstellt um 12 Uhr.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          height={windowViewport.height}
          gridOptions={{
            rowClassRules: {
              "bold-data-grid-row": "data.newest == true",
            },
          }}
          columnDefs={DPGStammdatenPanelColumnDefs}
          rowsDataDef={{ data: automatData.dpgStammdaten?.rows }}
        />
      </Grid>
    </Grid>
  );
};

export default DPGStammdatenPanel;
