import { ColDef } from "ag-grid-community";

const ZurBearbeitungColumnDefs: Array<ColDef> = [
  {
    headerName: "Region",
    field: "region",
    minWidth: 50,
    maxWidth: 85,
  },
  {
    headerName: "RNS",
    field: "rnsName",
    minWidth: 100,
  },
  {
    headerName: "PLZ",
    field: "rnsPlz",
    minWidth: 100,
  },
  {
    headerName: "Standort",
    field: "rnsOrt",
    minWidth: 100,
  },
  {
    headerName: "Strasse",
    field: "rnsStrasse",
    minWidth: 100,
  },
  {
    headerName: "GLN/RNS",
    field: "rnsGln",
    minWidth: 100,
  },
  {
    headerName: "Seriennummer",
    field: "serienNr",
    minWidth: 100,
  },
  {
    headerName: "Stammdatenfehler",
    field: "stammdatenfehler",
    minWidth: 100,
  },
  {
    headerName: "Clearingfehler",
    field: "clearingfehler",
    minWidth: 100,
  },
  {
    headerName: "Logfilefehler",
    field: "logfilefehler",
    minWidth: 100,
  },
];

export default ZurBearbeitungColumnDefs;
