import { ListItemText, SxProps } from "@mui/material";
import { redGreenSelector } from "global/util/styleMethods";
import React from "react";

interface props {
  primary: string;
  id?: string;
  sx?: SxProps;
}

export const CockpitListItemText: React.FC<props> = (props: props) => {
  return (
    <ListItemText
      primary={props.primary}
      sx={{ ...props.sx }}
      primaryTypographyProps={{ fontSize: 20, whiteSpace: "break-spaces" }}
      id={props.id}
    />
  );
};

export const SmallListItemText: React.FC<props> = (props: props) => {
  return (
    <ListItemText
      primary={props.primary}
      sx={{ ...props.sx }}
      id={props.id}
      primaryTypographyProps={{ fontSize: 13.5 }}
    />
  );
};

interface propsColor extends props {
  colorChangeBool: boolean;
}

export const ColoredSmallListItemText: React.FC<propsColor> = (
  props: propsColor
) => {
  return (
    <SmallListItemText
      primary={props.primary}
      id={props.id}
      sx={{ ...props.sx, color: redGreenSelector(props.colorChangeBool) }}
    />
  );
};
