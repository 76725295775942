import { AxiosResponse } from "axios";
import DPGStammdatenResult from "../interface/DPGStammdaten";

const DPGStammdatenDTO = {
  convertResponseToDPGStammdatenDTO: (
    response: AxiosResponse
  ): DPGStammdatenResult => {
    if (response.data) {
      return {
        total: response.data.total,
        rows: response.data.Row,
      };
    }
    return {} as DPGStammdatenResult;
  },
};
export default DPGStammdatenDTO;
