import { AxiosResponse } from "axios";
import DateUtils from "global/util/DateUtils";
import AutomatStatusList, { AutomatStatus } from "../interface/AutomatStatus";
import { FailedAutomats } from "../interface/FailedAutomatTagesstatus";

const AutomatStatusDTO = {
  createDTO: (input: AutomatStatusList) => {
    let rows: Array<AutomatStatus> = [];
    input.row.forEach((row) => {
      let record: AutomatStatus = {
        ...row,
        datumString: DateUtils.optionalTimeArrayToGermanString(
          row.datum as Array<number>
        ),
      };
      rows.push(record);
    });
    let newDTO: AutomatStatusList = {
      row: rows,
    };
    return newDTO;
  },
  convertFailedAutomatResponse: (
    response: AxiosResponse
  ): Array<FailedAutomats> => {
    if (response.data) {
      let rows: Array<FailedAutomats> = [];
      response.data.Row.forEach((row: FailedAutomats) => {
        let record: FailedAutomats = {
          ...row,
          lastNotizEditDateText: DateUtils.optionalTimeArrayToGermanString(
            row.lastNotizEditDate
          ) as string,
        };
        rows.push(record);
      });
      return rows;
    }
    return [];
  },
};

export default AutomatStatusDTO;
