import { Components, PaletteColorOptions, Theme } from "@mui/material";
import ColorPaletteOptions from "./ColorTheme";

const MenuThemeComponents: Components<Theme> = {
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor:
          ColorPaletteOptions?.secondary?.["main" as keyof PaletteColorOptions],
      },
      root: {
        position: "static",
        boxShadow: "none",
      },
    },
  },
};

export default MenuThemeComponents;
