import { Stack } from "@mui/material";
import {
  AUTOMATENBLATT_AUTOMATART_ENUM_ID,
  AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID,
  useEnumerator
} from "App/hook/use-enumerator";
import { Enumerator } from "global/components/EnumeratedValues/Enumerator";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  EpcomReducerFunctionOptional,
  EpcomReducerOptional
} from "global/interface/EpcomReducer";
import DateUtils, { getTomorrow } from "global/util/DateUtils";
import { DataControl } from "global/util/enum/DataControl";
import { useEffect, useReducer } from "react";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { generateDefaultAutomatenblattInformation } from "../../../generator/defaultAutomatenblattInformation";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import "../panels.scss";

interface DetailsPanelProps extends PanelBaseProps {
  automatKey: string;
}

const DetailsRightItem = (props: DetailsPanelProps) => {
  const [automatData] = useAutomatData(props.automatKey);

  const stateChangeAndReset: EpcomReducerFunctionOptional<
    AutomatenblattInformation
  > = (state, action) => {
    if (action.type === DataControl.CHANGE && action.valueToChange) {
      return { ...state, ...action.valueToChange };
    } else {
      return automatData.automatenblattInformation;
    }
  };

  const [automatenblattInformation, dispatchAutomatenblattInformation] =
    useReducer<EpcomReducerOptional<AutomatenblattInformation>>(
      stateChangeAndReset,
      automatData.automatenblattInformation ??
      generateDefaultAutomatenblattInformation()
    );

  const automatStatusZukunft: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATSTATUSZUKUNFT_ENUM_ID
  );
  const automatArt: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATART_ENUM_ID
  );

  const updateValues = props.updateValues;

  // Initialising at render and reset
  useEffect(() => {
    dispatchAutomatenblattInformation({ type: DataControl.RESET });
  }, [automatData, props.resetValues]);

  return (
    <Stack spacing={0.5}>
      <SelectBox
        id="automatStatusInZukunft"
        label="Zust. in Zukunft:"
        enableClearable
        enumerator={automatStatusZukunft}
        selection={automatStatusZukunft.itemForId(
          automatenblattInformation.automatStatusInZukunft
        )}
        getSelectedValue={(value) => {
          updateValues.current.automatStatusInZukunft = value?.id.toString();
          if (!updateValues.current.automatStatusGueltigVonInZukunft)
            updateValues.current.automatStatusGueltigVonInZukunft =
              DateUtils.formatDateToAPIDateString(
                automatenblattInformation.automatStatusGueltigVonInZukunft
              );
        }}
        reset={props.resetValues}
      />
      <DatePickerElement
        label="gültig ab:"
        defaultValue={
          automatenblattInformation.automatStatusGueltigVonInZukunft
        }
        getSelectedValue={(value) => {
          updateValues.current.automatStatusGueltigVonInZukunft =
            DateUtils.formatDateToAPIDateString(value) ?? "";
        }}
        resetToDefault={props.resetValues}
        id="automatStatusGueltigVonInZukunft"
      />
      <SelectBox
        id="artKey"
        label="Automaten Art:"
        enumerator={automatArt}
        selection={automatArt.itemForId(automatenblattInformation.artKey)}
        getSelectedValue={(value) => {
          updateValues.current.art = value?.id.toString();
        }}
        reset={props.resetValues}
      />
      <TextValue
        id="ssidAktiv"
        label="Aktive SSID:"
        value={automatenblattInformation.ssidAktiv}
        onChange={(event) => {
          dispatchAutomatenblattInformation({
            type: DataControl.CHANGE,
            valueToChange: { ssidAktiv: event.target.value },
          });
          updateValues.current.ssidAktiv = event.target.value;
        }}
      />
      <DatePickerElement
        label="ruht von:"
        defaultValue={automatenblattInformation.ruhtVon}
        getSelectedValue={(value) => {
          updateValues.current.ruhtVon =
            DateUtils.formatDateToAPIDateString(value) ?? ""; // Empty String needed on null
        }}
        resetToDefault={props.resetValues}
        startDate={getTomorrow()}
        id="ruhtVon"
      />
      <DatePickerElement
        label="ruht bis:"
        defaultValue={automatenblattInformation.ruhtBis}
        getSelectedValue={(value) => {
          updateValues.current.ruhtBis =
            DateUtils.formatDateToAPIDateString(value) ?? ""; // Empty String needed on null
        }}
        resetToDefault={props.resetValues}
        startDate={getTomorrow()}
        id="ruhtBis"
      />
    </Stack>
  );
};

export default DetailsRightItem;
