import { Button } from "@mui/material";
import React from "react";
import "../../ui.scss";

interface UIButtonProps {
  label: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
}

const UIButton: React.FC<UIButtonProps> = (props: UIButtonProps) => {
  return (
    <Button
      className={props.className ? `uiElement ${props.className}` : "uiElement"}
      variant="contained"
      id={props.id}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
};
export default UIButton;
