import AutomatenblattStammdaten from "../interface/AutomatenblattStammdaten";

const StammdatenInformationDTO = {
  createDTO: (input: AutomatenblattStammdaten) => {
    let newDTO: AutomatenblattStammdaten = {
      row: input.row,
    };
    return newDTO;
  },
};

export default StammdatenInformationDTO;
