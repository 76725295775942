import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledEpcomTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.primary,
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        fontWeight: theme.typography.fontWeightLight,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.primary.main,
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: theme.typography.fontWeightLight,
            color: 'inherit',
            paddingLeft: theme.spacing(0),
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
})) as React.ComponentType<TreeItemProps>;

export default StyledEpcomTreeItemRoot;
