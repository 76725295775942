import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

export const NotizenPanelColumnDefs: Array<ColDef> = [
  {
    headerName: "Anlagedatum",
    field: "datumErfasst",
    width: 120,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Typ",
    field: "notizTyp",
    width: 120,
  },
  {
    headerName: "Status",
    field: "notizStatus",
    width: 100,
  },
  {
    headerName: "Text",
    field: "notizText",
    flex: 1,
  },
  {
    headerName: "User",
    field: "userCreated",
    width: 90,
  },
  {
    headerName: "Änderungsdatum",
    field: "datumUpdated",
    width: 120,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Wiedervorlage",
    field: "datumFaellig",
    width: 120,
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Lese-Berechtigung",
    field: "notizReadRoles",
    width: 120,
  },
];
