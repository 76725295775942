import { Card, CardHeader, List } from "@mui/material";
import {
  HREF_BERICHTE_ANSICHTFEHLER,
  HREF_BERICHTE_DSMD_FEHLERHAFT,
  HREF_BERICHTE_ERREICHBARKEITEN,
} from "global/util/routes";
import {
  ColoredListItemTypography,
  ListItemTypography,
} from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";

interface props {
  countOfVendingMachineFailure: number;
  countOfDsmdFailure: number | string;
  countOfWarningsReachability: number;
  vendingMachineFailureVisible: boolean;
  dsmdFailureVisible: boolean;
  warningsReachabilityVisible: boolean;
}

const Automatenstatus: React.FC<props> = (props: props) => {
  const [countOfVendingMachineFailure, setCountOfVendingMachineFailure] =
    useState(Number);
  const [countOfDsmdFailure, setCountOfDsmdFailure] = useState<number | string>(
    "n/a"
  );
  const [countOfWarningsReachability, setCountOfWarningsReachability] =
    useState(Number);

  const [vendingMachineFailureVisible, setVendingMachineFailureVisible] =
    useState(Boolean);
  const [dsmdFailureVisible, setDsmdFailureVisible] = useState(Boolean);
  const [warningsReachabilityVisible, setWarningsReachabilityVisible] =
    useState(Boolean);

  let navigate = useNavigate();

  useEffect(() => {
    setCountOfVendingMachineFailure(props.countOfVendingMachineFailure);
    setCountOfDsmdFailure(props.countOfDsmdFailure);
    setCountOfWarningsReachability(props.countOfWarningsReachability);

    setVendingMachineFailureVisible(props.vendingMachineFailureVisible);
    setDsmdFailureVisible(props.dsmdFailureVisible);
    setWarningsReachabilityVisible(props.warningsReachabilityVisible);
  }, [
    props.countOfVendingMachineFailure,
    props.countOfDsmdFailure,
    props.countOfWarningsReachability,
    props.vendingMachineFailureVisible,
    props.dsmdFailureVisible,
    props.warningsReachabilityVisible,
  ]);

  function selectCardVisibility() {
    if (
      vendingMachineFailureVisible ||
      dsmdFailureVisible ||
      warningsReachabilityVisible
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Card hidden={selectCardVisibility()} aria-label="Automatenstatus">
      <CardHeader title="Automatenstatus" />
      <List disablePadding>
        <CockpitListItemButton
          divider
          sx={{ display: vendingMachineFailureVisible ? "flex" : "none" }}
          onClick={() => navigate(HREF_BERICHTE_ANSICHTFEHLER)}
        >
          <CockpitListItemText primary="Automaten fehlerhaft: " />
          <ColoredListItemTypography
            colorChangeBool={countOfVendingMachineFailure <= 0}
            text={`${countOfVendingMachineFailure}`}
            id={"countOfVendingMachineFailure"}
          />
        </CockpitListItemButton>

        <CockpitListItemButton
          divider
          sx={{ display: dsmdFailureVisible ? "flex" : "none" }}
          onClick={() => navigate(HREF_BERICHTE_DSMD_FEHLERHAFT)}
        >
          <CockpitListItemText primary={"DSMD fehlerhaft: "} />
          <ColoredListItemTypography
            colorChangeBool={countOfDsmdFailure <= 0}
            text={`${countOfDsmdFailure}`}
            id={"countOfDsmdFailure"}
          />
        </CockpitListItemButton>

        <CockpitListItemButton
          sx={{ display: warningsReachabilityVisible ? "flex" : "none" }}
          onClick={() => navigate(HREF_BERICHTE_ERREICHBARKEITEN)}
        >
          <CockpitListItemText primary="Warn. Erreichbarkeiten: " />
          <ListItemTypography
            text={`${countOfWarningsReachability}`}
            id="countOfWarningsReachability"
          />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Automatenstatus;
