import { AxiosResponse } from "axios";
import StammdatenInformationDTO from "../dto/StammdatenInformationDTO";

export const AxiosResponseToStammdatenDTOMapper = {
  map: (response: AxiosResponse) => {
    let input = response.data;
    return StammdatenInformationDTO.createDTO({
      row: input.Row,
    });
  },
};
