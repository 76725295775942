import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import SelectItem from "global/interface/SelectItem";

const StammdatenUtils = {
  stammdatenArrayToSelectItemArray(
    stammdaten: Array<EnumeratorItem>
  ): Array<SelectItem> {
    let response: Array<SelectItem> = [];
    if (stammdaten !== undefined && stammdaten.length > 0) {
      stammdaten.forEach((row) => {
        let item: SelectItem | undefined = this.stammdatenToSelectItem(row);
        if (item) response.push(item);
      });
    }
    return response;
  },
  stammdatenValueFindByKey(data: Array<EnumeratorItem>, key: string): string {
    if (data && data.length > 0) {
      let value: Array<EnumeratorItem> = data
        .filter((stammdaten) => stammdaten.id === key)
        .map((stammdaten) => stammdaten);
      if (value[0]) {
        return value[0].value;
      }
    }
    return "";
  },
  stammdatenToSelectItem(stammdaten: EnumeratorItem): SelectItem | undefined {
    if (stammdaten !== undefined) {
      const item: SelectItem = {
        label: stammdaten.value,
        id: stammdaten.id.toString(),
      };
      return item;
    }
  },
};

export default StammdatenUtils;
