import { Column } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";

export function autoSizeColumns(
  ref: RefObject<AgGridReact>,
  columnsNotToResize?: Array<String>,
  skipHeaders?: boolean
) {
  let excludedColums = columnsNotToResize ?? [];

  if (ref.current !== null) {
    let columns: Array<Column | string> = ref
      .current!.columnApi.getAllGridColumns()
      .map((column) => {
        return column.getId();
      })
      .filter((id) => {
        return !excludedColums.includes(id);
      });

    ref.current.columnApi.autoSizeColumns(columns, skipHeaders);
  }
}
