import {
  Button, CssBaseline,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
  Toolbar
} from "@mui/material";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import useModalController, {
  useModalDialog
} from "global/hook/modal/use-modals";
import { registerMessageDialogueInterceptor as registerMessageDialogInterceptor } from "global/util/REST/interceptors";
import { SnackbarKey, SnackbarProvider } from "notistack";
import TabControlProvider from "page/Automatensuche/components/TabControl/TabControlProvider";
import React, { ReactNode, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import AccessProvider from "./components/Access-Control/AccessProvider";
import AppContent from "./components/AppContent/AppContent";
import TitleBar from "./components/Titlebar/Titlebar";
import { loadErrorTypeContainer } from "./script/loadErrorTypes";
import AppTheme from "./theme/AppTheme";

export const App = () => {
  const modal = useModalDialog();

  const snackbarProviderRef = React.createRef<SnackbarProvider>();
  const onDismiss = (key: SnackbarKey) => () => {
    snackbarProviderRef.current?.closeSnackbar(key);
    return null;
  };

  const dialogController = useModalController();

  useEffect(() => {
    ReactSession.setStoreType("localStorage");
    loadErrorTypeContainer();
    registerMessageDialogInterceptor(dialogController);// eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={AppTheme}>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider
          hideIconVariant
          ref={snackbarProviderRef}
          action={(key): ReactNode => {
            return (
              <Button
                className="snackbarDismissButton"
                onClick={onDismiss(key)}
              >
                OK
              </Button>
            );
          }}
        >
          <div className="app">
            <CssBaseline />
            <Stack>
              <BrowserRouter>
                <AccessProvider>
                  <TitleBar />
                  <TabControlProvider>
                    <Toolbar variant="dense" className="separator" />
                    <AppContent />
                  </TabControlProvider>
                </AccessProvider>
              </BrowserRouter>
            </Stack>
            {modal && <ModalMessageDialog {...modal} />}
          </div>
        </SnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
