import { isFalsyOrEmptyString } from "../utils";

export function isPartialFilled(stringArray: Array<string | undefined>) {
  let countOfFalsyStrings = getCountOfFalsyStrings(stringArray);
  return countOfFalsyStrings > 0 && countOfFalsyStrings < stringArray.length;
}

export function getCountOfFalsyStrings(stringArray: Array<string | undefined>) {
  let result = 0;
  stringArray.forEach((member) => {
    if (isFalsyOrEmptyString(member)) {
      result++;
    }
  });
  return result;
}
