import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { TabControlContext } from "page/Automatensuche/components/TabControl/TabControlProvider";
import { useContext } from "react";
import { FailedAutomats } from "service/data-service/automate-controller/interface/FailedAutomatTagesstatus";
import ExportService from "service/report-service/export/Export.service";
import AnsichtfehlerController from "./ansichtfehler-controller";
import AnsichtfehlerColumnDefs from "./grid-column-defs";

const Ansichtfehler: React.FC = () => {
  const tabControlCtx = useContext(TabControlContext);
  const windowViewport = useWindowViewport(0, 210)

  return (
    <Stack className="fehlerRecords">
      <Grid container spacing={1} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>Ansicht fehlerhafte MM</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={AnsichtfehlerColumnDefs}
            onRowClicked={(row: FailedAutomats) => {
              tabControlCtx.push(row.serienNr, row.automatKey);
            }}
            exportDef={{ action: ExportService.downloadFehlerhafteMM }}
            rowsDataDef={{
              uncontrolledDataFetchCall: AnsichtfehlerController.loadData,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Ansichtfehler;
