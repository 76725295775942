import DateUtils from "global/util/DateUtils";
import { MutableRefObject } from "react";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";

export default function clearUpdateValues(
  updateValues: MutableRefObject<AutomatenUpdateRequest>,
  automatenblattInformation: AutomatenblattInformation
) {
  // Change the automatKey on each Tab switch
  updateValues.current.automatKey = automatenblattInformation.automatKey;
  // Von and Bis needs to be set because the API will delete if no values are sent at update process.
  updateValues.current.ruhtBis =
    DateUtils.formatDateToAPIDateString(automatenblattInformation.ruhtBis) ??
    "";
  updateValues.current.ruhtVon =
    DateUtils.formatDateToAPIDateString(automatenblattInformation.ruhtVon) ??
    "";
  // Same goes for automatStatusGueltigVonInZukunft. If it is null, it's deleted by the backend.
  updateValues.current.automatStatusInZukunft = undefined;
  updateValues.current.automatStatusGueltigVonInZukunft = undefined;
  updateValues.current.art = undefined;
  updateValues.current.ssidAktiv = undefined;
  updateValues.current.rnsBonNummer = undefined;
  updateValues.current.rnsBonNummerGueltigVon = undefined;
  updateValues.current.infofeld1 = undefined;
  updateValues.current.infofeld2 = undefined;
  updateValues.current.infofeld3 = undefined;
  updateValues.current.standortHinweis = undefined;
  updateValues.current.standort = undefined;
  updateValues.current.sortierKonf = undefined;
  updateValues.current.garantieEnde = undefined;
  updateValues.current.sammlungAufTisch = undefined;
  updateValues.current.tkDaten = undefined;
  updateValues.current.kommTypen = undefined;
  updateValues.current.mode = undefined;
  updateValues.current.otherAutomatKeys = undefined;
}
