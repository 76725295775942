import {
  createTheme,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import "./automatensuche.scss";
import DepositMachineList from "./components/DepositMachineList/DepositMachineList";
import AccordionTheme from "./components/FilterSystem/theme/FilterTheme";
import { TabControlContext } from "./components/TabControl/TabControlProvider";

const Automatensuche: React.FC = () => {
  const tabControlContext = useContext(TabControlContext);

  return (
    <ThemeProvider theme={(theme) => createTheme(AccordionTheme, theme)}>
      <Stack rowGap={1.5}>
        <Tabs
          className="suche-tabs"
          value={tabControlContext.tabContainer.activeTab}
          selectionFollowsFocus
        >
          <Tab
            onClick={() => {
              tabControlContext.switchTab(-1);
            }}
            label={<Typography>Automatensuche</Typography>}
            className="static-tab-element"
          />
          {tabControlContext.tabContainer.tabs.map(
            (routeTab) => routeTab.tabElement
          )}
        </Tabs>

        <Routes>
          <Route
            path="*"
            element={
              <DepositMachineList
                onCellClicked={(row) => {
                  tabControlContext.push(row.automatSerienNr, row.automatkey);
                }}
              />
            }
          />
          {tabControlContext.tabContainer?.tabs.map(
            (routeTab) => routeTab.tabRoute
          )}
        </Routes>
      </Stack>
    </ThemeProvider>
  );
};

export default Automatensuche;
