import { Grid } from "@mui/material";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import "../panels.scss";
import DetailsLeftItem from "./DetailsLeftItem";
import DetailsRightItem from "./DetailsRightItem";

interface DetailsPanelProps extends PanelBaseProps {
  automatKey: string;
}

const DetailsPanel = (props: DetailsPanelProps) => {
  return (
    <Grid container rowGap={2}>
      <Grid className="grid_item_40rem" item xs={12} sm={6}>
        <DetailsLeftItem
          automatKey={props.automatKey}
          updateValues={props.updateValues}
          resetValues={props.resetValues}
        />
      </Grid>
      <Grid className="grid_item_40rem" item xs={12} sm={6}>
        <DetailsRightItem
          automatKey={props.automatKey}
          updateValues={props.updateValues}
          resetValues={props.resetValues}
        />
      </Grid>
    </Grid>
  );
};

export default DetailsPanel;
