import { ThemeOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    textFieldLabel: React.CSSProperties;
    accordionSummary: React.CSSProperties;
    title: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    textFieldLabel?: React.CSSProperties;
    accordionSummary?: React.CSSProperties;
    title?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    textFieldLabel: true;
    accordionSummary: true;
    title: true;
  }
}

const TypographyThemeOptions: ThemeOptions = {
  typography: {
    title: { fontSize: "1.4rem", fontWeight: 450 },
    h1: { fontSize: "1.3rem", fontWeight: "bold", marginBottom: "0.6rem" },
    h2: { fontSize: "1.24rem", fontWeight: "bold", marginBottom: "0.6rem" },
    h3: {
      fontSize: "1.18rem",
      fontWeight: 450,
      marginTop: "0.1rem",
      marginBottom: "0.6rem",
    },
    h4: {
      fontSize: "1.12rem",
      fontWeight: "normal",
      marginTop: "0.2rem",
      marginBottom: "0.6rem",
    },
    h5: {
      fontSize: "1.0rem",
      fontWeight: "normal",
      marginTop: "0.6rem",
      marginBottom: "0.3rem",
    },
    h6: { fontSize: "1.0rem", fontWeight: "lighter" },
    accordionSummary: { fontSize: "1.0rem", fontWeight: 450 },
    textFieldLabel: {
      fontWeight: 300,
      color: "#222",
      fontSize: "small",
    },
  },
};

export default TypographyThemeOptions;
