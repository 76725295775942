import { AxiosResponse } from "axios";
import DateUtils from "global/util/DateUtils";
import NoteRequestDTO from "../interface/NoteRequest";
import NoteSearchResultDTO, { Note } from "../interface/NoteSearchResult";

const NoteMapper = {
  mapResponseToNoteSearchResultDTO: (
    response: AxiosResponse
  ): NoteSearchResultDTO => {
    if (response.data) {
      return {
        anzahlWiedervorlage: response.data.anzahlWiedervorlage,
        rows: response.data.Row,
      };
    }
    return {} as NoteSearchResultDTO;
  },
  mapResponseToNoteDTO: (response: AxiosResponse): Note => {
    if (response.data) {
      return {
        ...response.data,
      };
    }
    return {} as Note;
  },
  /**
   *
   * @param note
   * @returns A NoteRequest DTO. If you want to send it to the save API, you have to give notizAktion a value
   */
  mapNoteToNoteDTO: (note: Note): NoteRequestDTO => {
    return {
      automatKey: note.automatKey,
      fileContent: "",
      filename: note.dateiName,
      notizAktion: "",
      notizKopfKey: note.notizKey,
      notizKopfText: note.notizText,
      notizZeileKey: "",
      notizZeileText: "", // Has to be taken out of the API call "notizen/search/notizkopfKey"
      status: note.notizStatus,
      type: note.notizTyp,
      wiedervorlage: DateUtils.optionalTimeArrayToISOString(note.datumFaellig),
      notizReadRoles: note.notizReadRoles,
    };
  },
};
export default NoteMapper;
