import {
  Input,
  InputProps,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { HTMLInputTypeAttribute } from "react";
import "../../ui.scss";

interface TextValueProps {
  label?: string;
  value: string | number | undefined;
  placeholder?: string;
  id?: string;
  readOnly?: boolean;
  multiline?: boolean;
  disableInputUnderline?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: HTMLInputTypeAttribute | undefined;
  TypographyProps?: TypographyProps;
  InputProps?: InputProps;
  TextFieldProps?: TextFieldProps;
  hidden?: boolean;
}

const TextValue: React.FC<TextValueProps> = (props: TextValueProps) => {
  let hideUnderline = props.disableInputUnderline ?? props.readOnly ?? false;
  const isReadonly: boolean = props.readOnly ?? false;
  return (
    <Stack
      className="uiElement__Container"
      columnGap={2}
      display={props.hidden ? "none" : "flex"}
    >
      {props.label && (
        <Typography
          className="uiElement__Text uiElement__Label"
          id={`${props.id}-label`}
          {...props.TypographyProps}
        >
          {props.label}
        </Typography>
      )}
      {isReadonly && (
        <Input
          className="uiElement__TextElement"
          id={`${props.id}-input`}
          readOnly
          disableUnderline={hideUnderline}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          multiline={props.multiline}
          style={{ minWidth: props.multiline ? "18rem" : "" }}
          type={props.type}
          {...props.InputProps}
        />
      )}
      {!isReadonly && (
        <TextField
          className="uiElement__TextElement uiElement__InputWithBox"
          id={`${props.id}-input`}
          value={props.value}
          size="small"
          placeholder={props.placeholder}
          onChange={props.onChange}
          multiline={props.multiline}
          style={{ minWidth: props.multiline ? "18rem" : "" }}
          type={props.type}
          {...props.TextFieldProps}
        />
      )}
    </Stack>
  );
};
export default TextValue;
