import { AxiosResponse } from "axios";
import ErrorTypeDTO from "../interface/ErrorType";

const ErrorTypeMapper = {
  mapResponseToErrorTypeDTO: (response: AxiosResponse): Array<ErrorTypeDTO> => {
    if (response.data) {
      return [...response.data.Row];
    }
    return [];
  },
};

export default ErrorTypeMapper;
