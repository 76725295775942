import { AxiosResponse } from "axios";
import NewAutomat from "../interface/NewAutomat";

const NewAutomatDTO = {
    convertResponseToNewAutomatDTO: (
        response: AxiosResponse
    ): NewAutomat => {
        return {
            ...response.data,
        };
    },
};
export default NewAutomatDTO;
