import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Tab, Typography } from "@mui/material";
import { AutomatStoreController } from "global/hook/datastore/use-automat-datastore";
import findMachineKeyViaSerialNumber from "global/util/REST/findMachineKey";
import "page/Automatensuche/automatensuche.scss";
import { Automatenblatt } from "page/Automatensuche/components/Automatenblatt/automatenblatt";
import { TabContainerObject } from "page/Automatensuche/interface/TabControlTypes";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction, Route } from "react-router-dom";

const _TabContainer: TabContainerObject = {
  STATIC_TAB_COUNT: 0,
  tabs: [],
  activeTab: 0,
};

class TabControl {
  private _navigator: NavigateFunction;
  private _refreshTabContainer: Dispatch<SetStateAction<TabContainerObject>>;
  private _refreshActiveTab: Dispatch<SetStateAction<number>>;

  /**
   * Initialises the Tab control functions with a set function of a useState hook.
   * If the Tabs change, the App will re-render
   *
   * @param onPushOrDelete - onPush, passes the last index of the tab list as parameter.
   * onDelete, passes the index of the tab left to the deleted one. Returns -1 if no more tabs are present
   * Use this to open a Tab of your choice, but remember to add the count of your static Tabs before the dynamic ones.
   * @returns Object with funtions to add and remove Tab Elements
   */
  constructor(
    staticTabCount: number,
    navigator: NavigateFunction,
    refreshTabContainer: Dispatch<SetStateAction<TabContainerObject>>,
    refreshActiveTab: Dispatch<SetStateAction<number>>
  ) {
    _TabContainer.STATIC_TAB_COUNT = staticTabCount;
    this._navigator = navigator;
    this._refreshTabContainer = refreshTabContainer;
    this._refreshActiveTab = refreshActiveTab;
  }

  /**
   * Takes a row of data to create <Tab> and <Route> elements and start the Re-render
   * Tabs can't have double id's/ serial numbers.
   * If a Tab with that serial number is already open, it is navigated to.
   *
   * @param row - the data of a row to pass to the new tab-page
   */
  public push(automatSerienNr: string, automatKey?: string) {
    if (this._noDoubleSerialNumbersExists(automatSerienNr)) {
      this._pushNewTab(automatSerienNr, automatKey);
    } else {
      this.switchTab(this._findIndexOfSerialNumber(automatSerienNr));
    }
  }

  public switchTab(index: number) {
    this._setActiveTab(_TabContainer.STATIC_TAB_COUNT + index);
    this._navigateToIndex(index);
    this._refreshTabContainer(_TabContainer);
  }

  private _pushNewTab(automatSerienNr: string, automatKey?: string) {
    if (automatKey === undefined) {
      findMachineKeyViaSerialNumber(automatSerienNr, (automatKey) => {
        this._openAutomatenblatt(automatSerienNr, automatKey);
        this._navigateTabOnPush(_TabContainer.tabs.length - 1);
      });
    } else {
      this._openAutomatenblatt(automatSerienNr, automatKey);
      this._navigateTabOnPush(_TabContainer.tabs.length - 1);
    }
  }

  private _openAutomatenblatt(automatSerienNr: string, automatKey: string) {
    _TabContainer.tabs.push({
      tabElement: (
        <Tab
          id={automatSerienNr}
          key={automatSerienNr}
          component={"div"}
          className="tab-element"
          label={
            <Stack
              className="tab-stack"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div
                className="tab-div"
                onClick={() =>
                  this.switchTab(this._findIndexOfSerialNumber(automatSerienNr))
                }
              >
                <Typography>{`${automatSerienNr}`}</Typography>
              </div>
              <IconButton
                onMouseDown={(event) => {
                  this.removeVendingMachineTabAndRoute(automatSerienNr);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          }
        />
      ),
      tabRoute: (
        <Route
          key={automatSerienNr}
          path={`${automatSerienNr}/*`}
          element={<Automatenblatt automatKey={automatKey} />}
        />
      ),
    });
  }

  /**
   * Takes a serienNummer to delete one tab and start the Re-render
   *
   * @param serienNummer - the id of a row to close a tab
   */
  private removeVendingMachineTabAndRoute(serienNummer: string) {
    let indexOfDeletedTab = 0;
    _TabContainer.tabs = _TabContainer.tabs.filter((tab, index) => {
      if (tab.tabElement.key === serienNummer) {
        indexOfDeletedTab = index;
        return false;
      } else {
        return true;
      }
    });

    this._NavigateTabAfterDeletion(indexOfDeletedTab);
    this._refreshTabContainer(_TabContainer);

    AutomatStoreController.removeAutomat(serienNummer);
  }

  private _noDoubleSerialNumbersExists(automatSerienNr: string): boolean {
    return (
      _TabContainer.tabs.filter(
        (existingTab) => automatSerienNr === existingTab.tabElement.key
      ).length === 0
    );
  }

  private _navigateTabOnPush(indexOfNewTab: number) {
    this._setActiveTab(_TabContainer.STATIC_TAB_COUNT + indexOfNewTab);
    this._navigateToIndex(indexOfNewTab);
    this._refreshTabContainer(_TabContainer);
  }

  private _NavigateTabAfterDeletion(indexOfDeletedTab: number) {
    let currentContainerIndex =
      _TabContainer.activeTab - _TabContainer.STATIC_TAB_COUNT;
    if (currentContainerIndex >= indexOfDeletedTab) {
      this._setActiveTab(_TabContainer.activeTab - 1);

      if (currentContainerIndex === indexOfDeletedTab)
        this._navigateToIndex(currentContainerIndex - 1);
    } else {
      this._navigateToIndex(currentContainerIndex); // Only for re-render
    }
  }

  private _navigateToIndex(index: number) {
    let serialNumberToOpen =
      _TabContainer.tabs[index]?.tabElement.key!.toString();

    this._navigator(
      serialNumberToOpen
        ? "/automatensuche/" +
            serialNumberToOpen +
            AutomatStoreController.automatRoutePath(serialNumberToOpen)
        : "/automatensuche",
      { replace: true }
    );
  }

  private _setActiveTab(value: number) {
    _TabContainer.activeTab = value;
    this._refreshActiveTab(value);
  }

  private _findIndexOfSerialNumber(serialNumber: string) {
    let result = 0;
    _TabContainer.tabs.forEach((tab, index) => {
      if (tab.tabElement.key === serialNumber) {
        result = index;
      }
    });
    return result;
  }
}

export default TabControl;
