export function hasChildren(item: any) {
  const { children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export function capitalizeFirstLetterRestLowercase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function toLowerCaseReplaceUnderscoreWithSpace(string: string) {
  return string.toLowerCase().replaceAll("_", " ");
}

export function returnUndefinedIfEmptyString(value: string) {
  return value === "" ? undefined : value;
}

export function returnNullIfEmptyString(value: string) {
  return value === "" ? null : value;
}

export function returnUndefinedIfOnlyWhitespace(stringToCheck?: string) {
  if (stringToCheck) {
    return returnUndefinedIfEmptyString(stringToCheck.trim());
  }
  return undefined;
}

export function isFalsyOrEmptyString(value: string | undefined | null) {
  return value === null || value === undefined || value.trim() === "";
}
