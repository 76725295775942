import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

const WiedervorlageColumnDefs: Array<ColDef> = [
    {
        headerName: "SerienNr.",
        field: "serienNr",
        minWidth: 100,
        maxWidth: 100,
    },
    {
        headerName: "GLN/RNS",
        field: "rnsGln",
        minWidth: 130,
        maxWidth: 130,
    },
    {
        headerName: "RNS",
        field: "rnsName",
        minWidth: 200,
    },
    {
        headerName: "Standort",
        field: "rnsOrt",
        minWidth: 140,
        maxWidth: 140,
    },
    {
        headerName: "Anlagedatum",
        field: "datumErfasst",
        minWidth: 130,
        maxWidth: 130,
        valueFormatter: (params) =>
            DateUtils.optionalTimeArrayToGermanString(params.value),
    },
    {
        headerName: "Typ",
        field: "notizTyp",
        minWidth: 160,
        maxWidth: 160,
    },
    {
        headerName: "Status",
        field: "notizStatus",
        minWidth: 90,
        maxWidth: 90,
    },
    {
        headerName: "Text",
        field: "notizText",
        minWidth: 300,
    },
    {
        headerName: "User",
        field: "userCreated",
        minWidth: 80,
        maxWidth: 80,
    },
    {
        headerName: "Änderungsdatum",
        field: "datumUpdated",
        minWidth: 154,
        maxWidth: 154,
        valueFormatter: (params) =>
            DateUtils.optionalTimeArrayToGermanString(params.value),
    },
    {
        headerName: "Wiedervorlage",
        field: "datumFaellig",
        minWidth: 140,
        maxWidth: 140,
        valueFormatter: (params) =>
            DateUtils.optionalTimeArrayToGermanString(params.value),
    },
];

export default WiedervorlageColumnDefs;
