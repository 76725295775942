import { ColDef } from "ag-grid-community";
import { CellValueRenderer } from "page/Automatensuche/components/DataGrid/DataGrid";

export const ClearingfehlerPanelColumnDefs: Array<ColDef> = [
    {
        headerName: "Schlüssel-ID",
        field: "schluesselId",
        minWidth: 130,
        maxWidth: 280,
    },
    {
        headerName: "Gültig von",
        field: "gueltigVon",
        minWidth: 106,
        maxWidth: 120,
    },
    {
        headerName: "Gültig bis",
        field: "gueltigBis",
        minWidth: 100,
        maxWidth: 120,
    },
    {
        headerName: "Fehlerbeschreibung",
        field: "fehlerbeschreibung",
        minWidth: 300,
        maxWidth: 600,
    },
    {
        headerName: "Anzahl MM",
        field: "anzahlMm",
        minWidth: 110,
        maxWidth: 130,
    },
    {
        headerName: "Anzahl Rohdatensätze",
        field: "anzahlRohdatensaetze",
        minWidth: 190,
        maxWidth: 200,
    },
    {
        headerName: "Prüfungsdatum",
        field: "pruefungsdatum",
        minWidth: 140,
        maxWidth: 140,
    },
    {
        field: "erledigtCellValue",
        headerName: "Erledigt",
        cellRenderer: CellValueRenderer,
        maxWidth: 90,
        minWidth: 90,
        cellClass: 'ag-cell-center-justified-content'
    },
];
