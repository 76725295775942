import { Card, CardHeader, List } from "@mui/material";
import { ColoredListItemTypography } from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useEffect, useState } from "react";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";

interface props {
  billsWithoutAssignment: number;
  billsWithoutAssignmentVisible: boolean;
}

const Rechnungen: React.FC<props> = (props: props) => {
  // Variablen und Hooks
  const [billsWithoutAssignment, setCountOfNotes] = useState(Number);
  const [billsWithoutAssignmentVisible, setBillsWithoutAssignmentVisible] =
    useState(Boolean);

  useEffect(() => {
    setCountOfNotes(props.billsWithoutAssignment);
    setBillsWithoutAssignmentVisible(
      props.billsWithoutAssignmentVisible !== undefined
        ? props.billsWithoutAssignmentVisible
        : false
    );
  }, [props.billsWithoutAssignment, props.billsWithoutAssignmentVisible]);

  // React-Tree
  return (
    <Card hidden={!billsWithoutAssignmentVisible}>
      <CardHeader title="Rechnungen ATH" />
      <List disablePadding>
        <CockpitListItemButton divider aria-label="Rechnungen">
          <CockpitListItemText primary="GLN's ohne Zuordnung: " />
          <ColoredListItemTypography
            colorChangeBool={billsWithoutAssignment <= 0}
            text={`${billsWithoutAssignment}`}
            id="billsWithoutAssignment"
          />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Rechnungen;
