import HttpService from "App/service/http.service";
import { AxiosRequestConfig } from "axios";

const axiosWithKeycloak = HttpService.getAxiosClient();

const axiosCalls = {
  sendGet: (urlToCall: string, config?: AxiosRequestConfig) => {
    return axiosWithKeycloak({
      method: "GET",
      url: urlToCall,
      ...config,
    });
  },
  sendJsonDataPost: (
    urlToCall: string,
    dataToSend: Object,
    config?: AxiosRequestConfig
  ) => {
    HttpService.configure();
    return axiosWithKeycloak({
      method: "POST",
      url: urlToCall,
      data: dataToSend,
      headers: { "Content-Type": "application/json" },
      ...config,
    });
  },
};

export default axiosCalls;
