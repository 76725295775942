import { Grid, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { TabControlContext } from "page/Automatensuche/components/TabControl/TabControlProvider";
import { useContext } from "react";
import NoteSearchResultDTO, {
  Note
} from "service/data-service/notizen-controller/interface/NoteSearchResult";
import NoteService from "service/data-service/notizen-controller/Note.service";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import ExportService, {
  NotizenSuchoption
} from "service/report-service/export/Export.service";
import WiedervorlageColumnDefs from "./grid-column-defs";

const NotizenWiedervorlage: React.FC = () => {
  const tabControlCtx = useContext(TabControlContext);
  const windowViewport = useWindowViewport(0, 217)

  return (
    <Grid container spacing={2} width={"auto"}>
      <Grid item xs={12} mt={3}>
        <Typography variant={"h1"}>Notizen Wiedervorlage</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          height={windowViewport.height}
          columnDefs={WiedervorlageColumnDefs}
          onRowClicked={(note: Note) => {
            tabControlCtx.push(note.serienNr, note.automatKey);
          }}
          exportDef={{
            action: ExportService.downloadNotizen.bind(
              null,
              NotizenSuchoption.wiedervorlage,
              "liste"
            ),
          }}
          rowsDataDef={{
            uncontrolledDataFetchCall: (handler: ServiceResponseHandler) => {
              NoteService.noteSearch(
                NotizenSuchoption.wiedervorlage,
                "liste",
                (response: NoteSearchResultDTO) => {
                  handler.callbackOnSuccess(response.rows);
                },
                handler.callbackOnFail
              );
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
export default NotizenWiedervorlage;
