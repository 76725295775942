import RnsGln from "service/data-service/rns-controller/interface/RnsGln";

export default interface AutomatenblattInformation {
  readonly aktiveSerienNrInRns: string;
  readonly andereRegion: boolean;
  readonly anlageBenutzer: string;
  readonly anlageDatum: Array<number>;
  readonly anzahlAktiveAutomatenInRns: number;
  readonly art: string;
  readonly artKey: string;
  readonly athGln: number;
  readonly auslastungVerfuegbarkeitDTO: AuslastungVerfuegbarkeitAutomat | null;
  readonly automatCredentialList: Array<AutomatCredentials>;
  readonly automatKey: string;
  readonly automatStatusAktuell: string | undefined;
  readonly automatStatusGueltigBisAktuell: Array<number>;
  readonly automatStatusGueltigVonAktuell: string | undefined;
  readonly automatStatusGueltigVonInZukunft: Date | undefined;
  readonly automatStatusInZukunft: string;
  readonly eigentuemer: Object;
  readonly epcomGroupNameForGui: string;
  readonly garantieEnde: Date | undefined;
  readonly herstellerKuerzel: string;
  readonly herstellerName: string;
  readonly herstellungsdatum: Date;
  readonly inbetriebnahme: string | undefined;
  readonly infofeld1: string;
  readonly infofeld2: string;
  readonly infofeld3: string;
  readonly kommTypen: Array<KommTypen>;
  readonly letzterErfolgreicherAufruf: string;
  readonly letzterZugriffAlle: string;
  readonly modell: string;
  readonly modellKey: string;
  readonly modellMerkmale: ModellMerkmale;
  readonly notVisible: boolean;
  readonly notizen: Array<Object>;
  readonly nutzungsart: string;
  readonly readonly: boolean;
  readonly rns: Rns;
  readonly sapBonNummerDTO: SapBonNummer;
  readonly ruhtBis: Date | undefined;
  readonly ruhtVon: Date | undefined;
  readonly sammlungAufTisch: boolean;
  readonly seriennummer: number;
  readonly sortierKonf: string;
  readonly sortierKonfText: string;
  readonly ssidAktiv: string;
  readonly ssidGueltigVon: string | undefined;
  readonly standort: string;
  readonly standortHinweis: string;
  readonly tkClearer: string;
  readonly tkDaten: TKDaten;
  readonly zaehlstelle: string;
}

export function changeBonNumberInRnsBonNummerDTO(
  rnsBonNumber: SapBonNummer,
  newValue: string | null
): SapBonNummer {
  return { ...rnsBonNumber, bonNummer: newValue };
}

export function changeRnsObjectWithRnsGln(rns: Rns, newValue: RnsGln): Rns {
  return { ...rns, ...newValue };
}

export interface Rns {
  readonly gln: string;
  readonly name: string | undefined;
  readonly strasse: string | undefined;
  readonly plz: string | undefined;
  readonly ort: string | undefined;
  readonly sapGueltigVon: Array<number>;
  readonly sapGueltigBis: Array<number>;
  readonly bonNummer: string | undefined;
  readonly bonNummerGueltigVon: Date | undefined;
  readonly infofeld1: string | undefined;
  readonly infofeld2: string | undefined;
  readonly infofeld3: string | undefined;
  readonly kontaktEmail: string | undefined;
  readonly kontaktName: string | undefined;
  readonly kontaktTelefon1: string | undefined;
  readonly kontaktTelefon2: string | undefined;
  readonly bemerkung1: string | undefined;
  readonly bemerkung2: string | undefined;
  readonly bemerkung3: string | undefined;
  readonly edekaOrganisation: {
    readonly email: string;
    readonly errorMessage: string | undefined;
    readonly errorTyp: string | undefined;
    readonly gln: string;
    readonly name: string;
    readonly region: string;
  };
}

export interface SapBonNummer {
  readonly bonNummer: string | null;
  readonly gueltigVon: Array<number>;
}

export interface ModellMerkmale {
  readonly bulkTypeBeschreibung: string | undefined;
  readonly erkennungTechnikTypeBeschreibung: string | undefined;
  readonly kompaktorImAutomatkopfTypeBeschreibung: string | undefined;
  readonly kistenannahmeTypeBeschreibung: string | undefined;
  readonly mehrwegTypeBeschreibung: string | undefined;
  readonly sammlungAufTischTypeBeschreibung: string | undefined;
  readonly throwInSeveralTypeBeschreibung: string | undefined;
  readonly automatModellKey: string | undefined;
  readonly automatModellName: string | undefined;
  readonly bulkType: string | undefined;
  readonly erkennungTechnikType: string | undefined;
  readonly herstellerKuerzel: string | undefined;
  readonly kistenannahmeType: string | undefined;
  readonly kompaktorImAutomatkopfType: string | undefined;
  readonly mehrwegType: string | undefined;
  readonly modifiedTimestamp: Array<number> | undefined;
  readonly modifiedUser: string | undefined;
  readonly sammlungAufTischType: string | undefined;
  readonly throwInSeveralType: string | undefined;
}

export interface Details {
  readonly standortHinweis: string;
  readonly nutzungsart: string;
  readonly zaehlstelle: string;
  readonly sammlungAufTisch: boolean;
  readonly modellMerkmalBulkBeschreibung: string;
  readonly modellMerkmalErkennungTechnikBeschreibung: string;
  readonly modellMerkmalKompaktorImAutomatkopfBeschreibung: string;
  readonly modellMerkmalKistenannahmeBeschreibung: string;
  readonly modellMerkmalMehrwegBeschreibung: string;
  readonly modellMerkmalSammlungAufTischBeschreibung: string;
  readonly modellMerkmalThrowInSeveralBeschreibung: string;
  readonly garantieEnde: Date;
  readonly sortierKonf: string;
  readonly standort: string;
}

export interface TKDaten {
  readonly automatID: number;
  readonly automatImInternet: boolean;
  readonly automatKey: string;
  readonly tkArt: string;
  readonly kommArt: string;
  readonly ipV4Lan?: string;
  readonly ipV6Lan?: string;
  readonly stdGatewayV4?: string;
  readonly stdGatewayV6?: string;
  readonly subnetzV4?: string;
  readonly subnetzV6?: string;
  readonly switchNummer?: string;
  readonly switchNummerGueltigVon?: string;
  readonly infoSwitchNummer?: string;
  readonly portNummer?: string;
  readonly portNummerGueltigVon?: string;
  readonly infoPortNr?: string;
}

export interface KommTypen {
  readonly adapter: string;
  readonly artikelstammVariante: string | undefined;
  readonly ftpDirectory: string;
  readonly gueltigVon: Array<number>;
  readonly knzKommTypRequired: boolean;
  readonly kommPartnerName: string;
  readonly kommTyp: string;
  readonly passwordLan: string;
  readonly protokollTyp: string;
  readonly userLan: string;
}

export interface KommTyp {
  readonly id: string;
  readonly name: string;
}

export interface KommPartner {
  readonly firma: {
    readonly email: string | undefined;
    readonly emailBcc: string | undefined;
    readonly emailCc: string | undefined;
    readonly firmaNr: string | undefined;
    readonly gln: string | undefined;
    readonly id: number | undefined;
    readonly nameKurz: string | undefined;
    readonly nameLang: string | undefined;
    readonly zksNummer: string | undefined;
  };
  readonly id: number;
  readonly ipAdresse: string | undefined;
  readonly key: string;
  readonly knzShow: boolean;
  readonly kommPartnerArt: string;
  readonly kommPartnerCredentials: Array<number>;
  readonly name: string;
  readonly password: string;
  readonly protokollTyp: string | undefined;
  readonly username: string;
}

export interface AutomatCredentials {
  readonly adapter: string;
  readonly artikelstammVariante: string | undefined;
  readonly automat: string | undefined;
  readonly ftpDirectory: string | undefined;
  readonly gueltigBis: Array<number> | undefined;
  readonly gueltigVon: Array<number> | undefined;
  readonly id: number;
  readonly kommPartner: KommPartner;
  readonly kommTyp: KommTyp;
  readonly password: string;
  readonly required: boolean;
  readonly username: string;
}

export interface SapRechnungResponse {
  readonly anlagen: string | null;
  readonly anzahlPositionen: string | null;
  readonly cntDoublettenDb: number;
  readonly cntImported: number;
  readonly cntMovedToPostion: number;
  readonly cntNotForRegion: number;
  readonly cntPositions: number;
  readonly cntPositionsWithoutWarengruppe: number;
  readonly copyResult: string | null;
  readonly copyResultError: string | null;
  readonly doublettenCheckDb: string | null;
  readonly errorTyp: string | null;
  readonly excelDTO: string | null;
  readonly exportPositions: string | null;
  readonly id: string | null;
  readonly importPositions: string | null;
  readonly importTimestampStart: string | null;
  readonly lieferDatumEnde: string | null;
  readonly lieferDatumStart: string | null;
  readonly message: string | null;
  readonly page: string | null;
  readonly pageSize: string | null;
  readonly positionEdits: SapRechnungRecord[] | null;
  readonly region: string | null;
  readonly rnGln: string | null;
  readonly rnsGln: string | null;
  readonly ruecknahmeStelle: string | null;
  readonly serienNr: string | null;
}

export interface SapRechnungRecord {
  readonly angelegtAm: string | null;
  readonly angelegtAmUhrzeit: string | null;
  readonly anlagen: string | null;
  readonly anzahlPositionen: string | null;
  readonly artikel: string | null;
  readonly automatHerstellerName: string | null;
  readonly automatModell: string | null;
  readonly automatStatus: string | null;
  readonly automatTyp: string | null;
  readonly belegDatum: string | null;
  readonly bemerkung: string | null;
  readonly buchungsDatum: string | null;
  readonly datumInbetriebnahme: string | null;
  readonly fakturaMengenEinheit: string | null;
  readonly fakturaMengenEinheitExcelValue: string | null;
  readonly fakturierteMenge: number;
  readonly id: number;
  readonly knzHide: false;
  readonly knzNotEpcom: false;
  readonly knzSerienNrByUser: false;
  readonly kundeGln: string | null;
  readonly kurzText: string | null;
  readonly lieferLeistungsDatum: string | null;
  readonly lieferantenArtikelNr: string | null;
  readonly modifiedTimestamp: string | null;
  readonly modifiedUser: string | null;
  readonly nameRechnungsEmpfaenger: string | null;
  readonly nameRechnungssteller: string | null;
  readonly nettoWert: number;
  readonly nettoWertAsText: string | null;
  readonly nettoWertEinzelPreis: number;
  readonly positionLieferant: string | null;
  readonly rechnungsEmpfaenger: string | null;
  readonly rechnungssteller: string | null;
  readonly rechnungsstellerKuerzel: string | null;
  readonly referenz: string | null;
  readonly region: string | null;
  readonly regionName: string | null;
  readonly regulierungsBeleg: string | null;
  readonly rnGln: string | null;
  readonly rnsGln: string | null;
  readonly rnsGlnAdressMatch: string | null;
  readonly rnsGlnChanged: string | null;
  readonly rnsName: string | null;
  readonly rnsOrt: string | null;
  readonly rnsPlz: string | null;
  readonly rnsStrasse: string | null;
  readonly sapStammdatenRnGln: string | null;
  readonly serienNr: string | null;
  readonly serienNrEpcom: string | null;
  readonly storniert: string | null;
  readonly stornierterBeleg: string | null;
  readonly stornoGruppeNr: string | null;
  readonly unterWarengruppeName: string | null;
  readonly warengruppeName: string | null;
  readonly warengruppeNr: number;
}

export interface LogfileRecord {
  readonly datum: string;
  readonly text: string;
  readonly kategorie: string;
}

export interface HistorieRecord {
  readonly id: number;
  readonly automatKey: string;
  readonly created?: string;
  readonly beschreibung?: string;
  readonly wertNeu?: string;
  readonly rolle?: string;
  readonly benutzer?: string;
  readonly gueltigVon?: string | null;
  readonly wertAlt?: string;
}

export interface AuslastungVerfuegbarkeitAutomat {
  readonly durchschnittGebinde: number;
  readonly verfuegbarkeitGrad: number;
  readonly verfuegbarkeitLevel: number;
  readonly verfuegbarkeitGradTendenzZurVorwoche: number;
  readonly auslastungGrad: number;
  readonly auslastungLevel: number;
  readonly ranking: number;
  readonly datenbestandVom: Array<number>;
}
