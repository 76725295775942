import {
  Autocomplete,
  Box,
  Stack,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import SelectItem from "global/interface/SelectItem";
import React, { useCallback, useEffect, useState } from "react";

interface props {
  label: string;
  placeholder?: string;
  options: Array<SelectItem>;
  defaultValue?: string;
  resetSelectedValues: boolean;
  getSelectedValue: (value: string) => void;
  onEnterKey?: () => void;
  sx?: SxProps;
}

// self derived from https://mui.com/material-ui/react-select/
// check for more information

const SingleSelect: React.FC<props> = (props: props) => {
  const [value, setValue] = useState<SelectItem | null>(null);

  const getSelectedValue = props.getSelectedValue;

  const findDefaultValue = useCallback(() => {
    return props.options.filter(
      (stammdaten) =>
        stammdaten.id.toLowerCase() === props.defaultValue!.toLowerCase()
    )[0];
  }, [props.options, props.defaultValue]);

  useEffect(() => {
    if (props.options && props.defaultValue !== undefined) {
      setValue(findDefaultValue());
    } else {
      setValue(null);
    }
  }, [props.options, findDefaultValue, props.defaultValue]);

  // Reset
  useEffect(() => {
    setValue(null);
    getSelectedValue("");
  }, [props.resetSelectedValues, getSelectedValue]);

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="textFieldLabel" width="6rem">
        {props.label}
      </Typography>
      <Box width="80%">
        <Autocomplete
          size="small"
          fullWidth
          id={props.label}
          options={props.options}
          value={value}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                onKeyDown: (event) => {
                  if (event.key === "Enter") {
                    event.stopPropagation();
                  }
                },
              }}
            />
          )}
          onChange={(event, selectedValue) => {
            setValue(selectedValue);
            props.getSelectedValue(selectedValue?.id ?? "");
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter" && props.onEnterKey) {
              props.onEnterKey();
            }
          }}
          sx={props.sx}
        />
      </Box>
    </Stack>
  );
};

export default SingleSelect;
