import Kaufanfrage from "service/data-service/kaufanfragen-controller/interface/Kaufanfrage"
import { KaufanfragenGridActionProps } from "../interface/Kaufanfragen"
import KaufanfragenUtil from "./KaufanfragenUtil"

const KaufanfragenGBHController = {
    approve(
        props: KaufanfragenGridActionProps
    ) {
        if (KaufanfragenUtil.isGridSelectionSuitable(props, 'ANGEFRAGT')) {
            KaufanfragenUtil.showProgressIndicatorDialog(props.modals, 'Kaufanfrage wird genehmigt...')
            KaufanfragenUtil.updateKaufanfrageSelection(
                props,
                'GENEHMIGT',
                undefined,
                'Die Kaufanfrage wurde erfolgreich genehmigt!',
            )
        }
    },
    reject(
        props: KaufanfragenGridActionProps,
        reason?: string,
    ) {
        const recordForGrund: Kaufanfrage = reason ? { GRUND: reason } as Kaufanfrage : {} as Kaufanfrage
        KaufanfragenUtil.showProgressIndicatorDialog(props.modals, 'Kaufanfrage wird abgelehnt...')
        KaufanfragenUtil.updateKaufanfrageSelection(
            props,
            'ABGELEHNT',
            recordForGrund,
            'Die Kaufanfrage wurde erfolgreich abgelehnt!',

        )
    }
}

export default KaufanfragenGBHController
