import CockpitInformationDTO from "service/data-service/cockpit-controller/interface/CockpitInformation";
import CockpitVisibilitiesDTO from "service/data-service/cockpit-controller/interface/CockpitVisibilities";
import EpgCardInformationDTO from "service/data-service/cockpit-controller/interface/EpgCardInformation";

export function generateDefaultCockpitInformation(): CockpitInformationDTO {
  return {
    anwendernachricht: "",
    atMmFailed: 0,
    erreichbarkeitWarnings: 0,
    kaufanfragen: 0,
    notizenWiedervorlage: 0,
    sapRdNichtZugeordnetGh: 0,
    rnbtbDsmdFailed: 0,
    zurBearbeitungAnstehendClearing: 0,
    zurBearbeitungAnstehendLogfile: 0,
    zurBearbeitungAnstehendStammdaten: 0,
  };
}

export function generateDefaultEpgCardInformation(): EpgCardInformationDTO {
  return {
    archivAuslastungExceeded: false,
    archivTooManyFiles: false,
    archivTooManyFilesThreshold: "0",
    alsLoginDritterAnzahl: "0",
    alsLoginDritterExists: false,
    erreichbarkeitIpBereicheFailed: false,
    erreichbarkeitIpBereicheFailedAnzahl: 0,
    pingInvalidAutomatenAnzahl: 0,
    sapRdPossesWithoutEpcomRegions: false,
    sapRdPossesWithoutEpcomRegionsAnzahl: "0",
    sapRdCopyAnlagenFailed: false,
    sapRdCopyExcelFailed: false,
    jobsOk: false,
    jobCleanupAutomatLogfilesFailed: false,
    jobClearerStammdatenAbrufFailed: false,
    jobClearerVerarbeiteteDateienFailed: false,
    jobClearingFehlerFailed: false,
    jobDatenbankAuftraegeCleanupFailed: false,
    jobDpgClearerVerfuegbarkeitFailed: false,
    jobDpgStammdatenFailed: false,
    jobDetermineLogfileFehlerFailed: false,
    jobEpgArchivierungFailed: false,
    jobErreichbarkeitsReportFailed: false,
    jobGhChangeFailed: false,
    jobLoginDritterFailed: false,
    jobRnBtbMigrateFailed: false,
    jobRnBtbSerialsNachrichtencodeAccessFailed: false,
    jobSapStammdatenMigrationFailed: false,
    jobSapRechnungsdatenCopyFilesFailed: false,
    jobSapRechnungsdatenPosImportFailed: false,
  };
}
export function generateDefaultCockpitVisibilities(): CockpitVisibilitiesDTO {
  return {
    anwendernachrichtShow: false,
    atMmFailedShow: false,
    epgKachelShow: false,
    erreichbarkeitWarningsShow: false,
    kaufanfragenShow: false,
    notizenWiedervorlageShow: false,
    rnbtbDsmdFailedShow: false,
    sapRdNichtZugeordnetGhShow: false,
    zurBearbeitungAnstehendClearingShow: false,
    zurBearbeitungAnstehendLogfileShow: false,
    zurBearbeitungAnstehendStammdatenShow: false,
  };
}
