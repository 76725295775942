import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButton } from "@mui/material";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";
import NoteService from "service/data-service/notizen-controller/Note.service";

export const noteDialogColumnDefs: Array<ColDef> = [
  {
    headerName: "Anlagedatum",
    field: "datumErfasst",
    valueFormatter: (params) =>
      DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Text",
    field: "notizText",
    flex: 1,
    cellStyle: { "justify-content": "flex-start" },
    wrapText: true,
    autoHeight: true,
  },
  {
    headerName: "User",
    field: "userCreated",
  },
  {
    headerName: "Dateiname",
    field: "dateiName",
    flex: 1,
  },
  {
    headerName: "Download",
    field: "dateiName",
    width: 90,
    suppressSizeToFit: true,
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <>
          {params.data.dateiName && (
            <IconButton
              size="small"
              onClick={() => {
                NoteService.downloadNote(
                  params.data.notizTyp,
                  params.data.automatKey,
                  DateUtils.optionalTimeArrayToISOString(
                    params.data.datumErfasst
                  ),
                  params.data.dateiKey
                );
              }}
            >
              <FileDownloadIcon />
            </IconButton>
          )}
        </>
      );
    },
  },
];
