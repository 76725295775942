import { ColDef } from "ag-grid-community";
import DateUtils from "global/util/DateUtils";

const KaufanfragenColumnDefs: Array<ColDef> = [
    {
        headerName: "Region",
        field: "ORG_NAME",
        minWidth: 90,
        maxWidth: 90,
    },
    {
        headerName: "RNS-Name",
        field: "RNS_NAME",
        minWidth: 260,
    },
    {
        headerName: "PLZ",
        field: "PLZ",
        minWidth: 90,
        maxWidth: 90,
    },
    {
        headerName: "Standort",
        field: "ORT",
        minWidth: 140,
        maxWidth: 140,
    },
    {
        headerName: "Strasse",
        field: "STRASSE",
        minWidth: 160,
        maxWidth: 160,
    },
    {
        headerName: "GLN / RNS",
        field: "GLN_RNS",
        minWidth: 150,
        maxWidth: 150,
    },
    {
        headerName: "Modell",
        field: "MODELL_NAME",
        minWidth: 90,
        maxWidth: 90,
    },
    {
        headerName: "Hersteller",
        field: "HERSTELLER_NAME",
        minWidth: 120,
        maxWidth: 120,
    },
    {
        headerName: "Angefragt am",
        field: "ANGEFRAGT_AM",
        minWidth: 130,
        maxWidth: 130,
        valueFormatter: (params) =>
            DateUtils.optionalTimeArrayToGermanString(params.value),
    },
    {
        headerName: "Entschieden durch",
        field: "ENTSCHIEDEN_DURCH",
        minWidth: 170,
        maxWidth: 170,
    },
    {
        headerName: "Entschieden am",
        field: "ENTSCHIEDEN_AM",
        minWidth: 150,
        maxWidth: 150,
        valueFormatter: (params) =>
            DateUtils.optionalTimeArrayToGermanString(params.value),
    },
    {
        headerName: "Status",
        field: "STATUS",
        minWidth: 110,
        maxWidth: 110,
    },
    {
        headerName: "Letzte Bearbeitung",
        field: "LETZTE_BEARBEITUNG",
        minWidth: 170,
        maxWidth: 170,
        valueFormatter: (params) =>
            DateUtils.optionalTimeArrayToGermanString(params.value),
    },
    {
        headerName: "Ablehnungsgrund",
        field: "GRUND",
        minWidth: 160,
        maxWidth: 160,
    },
];

export default KaufanfragenColumnDefs;
