export function fileToBase64(
  file: File,
  callback: (
    error: ProgressEvent<FileReader> | null,
    result: string | ArrayBuffer | null
  ) => void
) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(null, reader.result);
  };
  reader.onerror = function (error) {
    callback(error, null);
  };
}

export function cutOffBase64DefinitionFromString(
  base64String: string | undefined
): string {
  if (base64String)
    return base64String.substring(base64String.indexOf("base64,") + 7);
  else return "";
}
