import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";

interface LinkTabProps {
  id?: string;
  key?: React.Key;
  label?: React.ReactNode;
  to: string;
  disableRipple?: boolean;
  className?: string;
}
const LinkTab = (props: LinkTabProps) => {
  return <Tab className={props.className} component={Link} {...props} />;
};

export default LinkTab;
