import { Grid, Slider, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import UIButton from "global/components/UI/UIButton/UIButton";
import DateUtils from "global/util/DateUtils";
import { Fragment, useEffect, useState } from "react";
import AutomatStatusService, {
  AutomatStatusTyps
} from "service/data-service/automate-controller/AutomatStatus.service";
import AutomatStatusList, {
  AutomatStatus
} from "service/data-service/automate-controller/interface/AutomatStatus";
import AutomatStatusLog, {
  StatusLog
} from "service/data-service/automate-controller/interface/AutomatStatusLog";
import ExportService from "service/report-service/export/Export.service";
import { StatusPanelColumnDefs } from "./grid-column-defs";
import StatusPanelController from "./status-panel-controller";
import StatusLogDataDialog from "./StatusLogDataDialog";

import { FETCH_AUTOMAT_STATUS_LOG } from "global/hook/datastore/automat/automat-status-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import "../panels.scss";
import "./status-panel.scss";

interface StatusPanelProps {
  recordCount?: number;
  automatKey: string;
}

const StatusPanel = (props: StatusPanelProps) => {
  const [automatData, dispatchStatusData] = useAutomatData(props.automatKey);

  const [recordCount, setRecordCount] = useState(automatData.viewInfo.statusRecordCount);
  const [statusRecords, setStatusRecords] = useState<Array<AutomatStatus>>();
  const [logDialogTitle, setLogDialogTitle] = useState<string>("");
  const [logDialogOpen, setLogDialogOpen] = useState<boolean>(false);
  const [statusLogRecords, setStatusLogRecords] = useState<Array<StatusLog>>([]);
  const [automatStatus, setAutomatStatus] = useState<AutomatStatusList | undefined>(automatData.automatStatus)
  const [isFetchingData, setIsFetchingData] = useState(false)

  const windowViewport = useWindowViewport(0, 306)

  useEffect(() => {
    setAutomatStatus(automatData.automatStatusLog ?? automatData.automatStatus);
  }, [automatData.automatStatusLog]);

  const sliderChangeHandler = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      setRecordCount(value);
    }
  };

  const loadAutomatStatus = () =>
    dispatchStatusData(
      FETCH_AUTOMAT_STATUS_LOG,
      { statusRecordCount: recordCount } as AutomatActionPayload,
      undefined,
      setIsFetchingData)


  useEffect(() => {
    setStatusRecords(
      StatusPanelController.transmuteAutomatStatusToGridData(automatStatus ?? { row: [] })
    );
  }, [automatStatus]);

  useEffect(() => {
    setRecordCount(automatData.viewInfo.statusRecordCount);
  }, [automatData.viewInfo.statusRecordCount]);

  let statusLogDataHandler = (
    logData: AutomatStatusLog,
    type: AutomatStatusTyps
  ) => {
    if (logData.rows.length > 0) {
      setLogDialogTitle(StatusPanelController.statusDialogTitleFor(type));
      setStatusLogRecords(logData.rows);
      setLogDialogOpen(true);
    }
  };

  let rowClickHandler = (data: AutomatStatus, columnId: string) => {
    let logType = StatusPanelController.automatStatusLogTypeFor(columnId);
    if (logType == null) {
      return;
    }
    AutomatStatusService.retrieveAutomatenStatusLog(
      props.automatKey,
      DateUtils.optionalTimeArrayToISOString(data.datum as Array<number>) ?? "",
      logType,
      (logData: AutomatStatusLog) => statusLogDataHandler(logData, logType!)
    );
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography className="uiElement uiElement__Text mr_1rem">
            {" "}
            Die letzen:{" "}
          </Typography>

          <Slider
            className="uiElement w_12rem status-tagcount-slider"
            aria-label="statusHistorie"
            value={recordCount}
            onChange={sliderChangeHandler}
            min={10}
            max={180}
          />
          <Typography className="uiElement uiElement__Text mr_1rem ml_1rem">
            {" "}
            Tage{" "}
          </Typography>

          <UIButton
            className="status-filter-button"
            label={" Filter anwenden"}
            onClick={loadAutomatStatus}
          />

          <Typography className="uiElement uiElement__Text mr_1rem ml_1rem">
            {`(Aktueller Filter: ${recordCount} Tage)`}
          </Typography>
        </Grid>
        <Grid item gridColumn="span 2" xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={StatusPanelColumnDefs}
            rowsDataDef={{
              data: statusRecords,
              isFetchingData: isFetchingData,
            }}
            onRowClicked={rowClickHandler}
            exportDef={{
              action: () => ExportService.downloadAutomatStatus(
                props.automatKey,
                recordCount
              )
            }}
          />
        </Grid>
      </Grid>
      <StatusLogDataDialog
        open={logDialogOpen}
        onClose={() => setLogDialogOpen(false)}
        title={logDialogTitle}
        records={statusLogRecords}
      />
    </Fragment>
  );
};

export default StatusPanel;
