const Config = {
  cockpitUrl: process.env.REACT_APP_COMPONENT_COCKPIT_URL,
  dataServiceUrl: process.env.REACT_APP_DATA_SERVICE_URL,
  btbApiUrl: process.env.REACT_APP_BTB_URL,
  reportServiceUrl: process.env.REACT_APP_REPORT_SERVICE_URL,
  automatServiceUrl: process.env.REACT_APP_AUTOMAT_SERVICE_URL,
  sapServiceUrl: process.env.REACT_APP_SAP_SERVICE_URL,
  artikleServiceUrl: process.env.REACT_APP_ARTIKLE_SERVICE_URL,
  logfileServiceUrl: process.env.REACT_APP_LOGFILE_SERVICE_URL,
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL!,
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM!,
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
};

export default Config;
