import { AxiosResponse } from "axios";
import Config from "Config";
import axiosCalls from "global/util/axiosCalls";
import SapDTO from "./dto/SapDTO";
import SapInputParam from "./interface/SapInputParam";

const SapService = {
  getData: (
    filter: SapInputParam,
    callbackOnSuccess: Function,
    callbackOnFail?: Function
  ) => {
    axiosCalls
      .sendJsonDataPost(
        `${Config.sapServiceUrl}saprd/getPositionenForUnterwgrs`,
        filter
      )
      .then((response: AxiosResponse) => {
        callbackOnSuccess(SapDTO.convertSapResponseDTO(response));
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
};

export default SapService;
