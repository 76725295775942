import { Optional } from "global/util/interface/MappedTypesTransform";
import { isPartialFilled as isPartiallyFilled } from "global/util/textFieldValidation/textFieldValidation";
import AutomatenblattInformation, {
  AutomatCredentials,
  KommTypen,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { ARTIKELSTAMM_TYPE_ID } from "../TKPartnerDetailCard";

const KOMMTYPES_CONTAINED_IN_TKPARTNER = [
  "AT_MM",
  "CD_STD",
  "AT_DPGBTB",
  "AT_EDKBTB",
  "AT_LOG",
];
export interface TKDetailsInformation {
  detail?: Optional<KommTypen>;
  credentialsInfo?: Optional<AutomatCredentials>;
  isArtikelStammdatenDetail: boolean;
}

const TkPartnerController = {
  /**
   * Checks if one of the TextFields is not filled out but some others are.
   * After that it executes the onMark or onNoMark Function accordingly
   * Just to be justed directly in TKPartnerDetailCard!
   *
   * @param tkDetailsInformation
   * @param onMark
   * @param onNoMark
   */
  markHeaderIfOneEntryIsEmpty: function (
    tkDetailsInformation: TKDetailsInformation,
    onMark: Function,
    onNoMark?: Function
  ) {
    let arrayToTest = [
      tkDetailsInformation.detail?.kommPartnerName,
      tkDetailsInformation.detail?.ftpDirectory,
      tkDetailsInformation.credentialsInfo?.username,
      tkDetailsInformation.credentialsInfo?.password,
    ];
    if (tkDetailsInformation.isArtikelStammdatenDetail) {
      arrayToTest.push(tkDetailsInformation.detail?.artikelstammVariante);
    }

    if (isPartiallyFilled(arrayToTest)) {
      onMark();
    } else {
      if (onNoMark) onNoMark();
    }
  },
  /**
   * Checks if something is not filled out completely in Automatenblatt
   * and sets a boolean in the given useState dispatch Function
   * @param automatenblattInformation
   * @param setMarkTkPartnerWithColor
   */
  isTkPartnerIncomplete: function (
    automatenblattInformation: AutomatenblattInformation,
    setMarkTkPartnerWithColor: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    let isOneValueEmpty = false;

    let dataInAllTKPartnerDetailCards = KOMMTYPES_CONTAINED_IN_TKPARTNER.map(
      (kommType) =>
        TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
          automatenblattInformation,
          kommType
        )
    );

    dataInAllTKPartnerDetailCards.forEach((oneTKDetails) => {
      this.markHeaderIfOneEntryIsEmpty(
        oneTKDetails,
        () => (isOneValueEmpty = true)
      );
    });
    setMarkTkPartnerWithColor(isOneValueEmpty);
  },
  /**
   * Maps the automatenblattInformation.kommTypes of the corresponding typ_id
   * to an object of type TKDetailsInformation
   * @param automatenblattInformation
   * @param typ_id
   * @returns An Object
   */
  mapAutomatenblattInformationToTkDetailsInformation: function (
    automatenblattInformation: AutomatenblattInformation,
    typ_id: string
  ): TKDetailsInformation {
    const kommTypen = automatenblattInformation.kommTypen;
    const detail = kommTypen.find((e) => e.kommTyp === typ_id);
    const automatCredentialList =
      automatenblattInformation.automatCredentialList;
    const credentialsInfo = automatCredentialList.find(
      (e) => e.kommTyp.id === typ_id
    );
    const isArtikelStammdatenDetail = typ_id === ARTIKELSTAMM_TYPE_ID;

    return {
      detail: detail,
      credentialsInfo: credentialsInfo,
      isArtikelStammdatenDetail: isArtikelStammdatenDetail,
    };
  },
};

export default TkPartnerController;
