import { colors, createTheme } from "@mui/material";

const CockpitTheme = createTheme({
  palette: {
    text: {
      primary: colors.grey[600],
    },
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: "linear-gradient(to bottom right, #e5e5e5, #fafafa)", //colors.grey[200],
          height: 2,
          titleTypographyProps: "bold",
        },
        title: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          minWidth: 280,
          height: 180,
        },
      },
    },
  },
});

export default CockpitTheme;
