import { Note } from "service/data-service/notizen-controller/interface/NoteSearchResult";

export function generateDefaultNote(): Note {
  return {
    automatKey: "",
    automatModell: "",
    dateiKey: "",
    dateiName: "",
    datumErfasst: [],
    datumFaellig: [],
    datumUpdated: [],
    errorTyp: "",
    herstellerKuerzel: "",
    message: "",
    notizKey: "",
    notizKopf: "",
    notizReadRoles: "",
    notizStatus: "",
    notizText: "",
    notizTyp: "",
    rnsGln: "",
    rnsName: "",
    rnsOrt: "",
    rnsPlz: "",
    rnsStrasse: "",
    serienNr: "",
    userCreated: "",
    userUpdate: "",
  };
}
